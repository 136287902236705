import { 
    SIGNIN_BY_SOCIAL_REQUEST
} from "../constans/ActionType";

export const socialLogin = (state = { inPromise: false }, action) => {
    const { type } = action;
    switch (type) {
      case SIGNIN_BY_SOCIAL_REQUEST:
      return { ...state, inPromise: true };
  
      default:
        return state;
    }
};
  