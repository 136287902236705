import React from 'react';
import {useSelector} from "react-redux";
import logoMenu from "../../../assets/img/Tile.png";
import {Link} from "react-router-dom";
import $ from "jquery";

function MobileMenu() {
  const categories = useSelector(state => state.getCategoryProduct.categories);
  return (
    <li className="nav-item dropdown ">
      <a className="nav-link dropdown-toggle menu-parent w-100" href="#">
        <img src={logoMenu} alt="logo_product"/>
        Sản phẩm của {process.env.REACT_APP_NAME}
      </a>
      <div className="dropdown-menu show" aria-labelledby="navbarDropdown">
      {categories.map((category, index) => {
        return (
            <div key={index} className="menu_child">
              <Link className="dropdown_menusub" tabIndex="-1" to={"/danh-muc/" + category.slug} onClick={() => { $('#closeMenu').click() }}>{category.name}
                  {category.childs.length ?
                    <span className="caret"></span>
                  : ''}
                  </Link>
              <ul className="dropdown-submenu pl-0">
                {category.childs && category.childs.length ? category.childs.filter(item => { if (item.status) { return item}})
                  .sort((a,b) => a.order_no - b.order_no).map(child => {
                  return (<li key={child.id}>
                    <Link className="dropdown_menusub" tabIndex="-1" to={"/danh-muc/" + child.slug} onClick={() => { $('#closeMenu').click() }}>
                        {child.name}
                        <span className="caret"></span>
                    </Link>
                    {child.Child && child.Child.length ?
                    <ul className="dropdown-submenu menuChild pl-0">
                      { child.Child.filter(item => { if (item.status) { return item}})
                        .sort((a,b) => a.order_no - b.order_no).map(children => {
                          return (<li key={children.id}>
                            <Link to={"/danh-muc/" + children.slug} onClick={() => { $('#closeMenu').click() }}>{ children.name }</Link>
                          </li>)
                        })
                      }
                    </ul>
                      : null }
                  </li>)
                }) : ''
                }
              </ul>
            </div>
          )
      })}
      </div>
    </li>
  )
}
export default MobileMenu