import {
  GET_LIST_CATEGORY,
} from "../constans/ActionType";


const actions = {};

actions.setCategoryProduct = (categories) => {
  return { type: GET_LIST_CATEGORY, payload: categories };
};

export { actions };
