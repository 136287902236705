import React from 'react'
import img_check from '../../../../assets/img/Check_ring.png';
function Commit() {
    return (
        <div className="commit">
            <h1>{process.env.REACT_APP_NAME} cam kết</h1>
            <ul>
                <li className="d-flex">
                    <img src={img_check} alt=""/>
                    <h5>Hoàn tiền nếu phát hiện hàng giả</h5>
                </li>
                <li className="d-flex">
                    <img src={img_check} alt=""/>
                    <h5>Bảo hành nhanh chóng</h5>
                </li>
                <li className="d-flex">
                    <img src={img_check} alt=""/>
                    <h5>Hàng chính hãng 100%</h5>
                </li>
                <li className="d-flex">
                    <img src={img_check} alt=""/>
                    <h5>Free ship đơn hàng trên 600K</h5>
                </li>
            </ul>
        </div>
    )
}

export default Commit
