import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';
import updateBtn from '../../../assets/img/Update avatar.svg';
import ENDPOINT from '../../../ultils/EndPoint';
import '../Profile.scss';
import { useHistory} from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import isEmpty from "validator/lib/isEmpty";
import axios from 'axios';
import Verification from '../../../pages/auth/account_verification';
import { actions } from '../../../actions/User';

function DetailInfo() {
    const data = useSelector(state => state.AuthMeRoot.auth);
    const stateProfileUpdate = useSelector(state => state.userProfileUpdate);
    const dispatch = useDispatch();
    const history = useHistory();
    const [disable, setDisable]= useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [birthday, setBirthday] = useState('');
    const [validationMsg, setValidationMsg] = useState({});
    const [validationMsgSocial, setValidationMsgSocial] = useState({});
    const [msg, setMsg] = useState('')
    const [msg1, setMsg1] = useState('')
    const [msgForSocial, setMsgForSocial] = useState('')
    const [imageURL, setImageURL] = useState('');
    const [avatarServer, setAvatarServer] = useState('');
    const [phonePassthrough, setPhonePassthrough] = useState('');
    const [phoneSocial, setPhoneSocial] = useState(phonePassthrough);
    const [socialCheckModal, setSocialCheckModal] = useState(false);

    // test phone
    const isPhoneNumber = new RegExp("^[0-9]*$")
    
    const [showVerification, setshowVerification] = useState(false);

    useEffect(() => {
        setAvatarServer(data.Avatar)
        setEmail(data.Email)
        setName(data.Name)
        setBirthday(data.Birthday)
        if(data.socials) {
            setSocialCheckModal(true)
        }
    }, [data.Email, data.Name, data.Birthday, data.Avatar, data.socials])

    // validate before submit
    const validateBeforeConfirm = () => {
        const msg = {};

        if(isEmpty(phoneSocial)) {
            msg.phoneSocial = 'Vui lòng nhập số điện thoại'
            $('.form-phone-social').addClass('red_blur')
        }else if (!isPhoneNumber.test(phoneSocial) ||  phoneSocial.length !== 10){
            $('.form-phone-social').addClass('red_blur')
            msg.phoneSocial = 'Số điện thoại không hợp lệ. Số điện thoại là 10 số, bắt đầu bằng số 0'
        }else if(phoneSocial.charAt(0) !== '0') {
            $('.form-phone-social').addClass('red_blur')
            msg.phoneSocial = 'Số điện thoại không hợp lệ. Số điện thoại là 10 số, bắt đầu bằng số 0'
        }

        setValidationMsgSocial(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // register phone
    const handleGetOTPForSocial = () => {
        const isValidate = validateBeforeConfirm()
        if(!isValidate) return

        validationMsg.phoneSocial = ''

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 5000);
        
        let query = '';
        const data = {
            phone : phoneSocial,
            type: 1,
        }
        Object.keys(data).map((key,index) => 
        query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )
        axios.post(`${ENDPOINT.REGISTERGETOTP}`+ query, data)
        .then(function (response) {
            if(response.data.data) {
                $('.form-phone').removeClass('red_blur')
                setPhonePassthrough(response.data.data.Phone)
                dispatch(actions.getOTPAgains(response.data.data.id))
                setshowVerification(true)
            }
        })
        .catch(error => {
            if(error.request.status) {
                $('.form-phone-social').addClass('red_blur')
                setMsgForSocial('Số điện thoại này đã được đăng ký.')
            }
        }) 
    }

    //removeExtraSpace
    const removeExtraSpace = (s) => s.trim().split(/ + /).join(' ');
    

    const handleName = (e) => {
        setName((e.target.value).replace(/\s\s+/g, ' '));
    }

    const handleEmail = (e) => {
        setEmail(removeExtraSpace(e.target.value));
    }

    const handleBirthday = (e) => {
        setBirthday(e.target.value);
    }

    // go to homePage
    const handleGoToHomePage = () => {
        history.push('/');
        window.location.reload();
    }

    const handleRemoveMessName = () => {
        validationMsg.name = ''
        $('.form-name').removeClass('red_blur')
    }

    const handleRemoveMessEmail = () => {
        validationMsg.email = ''
        $('.form-email').removeClass('red_blur')
        setMsg('')
    }

    const handlePhoneSocial = (e) => {
        setPhoneSocial(e.target.value)
    }
    const handleRemoveMessPhoneSocial = () => {
        $('.form-phone-social').removeClass('red_blur')
        setMsgForSocial('')
        setValidationMsgSocial('')
    }

    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(name.length > 255){
            msg.name = 'Trường họ và tên chỉ tối đa 255 kí tự.Vui lòng nhập lại.'
            $('.form-name').addClass('red_blur')
        }
        if(name === '') {
            msg.name = 'Vui lòng nhập họ và tên'
            $('.form-name').addClass('red_blur')
        }

        if(isEmpty(email)) {
            msg.email = 'Vui lòng nhập email'
            $('.form-email').addClass('red_blur')
        }else if(!isEmail(email)) {
            $('.form-email').addClass('red_blur')
            msg.email = 'Vui lòng nhập đúng định dạng email'
        }

        if(socialCheckModal === true) {
            if(phoneSocial !== '') {
                msg.phoneSocial = 'Vui lòng ấn xác minh điện thoại'
                $('.form-phone-social').addClass('red_blur')
            }
        }else {

        }
        
        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // only accept for nummber
    useEffect(() => {
        $('input[name="inputForName"]').keypress
        (
        function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {
                    event.preventDefault();	
                }
                else 
                {
                    if (event.keyCode < 48 || event.keyCode > 57 ) 
                    {
                       
                    }else {
                        event.preventDefault();	
                    }	
                }
            }
        );
    })

    // check path before update
    if(avatarServer){
        var pathname = new URL(avatarServer).href;         
    }

    // params for update client
    const dataUpadteInfo = new FormData();
    if(socialCheckModal === true) {
        dataUpadteInfo.append('avatar', avatarServer)
    }else {
        if((avatarServer && avatarServer.length !== 0) && imageURL === ''){
            dataUpadteInfo.append('avatar', pathname)
        }else {
            dataUpadteInfo.append('avatar', imageURL)
        }
    }
    
    dataUpadteInfo.append('name', name);
    dataUpadteInfo.append('email', email);
    dataUpadteInfo.append('birthday', birthday);

    if(socialCheckModal === true) {
        if(phoneSocial !== '') {
            dataUpadteInfo.append('phone', phoneSocial);
        }else {
            dataUpadteInfo.append('phone', data.Phone);
        }
    }else {
        dataUpadteInfo.append('phone', data.Phone);
    }

    // when every ok submit
    const updateInfo = () => {
        const isValidate = validateAll()
        if(!isValidate) return
        
        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);
   
        return axios.post(`${ENDPOINT.UPDATE_PROFILE_CLIENT}`, dataUpadteInfo,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-token') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                setMsg('')
                $('.form-email').removeClass('red_blur')
                toast.success('Cập nhật thông tin thành công !')
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
          })
          .catch(error => {
            if(error.request.status === 422) {
                setMsg('Email đã tồn tại')
                $('.form-email').addClass('red_blur')
            }else if(error.request.status === 502){
                setMsg1('Dung lượng ảnh tối đa là 500KB')
            }
            
          }
        )
    }



    // auto update profile social 
    useEffect(() => {
        if(stateProfileUpdate.payload === true) {
            axios.post(`${ENDPOINT.UPDATE_PROFILE_CLIENT}`, dataUpadteInfo,  {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('User-token') 
                }
               })
              .then(function (response) {
                if(response.status === 200) {
                    setMsg('')
                    $('.form-email').removeClass('red_blur')
                    toast.success('Cập nhật thông tin thành công !')
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                }
              })
              .catch(error => {
                if(error.request.status === 422) {
                    setMsg('Email đã tồn tại')
                    $('.form-email').addClass('red_blur')
                }else if(error.request.status === 502){
                    setMsg1('Dung lượng ảnh tối đa là 500KB')
                }
                
              }
            )
        }
    }, [stateProfileUpdate.payload])

    // check birdthday
    $('#check_birdthday').keypress(function(e) {
        e.preventDefault();
    })

    // type only number
    useEffect(() => {
        $('input[name="inputForNumber"]').keypress
        (
        function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else 
                {
                    if (event.keyCode < 48 || event.keyCode > 57 ) 
                    {
                        event.preventDefault();	
                    }	
                }
            }
        );
    })

    // upload file images
    const onFileChange = (e) => {
        if(e.target.files[0] !== undefined){
            setImageURL(e.target.files[0])
        }

        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').attr('src', e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        if(e.target.files[0] === undefined){
            
        }else {
            reader.readAsDataURL(e.target.files[0])
        }
       
        setMsg1('')
    }

    return (
        <div>
            <Verification phone={phonePassthrough} show={showVerification} social={false} close={() => setshowVerification(false)} onHide={() => setshowVerification(false)}/>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="changePass-wapper">
                    <h1>Thông tin tài khoản</h1>
                    <div style={{'height': '120px'}} className="detail-presonl">
                        <img alt="" id="imagePreview" src={avatarServer}></img>
                        {socialCheckModal === true ? '' :  <div><img className="btnUpload" alt="" src={updateBtn}/><input className="updateImageForProfile"  onChange={onFileChange} type="file" /></div>}
                    </div>

                    <p style={{'fontSize': '12px', 'marginLeft':'370px', 'marginTop':'10px'}} className="text-danger">{msg1}</p> 
                    <div className="changePass-body">
                        <div className="changePass-input-wapper ">
                            <p>Họ và tên</p>
                            <div className="input-group auth-pass-inputgroup changePass-input">
                                <input type="text" name="inputForName" onChange={handleName} value={name || ''} onKeyDown={handleRemoveMessName} className="form-control form-name"/>
                            </div>
                        </div>
                        <p style={{'fontSize': '12px', 'marginLeft':'168px'}} className="text-danger">{validationMsg.name}</p> 

                        {data.Phone ? 
                        <div className="changePass-input-wapper">
                            <p>Số điện thoại</p> 
                            <div className="input-group auth-pass-inputgroup disable-input">
                                <input type="text" disabled value={data.Phone || ''} className="form-control"/>
                            </div>
                        </div>  : 
                        <div>
                            <div className="changePass-input-wapper">
                                <p>Số điện thoại </p> 
                                <div className="input-group">
                                    <input name="inputForNumber" type="text" disabled={data.socials && data.socials.Phone ? true : false} value={phoneSocial || ''} onChange={handlePhoneSocial} onKeyDown={handleRemoveMessPhoneSocial} className="form-control input_social form-phone-social"/>
                                    <div className="btn_social" onClick={handleGetOTPForSocial}>Xác minh SĐT</div>
                                </div>
                            </div>
                            <p style={{'fontSize': '12px', 'marginLeft':'168px', 'marginBottom':'0px', 'marginTop':'0px'}} className="text-danger">{validationMsgSocial.phoneSocial}</p> 
                            <p style={{'fontSize': '12px', 'marginLeft':'168px', 'marginTop':'0px', 'marginBottom':'0px'}} className="text-danger">{msgForSocial}</p> 
                            <p style={{'fontSize': '12px', 'marginLeft':'168px'}} className="text-danger">{validationMsg.phoneSocial}</p> 
                        </div>
                        }
                        
                      
                        <p style={{'fontSize': '12px'}} className="text-danger"></p> 
                        <div className="changePass-input-wapper">
                            <p>Email</p>
                            <div className="input-group auth-pass-inputgroup changePass-input">
                                {data.socials !== undefined && data.socials  ? 
                                    data.socials.map((social, index) => 
                                        <input key={index} type="text" disabled={social.Provider === 'facebook' ? false : true} value={email || ''} onChange={handleEmail} onKeyDown={handleRemoveMessEmail} className="form-control form-email"/> 
                                    )
                                : <input type="text" onChange={handleEmail} value={email || ''} onKeyDown={handleRemoveMessEmail} className="form-control form-email"/>}
                            </div>
                        </div>
                        <p style={{'fontSize': '12px', 'marginLeft':'168px', 'marginBottom': '0px'}} className="text-danger">{validationMsg.email}</p> 
                        <p style={{'fontSize': '12px', 'marginLeft':'168px'}} className="text-danger">{msg}</p> 
                        <div className="changePass-input-wapper input-wapperdate">
                            <p>Ngày tháng năm sinh</p>
                            <div className="input-group auth-pass-inputgroup changePass-input format_dateType">
                                <input type="date" onChange={handleBirthday} id="check_birdthday" max="3000-12-31" value={birthday || ''} className="form-control form-birtday"/>
                            </div>
                            {/* <img alt="" className="icon_dateCalender" src={calender}></img> */}
                        </div>
                        <p style={{'fontSize': '12px', 'marginLeft':'168px'}} className="text-danger"></p> 
                        <div className="changePass-input-wapper">
                            <p id="submit">Mã giới thiệu:</p> 
                            <div className="input-group auth-pass-inputgroup disable-input">
                                <input type="text" disabled value={data.Code || ''} className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="btn-changePassword mt-5">
                        <div onClick={handleGoToHomePage} className="goto-home"> Quay lại trang chủ</div>
                        <button onClick={updateInfo} disabled={disable} style={{'borderStyle':'none'}} className="update-password">Cập nhật thông tin</button>
                    </div>
                </div>
        </div>
    )
}

export default DetailInfo
