import React from 'react';
import {formatCurrency, finalPrice} from "../../../../helpers/Function";

function TitleProduct(props) {
    return (
        <div className="title_product">
            <h1>{props.name}</h1>
          {props.type === 3 ? <div className="d-flex pl-0 pr-0">
              <h3>Liên hệ</h3>
            </div> :
            <div className="d-flex pl-0 pr-0">
              {((props.product.discount > 0 && props.product.discount < props.product.price) || (props.product.deal && props.product.deal.id)) > 0 ?
                <div className="d-flex pl-0 pr-0">
                  <h3>{formatCurrency(finalPrice(props.product))}đ</h3>
                  <h5>{formatCurrency(props.price)} {props.price ? 'đ' : ''}</h5>
                </div>
                :
                <h3>{formatCurrency(props.discount)} {props.price ? 'đ' : ''}</h3>
              }
            </div>
          }
        </div>
    )
}

export default TitleProduct;
