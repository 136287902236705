import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import icon_login_gg from "../../assets/img/icons8-google 1.png";
import icon_login_fb from "../../assets/img/icons8-facebook 1.png";
import icon_register from "../../assets/img/Expand_right_double_light.png";
import Register from './register';
import $ from 'jquery';
import '../../assets/scss/styles.scss';
import ENDPOINT from '../../ultils/EndPoint';
import axios from 'axios';
import Timer from '../timer';
import { useSelector, useDispatch } from 'react-redux';
import * as LoginSocialNetWerk from '../../components/common/social-login/Social_login';
import { actions } from '../../actions/User';
import Login from './login';
import { websiteActions } from '../../actions';

function Verification(props) {
    const dispatch = useDispatch();
    const data = useSelector(state => state.AuthMeRoot.auth);
    const requestOTPId = useSelector(state => state.CallOtpRequest.otpRequest)
    const noti = useSelector(state => state.CallOtpRequest.noti)
    const checkZero = useSelector(state => state.CallOtpRequest.checkTime)
    const [show, setShow] = useState(false);
    const [showVerification, setshowVerification] = useState(false);
    const [showRegister, setshowRegister] = useState(false);
    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');
    const [msg2, setmsg2] = useState('')
    const [msg3, setmsg3] = useState('')
    // input value OTP
    const [tagInputVal1, setTagInputVal1] = useState([]);
    const [tagInputVal2, setTagInputVal2] = useState([]);
    const [tagInputVal3, setTagInputVal3] = useState([]);
    const [tagInputVal4, setTagInputVal4] = useState([]);
    const [tagInputVal5, setTagInputVal5] = useState([]);

    const handleClose = () => setShow(props.close);
    const handleCloseMiss = () => setshowVerification(false);

    const [seconds, setSeconds] = useState(0)
    const [minutes, setMinutes] = useState(3)
    
    function onChangeTagInput1(e) {
        setTagInputVal1([e.target.value].reverse());
        $('.form-otp').removeClass('red_blur')
    }
    function onChangeTagInput2(e) {
        setTagInputVal2([e.target.value].reverse());
        $('.form-otp').removeClass('red_blur')
    }
    function onChangeTagInput3(e) {
        setTagInputVal3([e.target.value].reverse());
        $('.form-otp').removeClass('red_blur')
    }
    function onChangeTagInput4(e) {
        setTagInputVal4([e.target.value].reverse());
        $('.form-otp').removeClass('red_blur')
    }
    function onChangeTagInput5(e) {
        setTagInputVal5([e.target.value].reverse());
        $('.form-otp').removeClass('red_blur')
    }
    
    // set all of OTP
    var array = [];

    if(checkZero === undefined) {
        array = ''
    }else if(checkZero === null) {
        array = `${tagInputVal1}${tagInputVal2}${tagInputVal3}${tagInputVal4}${tagInputVal5}`
    }

  // validate before submit
  const validateAll = () => {
    const msg = {};
    
    if(tagInputVal1.length < 1 || tagInputVal2.length < 1 || tagInputVal3.length < 1 || tagInputVal4.length < 1 || tagInputVal5.length < 1) {
        msg.inputValue = "Bạn vui lòng nhập OTP để xác minh"
        $('.form-otp').addClass('red_blur')
    }

    if(checkZero === undefined){
        msg.inputValue = ""
    }
    
    setValidationMsg(msg) 
    if(Object.keys(msg).length > 0){
        return false
    }else{
        return true
    }
}

useEffect(() => {
    if(checkZero === undefined) {
        setmsg3('Mã OTP hết hiệu lực! Vui lòng lấy lại mã OTP')
        setMsg('')
        setValidationMsg('')
        $('#digit-1').val('')
        $('#digit-2').val('')
        $('#digit-3').val('')
        $('#digit-4').val('')
        $('#digit-5').val('')
    }else if(checkZero === null) {
        setmsg3('')
        $('.form-otp').removeClass('red_blur')
        setTagInputVal1('')
        setTagInputVal2('')
        setTagInputVal3('')
        setTagInputVal4('')
        setTagInputVal5('')
    }
}, [checkZero])

const [disable, setDisable]= useState(false);
const [setdisable, settttsetdisable] = useState(false);

useEffect(() => {
    if(noti === 'checked') {
        setmsg2('Mã OTP hết hiệu lực! Vui lòng lấy lại mã OTP') 
        setMsg('')
        setValidationMsg('')
        settttsetdisable(true)
        $('#digit-1').val('')
        $('#digit-2').val('')
        $('#digit-3').val('')
        $('#digit-4').val('')
        $('#digit-5').val('')
    }
    
}, [noti])
useEffect(() => {
    if(requestOTPId !== 'undefinded'){
        settttsetdisable(false)
        setmsg2('') 
        setMsg('')
        setValidationMsg('')
        $('#digit-1').val('')
        $('#digit-2').val('')
        $('#digit-3').val('')
        $('#digit-4').val('')
        $('#digit-5').val('')
    }
}, [requestOTPId])

    useEffect(() => {
        $('.inputForNumber').keydown
        (
            function(event)
                {
                    if (event.keyCode === 46 || event.keyCode === 8)
                    {

                    }
                    else 
                    { 
                        if (event.keyCode < 48 || event.keyCode > 57 ) 
                        {
                            event.preventDefault();	
                        }	
                    }
                }
            );

        $('input[type="number"]').keypress(function() {
            if (this.value.length >= 1) {
                return false;
            }
        });
    })

    $('.digit-group').find('input').each(function() {
        $(this).attr('maxlength', 1);
        $(this).on('keyup', function(e) {
            var parent = $($(this).parent());
            
            if(e.keyCode === 8 || e.keyCode === 37) {
                var prev = parent.find('input#' + $(this).data('previous'));
                
                if(prev.length) {
                    $(prev).select();
                }
            } else if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
                var next = parent.find('input#' + $(this).data('next'));
                
                if(next.length) {
                    $(next).select();
                } else {
                    if(parent.data('autosubmit')) {
                        parent.submit();
                    }
                }
            }
        });
    });


    const handleVetifyOtp = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        let query = '';
        const data = {
            otp : array,
        };
    
        Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )
    
        return axios.post(`${ENDPOINT.CONFIRMOTP}/${requestOTPId}`+ query, data)
          .then(function (response) {
              if(response.data.status === true) {
                setshowRegister(true)
                handleRemove()
                $('.form-otp').removeClass('red_blur')
                dispatch(actions.GetNotiChangeSocialProfile(true))
            }
          })
          .catch(error => {
            setshowRegister(false)
            setMsg('Mã OTP không chính xác. Bạn vui lòng kiểm tra lại tin nhắn')
            $('.form-otp').addClass('red_blur')
          }
        )
    } 

    const handleRemove = () => {
        handleClose()
        setMsg('')
        setValidationMsg('')
        setTagInputVal1('')
        setTagInputVal2('')
        setTagInputVal3('')
        setTagInputVal4('')
        setTagInputVal5('')
    }

    const handleCheck = () => {
        setValidationMsg('')
        setMsg('')
    }
    
    var type = 1

    // set show for modal
    const [showLogin, setShowLogin] = useState(false);
    return (
        <div>
            {data && data.socials ? '' : <Register show={showRegister} phone={props.phone} close={() => setshowRegister(false)} onHide={() => setshowRegister(false)} />} 

            {
                showLogin === true 
                    ? <Login show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
                    : 
                    <Modal show={props.show} onHide={handleRemove} className="modal-login" >
                        <Modal.Header closeButton  className="grid">
                            <Modal.Title>Xác thực tài khoản</Modal.Title>
                            <p>Mã xác thực OTP được gửi đến số <a>{props.phone}</a> Vui lòng nhập mã OTP để xác thực tài khoản</p>
                            <div className="timer"><Timer phone={props.phone} type={type}/></div>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="form_pwd">
                                <div style={{'marginBottom':'0px', 'paddingBottom':'56px'}} className="digit-group form-group d-flex justify-content-center otp"  data-group-name="digits">
                                    <input type="text" onKeyDown={handleCheck}  onChange={onChangeTagInput1} className="fill_phone form-otp inputForNumber" id="digit-1" name="digit-1" data-next="digit-2"/>
                                    <input type="text" onKeyDown={handleCheck}  onChange={onChangeTagInput2} className="fill_phone form-otp inputForNumber" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1"/>
                                    <input type="text" onKeyDown={handleCheck}  onChange={onChangeTagInput3} className="fill_phone form-otp inputForNumber" id="digit-3" name="digit-3" data-next="digit-4 " data-previous="digit-2"/>
                                    <input type="text" onKeyDown={handleCheck}  onChange={onChangeTagInput4} className="fill_phone form-otp inputForNumber" id="digit-4" name="digit-4" data-next="digit-5 " data-previous="digit-3"/>
                                    <input type="text" onKeyDown={handleCheck}  onChange={onChangeTagInput5} className="fill_phone form-otp inputForNumber" id="digit-5" name="digit-5" data-previous="digit-4"/>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <p style={{'fontSize': '12px', 'marginTop':'-45px'}} className="text-danger">{validationMsg.inputValue}</p> 
                                    <p style={{'fontSize': '12px', 'marginTop':'-45px'}} className="text-danger">{msg}</p> 
                                    <p style={{'fontSize': '12px', 'marginTop':'-45px'}} className="text-danger">{msg2}</p>
                                    <p style={{'fontSize': '12px', 'marginTop':'-45px'}} className="text-danger">{msg3}</p>
                                </div>
                                <div className="form-group">
                                    <Button onClick={() => {handleVetifyOtp()}} disabled= {disable} className="button_acceprt">Xác minh</Button>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group w-100">
                                <label htmlFor="">Bạn đã có tài khoản?</label>
                                <div className="d-flex w-100">
                                    <Button className="btn_login_other">
                                        <img src={icon_login_gg} alt=""/>
                                    </Button>
                                    <LoginSocialNetWerk.GoogleLoginAuth/>
                                    <Button className="btn_login_other">
                                        <img src={icon_login_fb} alt=""/>
                                    </Button>
                                    <LoginSocialNetWerk.FaceBookLoginAuth/>
                                    <Button onClick={() => {
                                        handleClose()
                                        // setShowLogin(true)
                                        dispatch(websiteActions.setModal(true))
                                    }} className="btn_register btn_login">
                                        Đăng nhập ngay
                                        <img src={icon_register} alt=""/>
                                    </Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
            }
        </div>
    )
}

export default Verification;
