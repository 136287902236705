import React, {useRef} from 'react';
import './product.scss';
import Slider from "react-slick";
import arrowLeft from '../../../assets/img/Vector 9.svg'
import arrhoverLeft from '../../../assets/img/Vector 9 copy.svg'
import arrowRight from '../../../assets/img/Vector 9 (1).svg'
import arrhoverRight from '../../../assets/img/Vector 9 (1) copy.svg'
import ProductItem from '../product-item/index';


function ProductSameCategory({name, dataSameCategory}) {

    const slider = useRef();
  
    const next = () => {
     slider.current.slickNext();
   };
    const previous = () => {
     slider.current.slickPrev();
   }; 

    var settings = {
        dots: false,
        autoplay: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,

        responsive: [
            {
            breakpoint: 1025,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
            }
        ]
    };

    return (
        <div className="branch-wapper  product_views mb-4">
                <div className="container_sameCategory">
                    <div className="branch-title">
                        {dataSameCategory && dataSameCategory.length > 0 ? <h2>{name}</h2> : ''}
                        {
                            dataSameCategory && dataSameCategory.length > 4 
                            ?  <div className="button_slide">
                                    <p onClick={previous} className="arrow arrowLeftSame ml-2"><img alt="" className="arrow-1" src={arrowRight}></img> 
                                    <img alt="" className="arrow-2" src={arrhoverRight}></img>
                                    </p>
                                    <p onClick={next} className="arrow arrowRightSame mr-2"><img alt="" className="arrow-3" src={arrowLeft}></img> 
                                    <img alt=""
                                        className="arrow-4"
                                        src={arrhoverLeft}></img>
                                    </p>
                                </div>
                            : ''
                        }
                    </div>
                    {
                        dataSameCategory && dataSameCategory.length > 4
                        ? <Slider ref={(c) => (slider.current = c)} {...settings}>
                            {dataSameCategory && dataSameCategory.map((slide,index) => 
                                <div key={index}><ProductItem product={slide} /></div> 
                            )}
                        </Slider>
                        :
                        <div className="d-flex alone_sameCate"> 
                            {dataSameCategory && dataSameCategory.map((slide,index) => 
                                <div className="mr-3" key={index}><ProductItem product={slide} /></div>   
                            )}
                        </div>
                    }
                    
                </div>
        </div>
    )
}

export default ProductSameCategory;
