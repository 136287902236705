import React, {useState, useEffect} from 'react';
import '../assets/scss/trademark.scss';
import Brand from "../components/home-page/brand/Brand";
import {useParams} from "react-router-dom";
import {ProductApiService} from "../ultils/EndPoint";
import {Pagination} from "antd";
import {Helmet} from "react-helmet";
import ProductItem from '../components/common/product-item';
import NotFound from "../components/common/not_found/NotFound";

function TradeMark() {

    const {slug} = useParams();
    const [products, setProduct] = useState([]);
    const [brand, setBrand] = useState([]);
    const [total, setTotal] = useState();
    const [ idBrands, setIdBrands ] = useState(0);
    const [customersPerPage] = useState(15);
    const [currentPage, setcurrentPage] = useState(1);
    const [selectSort, setSelectSort] = useState('product_sell desc');
    const [see_more, setSeeMore] = useState(false);
    const [isNotFound, setNotFound] = useState(false);
    const handleSelectedSort = (e) => {
        setSelectSort(e.target.value)
    }

    // brands 
    useEffect(() => {
        if (slug) {
            ProductApiService.getBrandBySlug(slug).then(res => {
                if (typeof res.error !== 'undefined') {
                    setNotFound(true)
                }
                if (res.data) {
                    setBrand(res.data)
                    setIdBrands(res.data.id)
                }
            })
        }
    }, [slug])

    //product 
    useEffect(() => {
        if (idBrands) {
            let data = {
                brand_id: '[' + idBrands + ']',
                per_page: customersPerPage,
                page: currentPage,
                order_by: selectSort,
            };
            ProductApiService.getListProduct(data).then(res => {
                setProduct(res.data.records || [])
                setTotal(res.data.total_record || 0)
            })
        }
    }, [idBrands, customersPerPage, currentPage, selectSort])

    if (isNotFound) {
        return (<NotFound />)
    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{brand.meta_title}</title>
                <meta name="description" content={brand.meta_desc}/>
                <meta name="keywords" content={brand.meta_key}/>
            </Helmet>
            <div className="banner_trademark">
                <img src={brand.banner} alt=""/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="infomation_trademark d-flex w-100">
                        <img src={brand.image} alt={brand.nameg} className="logo_trademark"/>
                        <div className="grid align-self-center ">
                            <h4>{brand.name}</h4>
                            <p className={see_more ? 'see-more' : ''}>{brand.description}</p>
                            {/*{see_more ? <p className="show-more" onClick={() => setSeeMore(false)}>Xem thêm</p> : <p className="show-more" onClick={() => setSeeMore(true)}>Ẩn bớt</p>}*/}
                        </div>
                    </div>
                    <div className="product w-100 mt-66">
                        {products.length ?
                          <div className="header-productTitle d-flex align-items-center justify-content-between mb-16">
                              <div className="title_header">
                                  <p>Hiển thị {customersPerPage * (currentPage - 1) + 1} - {customersPerPage * currentPage < total ? customersPerPage * currentPage : total} trong số {total} Sản
                                      phẩm</p>
                              </div>
                              <select className="form-control custom_select" onChange={handleSelectedSort}>
                                  <option value="product_sell desc">Sản phẩm bán chạy</option>
                                  <option value="discount asc">Giá tăng dần</option>
                                  <option value="discount desc">Giá giảm dần</option>
                                  <option value="name asc">Xếp thứ tự A - Z</option>
                                  <option value="name desc">Xếp thứ tự Z - A</option>
                              </select>
                          </div> : ''
                        }
                        <div className="parents">
                            <div className="grid grid-5 grid-gap-20">
                                {products !== null ?
                                  products.map((product, index) => <div key={index}><ProductItem product={product} /></div> ) : ''}
                            </div>
                        </div>
                        {total <= customersPerPage ? '' :
                            <Pagination
                                defaultCurrent={currentPage}
                                onChange={(value) => {window.scrollTo({top: 800, left: 0, behavior: 'smooth' }); setcurrentPage(value) }}
                                total={total}
                                current={currentPage}
                                defaultPageSize={customersPerPage}
                                showSizeChanger= {false}
                            />
                        }
                    </div>
                    <Brand name= {'Các thương hiệu khác'}/>
                </div>
            </div>
        </div>
    )
}

export default TradeMark
