import React, { useState, useEffect } from 'react';
import '../Profile.scss';
import {Link} from 'react-router-dom';
import ENDPOINT from '../../../ultils/EndPoint';
import axios from 'axios';
import close from '../../../assets/img/Close_round.svg';
import {Button, Modal} from "react-bootstrap";

function AdressDelivaryShow() {
    const [data, setData] = useState([]);   
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [adressId, setAdressId] = useState('');
    const handleShow = (e) => {
        setShow(true)
        setAdressId(e.target.id);
    };

    // call api for list
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.ADRESS_USER}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-token') 
            }
            })
        .then((res) => {
            if (!unmounted) {
                setData(res.data.data)
            }
        })
        return () => { unmounted = true };
    }, []);

    const userAuthToken = localStorage.getItem('User-token');
 
    // set default adress
    const setDefaultAdress = (e) => {
        const config = {
            headers: { Authorization: `Bearer ${userAuthToken}` }
        };
        axios.post(`${ENDPOINT.ADRESS_USER_DEFAULT}/${e.target.id}`,{"jwt":userAuthToken}, config)
          .then(function (response) {
              window.location.reload()
          })
          .catch(error => {
            console.log(error);
          }
        )
    }

    // delete user adress 
    const handleDeleteUserAdress = (id) => {
        const config = {
            headers: { Authorization: `Bearer ${userAuthToken}` }
        };
        axios.delete(`${ENDPOINT.ADRESS_USER}/${id}`, config)
          .then(function (response) {
              window.location.reload()
          })
          .catch(error => {
            console.log(error);
          }
        )
    }

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
    }
    
    return (
        <div>
            <div className="changePass-wapper adressDelivery-show">
                <h1>Địa chỉ giao hàng</h1>
                <div className="adressDelivery-wapper">
                    {data.length > 0 ? <p>Danh sách địa chỉ</p> : <p>Bạn chưa có địa chỉ giao hàng nào</p> } 
                    <Link style={{'textDecoration':'none'}} to="/profile/address-delivery/address"><button className="adressDelivery-showBtn">Thêm mới địa chỉ</button></Link>
                </div>
                {data.length === 0 ? '' :  
                    <div className="adressDelivery-list">
                        {data
                        .sort((a,b) => b.default - a.default)
                        .map((adress, index) => 
                            <div key={index} className="user_adress">
                                <table className="table borderless">
                                    <tbody>
                                        <tr>
                                            <th style={{'paddingBottom':'0','paddingTop':'20px','paddingLeft':'30px', 'fontWeight':'normal', 'width':'130px'}} scope="row">Họ và tên</th>
                                            <td style={{'paddingBottom':'0','paddingTop':'20px'}} >{adress.name} {adress.default === 1 ? <span className='adress_default'>Địa chỉ mặc định</span> : ''}</td>
                                        </tr>
                                        <tr>
                                            <th style={{'paddingBottom':'0','paddingLeft':'30px', 'fontWeight':'normal'}} scope="row">Số điện thoại</th>
                                            <td style={{'paddingBottom':'0'}}>{adress.phone}</td>
                                        </tr>
                                        <tr>
                                            <th style={{'paddingBottom':'0','paddingLeft':'30px', 'fontWeight':'normal'}} scope="row">Địa chỉ</th>
                                            <td style={{'paddingBottom':'0'}} >{`${adress.address}, ${titleCase(adress.ward_name)}, ${titleCase(adress.district_name)}, ${adress.city_name}`}</td>
                                        </tr>
                                        <tr>
                                            <th className="edit_adress" scope="row"><Link to={`/profile/address-delivery/address/${adress.id}`}><span>Chỉnh sửa</span></Link></th>
                                            <td className="default_adress">{adress.default === 1 ? '' : <span id={adress.id} onClick={setDefaultAdress}>Đặt làm địa chỉ mặc định</span>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {adress.default === 1 ? '' :  <img className="close_btn_userAdress" alt="" id={adress.id} onClick={handleShow} style={{'cursor':'pointer'}} src={close}></img>}
                            </div>
                        )}
                    </div>
                } 

                <Modal className="modal_deleteAdressUser" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body  closeButton>
                        <p>Bạn có chắc chắn muốn xóa địa chỉ này khỏi danh sách ?</p>               
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Không
                        </Button>
                        <Button variant="primary" onClick={() => {
                           handleDeleteUserAdress(adressId)
                           handleClose()
                        }}>
                            Xoá địa chỉ
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default AdressDelivaryShow
