import React from 'react';
import arrow from "../../../assets/img/Arrow_right_long_light (1).svg";
import arrow1 from '../../../assets/img/Arrow_right_long_light.svg'
import {Link} from "react-router-dom";
import New from "../../common/news/New";

export default function NewsHomePage(props) {
    return(
        <div className="container pl-0 pr-0 ">
                <div className="hotNew" style={{marginBottom:'50px'}}>
                    <h1 className="mb-0">
                        <Link to='/tin-tuc-danh-muc/all' style={{color: '#C6910F', textTransform:'uppercase'}}>Tin hot từ {process.env.REACT_APP_NAME}</Link>
                        </h1>
                    {/*<Link to='/tin-tuc-danh-muc/all' className="hotNew_hover"> Xem thêm <img className="arrow-more" alt="" src={arrow} /><img className="arrow_moreHotNew" alt="" src={arrow1} /></Link>*/}
                </div>
            <div className="hotNew-body">
                { props.news.map(value => ( <New new={value}  key={value.id}/>))}
             </div>
            </div>
    )
}