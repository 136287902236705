import * as types from '../constans/ActionType';

const actions = {};
  
actions.signInRequest = (user) => {
    return {
        type: types.SIGNIN_REQUEST,
        payload: user,
    };
};

actions.getOTPAgains = (otp) => {
    return {
        type: types.GET_OTP,
        payload: otp,
    };
};

actions.getNoti = (noti) => {
    return {
        type: types.NOTI,
        payload: noti,
    };
};

actions.GetNotiChangeSocialProfile = (mess) => {
    return {
        type: types.NOTI_SOCIAL,
        payload: mess,
    };
}

actions.checkTime = (time) => {
    return {
        type: types.CHECK_TIME_OTP,
        payload: time,
    };
};

actions.getAuthMe = (data) => {
    return {
        type: types.AUTH_ME,
        payload: data,
    };
};

actions.loginSocialNetWerk = (data) => {
    return {
        type: types.SIGNIN_BY_SOCIAL_REQUEST,
        payload: data,
    };
};
  
actions.signUpRequest = (user) => {
    return {
        type: types.SIGNUP_REQUEST,
        payload: user,
    };
};

actions.signUpSuccess = (user) => {
    return {
      type: types.SIGNUP_SUCCESS,
      payload: user,
    };
  };

actions.logOut = () => {
    return {
        type: types.LOGOUT,
    };
};
  
export { actions };