import {
    GET_FAVORITE_PRODUCTS,
    DELETE_FAVORITE, 
    GET_LIST_FAVORITE_PRODUCTS,
    ADD_FAVORITE
  } from "../constans/ActionType";
  
  const init = {
    favorite_products: [],
    listDataGet: [],
    allFavor:[]
  }

  export const getFavoriteProduct = (state = init, action) => {
    switch (action.type) {
      case GET_FAVORITE_PRODUCTS:
        return {...state, favorite_products : action.payload}

      case GET_LIST_FAVORITE_PRODUCTS:
        return {...state, listDataGet : action.payload}

      case DELETE_FAVORITE:
        const newFavor = [...state.favorite_products.filter((x) => x.id !== action.payload)];
        return {
            ...state,
            favorite_products: newFavor
      }
      case ADD_FAVORITE:
        return {...state, allFavor : action.payload}

      default:
        return state;
    }
  }