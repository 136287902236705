import * as type from '../constans/ActionType';


/*GET_ALL_PRODUCT*/
export function GetAllDataCart(payload){
    return{
        type: type.GET_ALL_DATA_CART,
        payload
    }
}

/*GET NUMBER CART*/
export function GetNumberCart(){
    return{
        type: type.GET_NUMBER_CART
    }
}
 
export function AddCart(payload){
    return {
        type: type.ADD_CART,
        payload
    }
}
export function UpdateCart(payload){
    return {
        type: type.UPDATE_CART,
        payload
    }
}
export function DeleteCart(payload){
    return{
        type: type.DELETE_CART,
        payload
    }
}
 
export function IncreaseQuantity(payload){
    return{
        type: type.INCREASE_QUANTITY,
        payload
    }
}
export function DecreaseQuantity(payload){
    return{
        type: type.DECREASE_QUANTITY,
        payload
    }
}
