import React from 'react';
import imgNotPurchase from '../../../assets/img/profile/surprise 1.png';
import iconBuy from '../../../assets/img/cart/Group.png';
function HistoryPointEmpty() {
    return (
        <div className="content_purchase">
            <div className="changePass-wapper" style={{marginLeft: "0px"}}>
                <div style={{boxShadow:'none'}} className="table_purchase">
                    <img src={imgNotPurchase} alt=""/>
                    <h2>Bạn chưa tích điểm cho đơn hàng nào</h2>
                    <a onClick={()=> window.location = '/'}>
                        <img src={iconBuy} alt=""/>
                        <span>Quay lại mua sắm</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default HistoryPointEmpty;
