import React from 'react';
import './category.scss';
import $ from 'jquery'
import 'slick-carousel/slick/slick';
import arrowLeft from '../../../assets/img/Vector 9.svg'
import arrhoverLeft from '../../../assets/img/Vector 9 copy.svg'
import arrowRight from '../../../assets/img/Vector 9 (1).svg'
import arrhoverRight from '../../../assets/img/Vector 9 (1) copy.svg'
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

function Category({name}) {
    const categories = useSelector(state => state.getCategoryProduct.categories);
    if(categories.length < 9) {
        $('.category-carousel').addClass('full_content')
    }
    $(function () {
        if (categories && categories.length) {
            $('.category-carousel').not('.slick-initialized').slick({
              dots: false,
              infinite: true,
              speed: 500,
              slidesToShow: 8,
              slidesToScroll: 1,
              autoplay: true,
              prevArrow: '.arrowLeft1',
              nextArrow: '.arrowRight1',
              responsive: [
                  {
                      breakpoint: 1008,
                      settings: {
                          slidesToShow: 6,
                          slidesToScroll: 1,
                      }
                  },
                  {
                      breakpoint: 768,
                      settings: {
                          slidesToShow: 3,
                          slidesToScroll: 1,
                      }
                  },
                  {
                      breakpoint: 767,
                      settings: {
                          slidesToShow: 3,
                          slidesToScroll: 1,
                      }
                  }
  
              ]
            })
          }

      })

    // <div className="button_mobile">
    //     <p className="arrow arrowLeft1"><img alt="" className="arrow-1" src={arrowRight}></img> <img alt="icon_next" className="arrow-2" src={arrhoverRight}></img></p>
    //     <p className="arrow arrowRight1 ml-2"><img alt="" className="arrow-3" src={arrowLeft}></img> <img alt="icon_prev" className="arrow-4" src={arrhoverLeft}></img></p>
    // </div>

    return (
        <div className="container">
            <div className="row">
                <div className="category-wapper">
                    <div className="title_category_wrapper text-center justify-content-center">
                        <h2 style={{color: '#C6910F', textTransform:'uppercase'}}>
                            {name}
                        </h2>
                      {categories.length > 8 ?
                        <div className="button_slick_category">
                            <p className="arrow arrowLeft1"><img alt="" className="arrow-1" src={arrowRight}></img> <img alt="icon_next" className="arrow-2" src={arrhoverRight}></img></p>
                            <p className="arrow arrowRight1 ml-2"><img alt="" className="arrow-3" src={arrowLeft}></img> <img alt="icon_prev" className="arrow-4" src={arrhoverLeft}></img></p>
                        </div>
                        : ''
                          
                      }
                    </div>

                    <div className="category-carousel">
                        {categories.map(category => {
                            return(
                                <div key={category.id} className="category-child">
                                  <Link  to={"/danh-muc/" + category.slug}>
                                    <img alt={category.name} src={category.icon}></img>
                                    <p>{category.name}</p>
                                  </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Category
