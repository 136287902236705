import React, {useEffect, useState} from 'react'
import icon_drop from '../../../assets/img/Rectangle 130.png';
import logo_default from '../../../assets/img/avatar-default.jpg';
import logo_admin from '../../../assets/img/logo_admin_citimed.png'
import icon_file from '../../../assets/img/XMLID 236.png'
import icon_send from '../../../assets/img/send-message-button 1.png'
import $ from 'jquery'
import {formatDateTime, toBase64} from "../../../helpers/Function";
import {EventJoinChannel} from "../../../helpers/constant";
import trim from "validator/es/lib/trim";
import {ApiService} from "../../../ultils/EndPoint";
import './chat.scss';
import {useDispatch, useSelector} from "react-redux";
import { websiteActions } from '../../../actions';


function Chat() {

    // const dispatch = useDispatch();
    // const stateOpenChat = useSelector(state => state.getWebsite)
    //
    // useEffect(() => {
    //     if(stateOpenChat.openChat === true){
    //         $('.content_chat').show();
    //     }
    // }, [stateOpenChat.openChat])

    let user = {
        Avatar: logo_default
    }

    let image = {
        mine_type : "",
        value : "",
        file_name : ""
    }

    let isAuth = false


    function getMessageAdmin(message) {
        let option1 = ''
        message.extra_data && message.extra_data.map((option, key) => {
                option1 += "<li class='script_option' data-content='" + option.name_option + "' data-value='" + option.script_option_id + "'>" + option.name_option + "</li>"
            }
        )

        let content = message.content
        if (message.files && message.files.length){
            message.files.forEach(file => {
                content += "<br/><img width='100' height='100' src='" + file + "' alt='image-chat'/>"
            })
        }
        return `
                <div class="admin_comment">
                    <div class="d-flex">
                        <div class="infomation">
                            <span>Admin Citimed <strong>${formatDateTime(message.time)}</strong></span>
                            <div class="d-flex">
                                <img src=${logo_admin} alt="" class="avatar_admin"/>
                                <ul class="list_chat_admin pl-0">
                                    <li>
                                        <span>${content}</span>
                                    </li>
                                    ${option1}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                `
    }

    function getMessageCustomer(message) {
        let content = message.content
        if (message.files && message.files.length){
            message.files.forEach(file => {
                content += "<br/><img width='100' height='100' src='" + file + "' alt='image-chat'/>"
            })
        }
        return `<div class="customer_comment">
                    <div class="d-flex justify-content-end">
                        <div class="infomation">
                            <span><strong>${formatDateTime(message.time)}</strong></span>
                            <div class="d-flex justify-content-end">
                                <ul class="list_chat_admin pl-0">
                                    <li>
                                        <span>${content}</span>
                                    </li>
                                </ul>
                                <img src='${user.Avatar}' alt="" class="avatar_admin"/>
                            </div>
                        </div>
                    </div>
                </div>`
    }

    function getChat(message) {
        let data = ''
        if (message.model_id === "admin") {
            data = getMessageAdmin(message)
        } else {
            data = getMessageCustomer(message)
            // if (message.extra_data) {
            //     data += getMessageAdmin(message.extra_data)
            // }
        }
        return data
    }

    async function handleUploadImage(e) {
        $('.previewFile').remove()
        $('.contain-preview').append(
            `<div style="position: relative" class="mt-3 previewFile d-flex justify-content-end">
                   <button class="close-preview">x</button>
                    <img id="file-preview" alt="preview-file" width=${100} height=${100}/>
                </div>`
        )
        let file = e.target.files[0]
        let base64 = await toBase64(file)
        let extra = base64.split(/^(data:(.*?);base64,)/)
        image.mine_type = extra[2]
        image.value = extra[3]
        image.file_name = file.name
        $('#file-preview').attr("src", URL.createObjectURL(file));
    }


    function removePreview(){
        $('#file_upload').val("")
        $('.previewFile').remove()
    }

    $('#btn_hide_chat').on('click', function () {
        $('.content_chat').hide();
        // dispatch(websiteActions.setOpenChat(false))
    })

    function setEmptyImage(){
        image = {}
    }

    function scrollBottom() {
        $('.comment_chat').animate({scrollTop: 9999999999});
    }

    $(function (){
        $('.popupChat').on('click', function () {
            $('.content_chat').show();
            scrollBottom()
        })

        $('#btn_hide_chat').on('click', function () {
            $('.content_chat').hide();
        })
    })

    function connect(id){
        let ws = new WebSocket(process.env.REACT_APP_DOMAIN_CHAT + 'ws/' + id + '?authorization=' + (isAuth ? 1 : 0))
        ws.onopen = async () => {
            console.log("connected")
            $(document).ready(function () {
                $(".script_option").unbind();
                $(document).on('click', '.script_option', function () {
                    let data = {}
                    data.model_id = user.ID.toString()
                    data.content = $(this).data("content")
                    data.script_id = $(this).data("value")
                    data.chat_with = "user-admin"
                    data.user_id = "admin"
                    scrollBottom()
                    sendMessage(data)
                })

                $(document).on('click', '.close-preview', function () {
                    removePreview()
                    setEmptyImage()
                })

                setInterval(function(){
                    let data = {}
                    data.event = 1
                    ws.send(JSON.stringify(data))
                }, 50000);


                $(document).on('click', '.btn-send', function () {
                    let content = trim($('#input-chat').val())
                    if (!content && !image.value) {
                        $('#input-chat').css('border', '1px solid red')
                        return
                    }
                    if (content || image.value) {
                        let data = {}
                        data.model_id = user.ID.toString()
                        data.content = content
                        data.script_id = 0
                        data.chat_with = "user-admin"
                        data.user_id = "admin"
                        data.file = [image]
                        scrollBottom()
                        sendMessage(data)
                        $('#input-chat').css('border', '1px solid #E0E0E0')
                        $('#input-chat').val("")
                        removePreview()
                        setEmptyImage()
                    }
                })

                $('#input-chat').keyup(function (e) {
                    if (e.keyCode == 13) {
                        // $(this).trigger("enterKey");
                        $('.btn-send').click()
                    }
                });
            })
        }

        ws.onmessage = evt => {
            // listen to data sent from the websocket server
            const message = JSON.parse(evt.data)
            console.log(message)
            if (message) {
                if (message.event == EventJoinChannel && message.room.length && message.room[0].chat) {
                    $('.comment_chat').html(`<div class="contain-preview"></div>`)
                    let listChat =  message.room[0].chat.map(chat => {
                        return getChat(chat)
                    })
                    $('.comment_chat').append(listChat)
                } else {
                    if (message) {
                        $('.comment_chat').append(getChat(message.room[0].last_message))
                    }
                }
            }
        }

        ws.onclose = () => {
            connect(id)
            console.log('disconnect')
        }

        function isOpen(ws) { return ws.readyState === ws.OPEN }

        function sendMessage(data) {
            if (!isOpen(ws)){
                return
            }
            ws.send(JSON.stringify(data))
        }
    }

    useEffect(async function () {
        await ApiService.get('client/me', {}, true).then(r => {
            if (r.status) {
                user = r.data
                isAuth = true
            } else {
                let userIdNotAuth = localStorage.getItem("userIdNotAuth")
                if (userIdNotAuth){
                    user.ID = userIdNotAuth
                }else{
                    var crypto = require("crypto");
                    user.ID = crypto.randomBytes(32).toString('hex')
                    localStorage.setItem('userIdNotAuth', user.ID)
                }
            }
        })
        connect(user.ID)
    })

    return (
        <div className="content_chat">
            <div className="title_chat d-flex justify-content-between">
                <h5>Trò chuyện cùng dược sĩ</h5>
                <button type="button" id="btn_hide_chat">
                    <img src={icon_drop} alt=""/>
                </button>
            </div>
            <div className="comment_chat">
                {/*/!*<div className="admin_comment">*!/*/}
                {/*/!*    <div className="d-flex">*!/*/}
                {/*//         <div className="infomation">*/}
                {/*//             <span>Thu Hiền _dược sĩ citimed <strong>16:24 24/16/2021</strong></span>*/}
                {/*//             <div className="d-flex">*/}
                {/*/!*                <img src={logo_admin} alt="" className="avatar_admin"/>*!/*/}
                {/*/!*                <ul className="list_chat_admin pl-0">*!/*/}
                {/*/!*                    <li>*!/*/}
                {/*/!*                        <span>Cảm ơn bạn đã liên hệ CitiMED. Chúng tôi sẵn sàng giải đáp các vấn đề của bạn</span>*!/*/}
                {/*/!*                    </li>*!/*/}
                {/*/!*                    <li>*!/*/}
                {/*/!*                        <span>Tôi cần tư vấn về sức khỏe</span>*!/*/}
                {/*/!*                    </li>*!/*/}
                {/*//                     <li>*/}
                {/*//                         <span>Tôi cần gặp dược sĩ</span>*/}
                {/*/!*                    </li>*!/*/}
                {/*/!*                    <li>*!/*/}
                {/*/!*                        <span>Tôi cần tư vấn mua hàng</span>*!/*/}
                {/*/!*                    </li>*!/*/}
                {/*/!*                </ul>*!/*/}
                {/*/!*            </div>*!/*/}
                {/*/!*        </div>*!/*/}
                {/*/!*    </div>*!/*/}
                {/*/!*</div>*!/*/}
                {/*<div className="customer_comment">*/}
                {/*    <div className="d-flex justify-content-end">*/}
                {/*        <div className="infomation">*/}
                {/*            <span>Duy Long<strong>16:24 24/16/2021</strong></span>*/}
                {/*            <div className="d-flex justify-content-end">*/}
                {/*                <ul className="list_chat_admin pl-0">*/}
                {/*                    <li>*/}
                {/*                        <span>Cảm ơn bạn đã liên hệ CitiMED. Chúng tôi sẵn sàng giải đáp các vấn đề của bạn</span>*/}
                {/*                    </li>*/}
                {/*                </ul>*/}
                {/*                <img src={logo_customer} alt="" className="avatar_admin"/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="admin_comment">*/}
                {/*    <div className="d-flex">*/}
                {/*        <div className="infomation">*/}
                {/*            <span>Thu Hiền _dược sĩ citimed <strong>16:24 24/16/2021</strong></span>*/}
                {/*            <div className="d-flex">*/}
                {/*                <img src={logo_admin} alt="" className="avatar_admin"/>*/}
                {/*                <ul className="list_chat_admin pl-0">*/}
                {/*                    <li>*/}
                {/*                        <span>Cảm ơn bạn đã liên hệ CitiMED. Chúng tôi sẵn sàng giải đáp các vấn đề của bạn <a*/}
                {/*                            href="#">https://google.com</a></span>*/}
                {/*                    </li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className="enter_chat d-flex">
                <div className="upload_file">
                    <input id="file_upload" onChange={(e) => {
                        handleUploadImage(e)
                    } } accept="image/*" type="file"/>
                    <button type="button">
                        <img src={icon_file} alt=""/>
                    </button>
                </div>

                <input type="text" id="input-chat" className="form-control" placeholder="Chat ở đây..."/>
                <button type="button" className="btn-send">
                    <img src={icon_send} alt=""/>
                    <span>Gửi</span>
                </button>
            </div>
        </div>
    )
}

export default Chat
