import React, {useEffect, useState} from "react";
import $ from 'jquery';
import 'slick-carousel/slick/slick';
import '../header/header.scss';
import left from '../../../assets/img/Arrow_drop_left copy.svg'
import right from '../../../assets/img/Arrow_drop_left.svg'
import LazyLoad from 'react-lazyload';
import {BannerApiService} from "../../../ultils/EndPoint";
import {Link} from "react-router-dom";
import {isMobile} from 'react-device-detect';

function SliderShow() {
  const [bannerMain, setBannerMain] = useState([]);
  //banner-main
  useEffect(() => {
    const TYPE_MAIN_BANNER = 1;
    BannerApiService.getBannerByType(TYPE_MAIN_BANNER).then(res => {
      let banners = res.data;
      if (banners) {
        setBannerMain(banners)
      }
    })
  }, [])

  $(function () {
    if (bannerMain.length) {
      $('.single-items').not('.slick-initialized').slick({
        dots: true,
        infinite: true,
        dotsClass: 'slick-dot-customer slick-dots slick-thumb',
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        prevArrow:".arrow-left-header",
        nextArrow:".arrow-right-header",
      })
    }
  })

  if (bannerMain.length) {
    return (
      <div className="slider-wapper">
        <div className="single-items">
          {bannerMain.map(banner => {
            return(
              <div key={banner.id}>
                <Link to={{pathname: `${banner.link}`}} target="_blank">
                  {!isMobile ?
                  <LazyLoad height={200}>
                    <img alt="banner_sale" src={banner.image} className="banner_home"></img>
                  </LazyLoad>
                    : <LazyLoad height={200}>
                    <img alt="banner_sale" src={banner.image_mobile ? banner.image_mobile : banner.image} className="banner_home"></img>
                    </LazyLoad> }
                </Link>
              </div>)
          })}
        </div>
        <div className="arrow-left-header">
          <img alt="" src={left}></img>
        </div>
        <div className="arrow-right-header">
          <img alt="" src={right}></img>
        </div>
      </div>
    )
  }
  return null;
}

export default SliderShow

