import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import icon_login_gg from "../../assets/img/icons8-google 1.png";
import icon_login_fb from "../../assets/img/icons8-facebook 1.png";
import icon_register from "../../assets/img/Expand_right_double_light.png";
import Verification from "../auth/account_verification"
import isEmpty from "validator/lib/isEmpty";
import ENDPOINT from '../../ultils/EndPoint';
import axios from 'axios';
import $ from 'jquery';
import { actions } from '../../actions/User';
import { useDispatch } from 'react-redux';
import Login from './login';
import * as LoginSocialNetWerk from '../../components/common/social-login/Social_login';
import { websiteActions } from '../../actions';

function AccountPhone (props) {
    // regex for test
    const dispatch = useDispatch()
    const isPhoneNumber = new RegExp("^[0-9]*$")
    const [show, setShow] = useState(false);
    const [showVerification, setshowVerification] = useState(false);
    const handleClose = () => setShow(props.close);
    const [validationMsg, setValidationMsg] = useState({});
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phonePassthrough, setPhonePassthrough] = useState('');
    const [msg, setMsg] = useState('')

    const [disable, setDisable]= useState(false);

    const handleInputPhone = (e) => {
        setPhoneNumber(e.target.value)
    } 

    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(isEmpty(phoneNumber)) {
            msg.phoneNumber = 'Vui lòng nhập số điện thoại'
            $('.form-phone').addClass('red_blur')
        }else if (!isPhoneNumber.test(phoneNumber) ||  phoneNumber.length !== 10){
            $('.form-phone').addClass('red_blur')
            msg.phoneNumber = 'Số điện thoại không hợp lệ. Số điện thoại là 10 số, bắt đầu bằng số 0'
        }else if(phoneNumber.charAt(0) !== '0') {
            $('.form-phone').addClass('red_blur')
            msg.phoneNumber = 'Số điện thoại không hợp lệ. Số điện thoại là 10 số, bắt đầu bằng số 0'
        }

        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    const handleGetOTP = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 5000);
        
        let query = '';
        const data = {
            phone : phoneNumber,
            type: 1,
        }
        Object.keys(data).map((key,index) => 
        query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )
        axios.post(`${ENDPOINT.REGISTERGETOTP}`+ query, data)
        .then(function (response) {
            if(response.data.data) {
                $('.form-phone').removeClass('red_blur')
                setPhonePassthrough(response.data.data.Phone)
                // setIdOtp(response.data.data.id);
                dispatch(actions.getOTPAgains(response.data.data.id))
                handleCloseModal()
                setshowVerification(true)
            }
        })
        .catch(error => {
            if(error.request.status) {
                $('.form-phone').addClass('red_blur')
                setMsg('Số điện thoại đã được đăng ký. Bạn có thể đăng nhập ngay. Nếu đây không phải là tài khoản của bạn, vui lòng liên hệ với chúng tôi')
            }
        }) 
    }

    const handlePressPhoneNumber = () => {
        setMsg('')
        $('.form-phone').removeClass('red_blur')
        setValidationMsg('')
    }

    const handleCloseModal = () => {
        setPhoneNumber('')
        setValidationMsg('')
        handleClose();
    }

    useEffect(() => {
        $('input[name="inputForNumber"]').keypress
        (
        function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else 
                {
                    if (event.keyCode < 48 || event.keyCode > 57 ) 
                    {
                        event.preventDefault();	
                    }	
                }
            }
        );
    })

    const handleRemove = () => {
        setMsg('')
        setValidationMsg('')
        handleClose()
    }
    
    // set show for modal
    const [showLogin, setShowLogin] = useState(false);
    return (
        <div>
            <Verification handleGetOTP={handleGetOTP} phone={phonePassthrough} show={showVerification} close={() => setshowVerification(false)} onHide={() => setshowVerification(false)}/>
                {
                    showLogin === true 
                    ? <Login show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
                    : 
                 <Modal show={props.show} onHide={handleRemove} className="modal-login" >
                    <Modal.Header closeButton  className="grid">
                        <Modal.Title>Đăng ký tài khoản</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="mt-4">
                        <Form className="form_pwd">
                            <div className="form-group">
                                <input onChange={handleInputPhone} name="inputForNumber" type="text" onKeyDown={handlePressPhoneNumber} className="form-control form-phone" placeholder="Nhập số điện thoại của bạn"/>
                                <p style={{'fontSize': '12px', 'marginBottom':'0px'}} className="text-danger">{validationMsg.phoneNumber}</p> 
                                <p style={{'fontSize': '12px'}} className="text-danger">{msg}</p> 
                            </div>
                            <div className="form-group">
                                <Button onClick={handleGetOTP} disabled={disable} className="button_acceprt">Đăng ký</Button>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="form-group w-100">
                            <label htmlFor="">Bạn đã có tài khoản?</label>
                            <div className="d-flex w-100">
                                <Button className="btn_login_other">
                                    <img src={icon_login_gg} alt=""/>
                                </Button>
                                <LoginSocialNetWerk.GoogleLoginAuth/>
                                <Button className="btn_login_other">
                                    <img src={icon_login_fb} alt=""/>
                                </Button>
                                <LoginSocialNetWerk.FaceBookLoginAuth/>
                                <Button onClick={() => {
                                    handleClose()
                                    // setShowLogin(true)
                                    dispatch(websiteActions.setModal(true))
                                }} className="btn_register btn_login">
                                    Đăng nhập ngay
                                    <img src={icon_register} alt=""/>
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
                }
        </div>
    )
}

export default AccountPhone;
