import React from 'react';
import {isMobile} from "react-device-detect";

function ListBannerCategory({banners}) {
  if (banners) {
    return (
      <div className="grid grid-4 grid-gap-1m0">
        {banners.map((item, index) => {
          return (!isMobile ? <img style={{width:'218px', height:'150px'}} key={index} src={item.image} alt="banner-list-category"/> :  <img style={{width:'218px', height:'150px'}} key={index} src={item.image_mobile ? item.image_mobile : item.image} alt="banner-list-category"/>)
        })}
      </div>
    )
  }
  return '';
}

export default ListBannerCategory;
