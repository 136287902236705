import {
    GET_FAVORITE_PRODUCTS,
    DELETE_FAVORITE,
    GET_LIST_FAVORITE_PRODUCTS,
    ADD_FAVORITE
  } from "../constans/ActionType";
  
  
  export function setFavoriteProduct(favoProduct){
    return { type: GET_FAVORITE_PRODUCTS, payload: favoProduct };
  };
  
  export function setListFavoriteProduct(favoListProduct){
    return { type: GET_LIST_FAVORITE_PRODUCTS, payload: favoListProduct };
  };

  export function addFavorite(payload){
    return {
        type: ADD_FAVORITE,
        payload
    }
}
   
  export function deleteFavorItem(payload){
    return{
        type: DELETE_FAVORITE,
        payload
    }
}