import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import './sidebar.scss';
import icon_dropdown from '../../../assets/img/Group_54_1.svg';
import icon_dropdown_red from '../../../assets/img/Group_54_3.svg';
import icon_dropdown_child from '../../../assets/img/Group_54_2.svg';
import $ from 'jquery';
import {useSelector} from "react-redux";
import Child from './child';

function SideCategory({category}) {
    const [idActive, setIdActive] = useState([]);
    const categories = useSelector(state => state.getCategoryProduct.categories);
    useEffect(() => {
        $('.parent_li').off('click').on('click',function () {
          $(this).find('.icon_drop').toggleClass('active')
          $(this).parent().find('.list_menu_son').toggleClass('active')
        })
      $('.child_li').off('click').on('click',function () {
        $(this).find('.icon_drop').toggleClass('active')
        if ($(this).find('.icon_drop').hasClass('active')) {
          $(this).find('.icon_drop').attr('src', icon_dropdown_red)
        } else {
          $(this).find('.icon_drop').attr('src', icon_dropdown_child)
        }
        if($(this).has('img.icon_drop')) {
          $(this).find('.parent_child').toggleClass('active')
        }

        $(this).parent().find('.list_content_side_menu').toggleClass('active')
      })
    })
    return (
        <div className="sidebar_category">
            <ul className="side-menu">
                {(categories || []).map(category => {
                    return (<li key={category.id}>
                        <div className="d-flex justify-content-between parent_li">
                            <Link to={"/danh-muc/" + category.slug} className="parent_link">{category.name}</Link>
                            {category.childs && category.childs.length ? <img src={icon_dropdown} alt="icon dropdown" className="icon_drop"/> : null}
                        </div>
                        <div className="list_menu_son">
                          {category.childs && category.childs.length ?
                            category.childs.map(child => {
                              return (
                                <div key={child.id} className="content_side_menu">
                                  <div className="d-flex justify-content-between child_li">
                                    <Link to={"/danh-muc/" + child.slug}><span className="parent_child">{child.name}</span></Link>
                                    {child.Child && child.Child.length ? <img src={icon_dropdown_child} alt="icon dropdown" className="icon_drop icon_color"/> : null}
                                  </div>
                                  <Child data={child.Child}/>
                              </div>)
                            })
                          : null}
                        </div>
                    </li>)
                })}

            </ul>
        </div>
    )
}
export default SideCategory;
