import { all } from 'redux-saga/effects';
import { userWatcher } from './User';

function* rootSaga() {
    yield all([
        userWatcher()
    ]);
}

export default rootSaga;
