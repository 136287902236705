import React from 'react';
import './product.scss';
import '../../home-page/brand/branch.scss';
import img1 from '../../../assets/img/Rectangle 63 (1).png'
import img2 from '../../../assets/img/Rectangle 63 (2).png'
import img3 from '../../../assets/img/Rectangle 63 (5).png'
import img4 from '../../../assets/img/Rectangle 63 (4).png'
import img5 from '../../../assets/img/Rectangle 63 (3).png'
import $ from 'jquery';
import arrowLeft from '../../../assets/img/Vector 9.svg'
import arrhoverLeft from '../../../assets/img/Vector 9 copy.svg'
import arrowRight from '../../../assets/img/Vector 9 (1).svg'
import arrhoverRight from '../../../assets/img/Vector 9 (1) copy.svg'
import LazyLoad from "react-lazyload";
import img7 from "../../../assets/img/Rectangle 63 (7).png";
import loveIn from "../../../assets/img/fill 16 - white.svg";
import icon_cart from "../../../assets/img/Group 68.svg";

function ProductView(props) {

    $(function () {
        $('.branch-carouseld').not('.slick-initialized').slick({
            dots: false,
            infinite: true,
            speed: 500,
            autoplay:true,
            slidesToShow: 4,
            slidesToScroll: 1,
            prevArrow: '.arrowLeft',
            nextArrow: '.arrowRight'
        })
    })

    return (
        <div className="branch-wapper product_views">
            <div className="branch-title">
                <h2>{props.name}</h2>
                <div className="button_slide">
                    <p className="arrow arrowLeft "><img alt="" className="arrow-1" src={arrowRight}></img> <img alt=""
                                                                                                                 className="arrow-2"
                                                                                                                 src={arrhoverRight}></img>
                    </p>
                    <p className="arrow arrowRight ml-2"><img alt="" className="arrow-3" src={arrowLeft}></img> <img alt=""
                                                                                                                     className="arrow-4"
                                                                                                                     src={arrhoverLeft}></img>
                    </p>
                </div>
            </div>
            <div className="position-relative">
                <div className="branch-carouseld">
                {props.dataProductSeen ?
                    props.dataProductSeen.map((seen, index) => 
                        <div key={index} className="child">
                            <LazyLoad height={200}>
                                <div className="discount-wapper">
                                    {seen.medias !== null 
                                    ?
                                        seen.medias.map(check => 
                                            check.collection_name === 'single' ? <img alt=""  src={check.image} /> : ''
                                        )
                                    : 
                                    ''}
                                    <p className="discount">2%</p>
                                    <div className="loveCount">
                                        <img src={loveIn} style={{'width': '18px', 'height': '18px'}} alt=""></img>
                                    </div>
                                </div>
                            </LazyLoad>
                            <div>
                                <p>{seen.name}</p>
                                <div className="price-product">
                                    <div className="d-flex justify-content-center align-items-baseline">
                                        <h2>{new Intl.NumberFormat('it-IT', {style : 'currency', currency : 'VND'}).format( seen.price - ((seen.product_sell * seen.price)/100))}</h2>
                                        <h4 className="ml-2">{new Intl.NumberFormat('it-IT', {style : 'currency', currency : 'VND'}).format(seen.price)}</h4>
                                    </div>
                                    <div className="cart-hover">
                                        <img alt="" src={icon_cart} style={{'width': '16px', 'height': '16px'}}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                : ''}
                </div>
            </div>
        </div>
    )
}

export default ProductView;
