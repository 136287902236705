import React, {useEffect, useState} from 'react'
import $ from 'jquery'
import {PrescriptionApiService} from "../../../ultils/EndPoint";
import {convertDateTime, objectToQueryParams} from "../../../helpers/Function";
import {Pagination} from "antd";
import trim from "validator/es/lib/trim";
import imgNotPurchase from "../../../assets/img/sleeping-pills 1.png";
import {Link} from "react-router-dom";

function Prescription() {
    const [listPrescription, setListPrescription] = useState([]);

    const [customersPerPage] = useState(4)

    const limitNote = 400;
    const [dataPaginate, setDataPaginate] = useState({
        "currentPage" : 1
    })

    const getListPrescription = (query) => {
        PrescriptionApiService.getList(query).then(r => {
            if (r.status){
                setListPrescription(r.data.records)
                setDataPaginate({
                    "totalRecord" : r.data.total_record,
                    "totalPage"   : r.data.total_page,
                    "currentPage"   : r.data.page,
                    "perPage"   : r.data.limit,
                })
            }
        })
    }

    $(function (){
        $('.btn_show').on('click',function () {
            $(this).hide()
            $(this).parents('.grid').find('.note_child').css('display', 'none')
            $(this).parents('.grid').find('.note_child_hidden').css('display', 'inline')
            $(this).next().show()
        })
        $('.btn_hide').on('click',function () {
            $(this).hide()
            $(this).parents('.grid').find('.note_child').css('display', '-webkit-box')
            $(this).parents('.grid').find('.note_child_hidden').css('display', 'none')
            $(this).prev().show()
        })
    })

    useEffect(() => {
        let data = {}
        data.per_page = customersPerPage
        let query = objectToQueryParams(data)
        getListPrescription(query)

    }, [])

    const setCurrentPage = (page) => {
        let data = {}
        data.page = page
        data.per_page = customersPerPage
        let query = objectToQueryParams(data)
        getListPrescription(query)
    }
    return (
        <div className="content_purchase">
            <div className="changePass-wapper">
                <h1>Đơn thuốc tư vấn online</h1>
                <ul className="prescription_form pl-0 pt-0">
                    {listPrescription ? listPrescription.map((item, key) =>
                        <li key={key} style={{marginTop : "30px"}}>
                            <h5>{process.env.REACT_APP_NAME}-{item.id}</h5>
                            <span className="date_time">{convertDateTime(item.created_at, 'DD/MM/YYYY HH:mm:ss')}</span>
                            <div className="d-flex w-100">
                                <img src={item.image} alt=""/>
                                <div className="grid w-100">
                                    <div className="d-flex">
                                        <span>Họ tên:</span>
                                        <p>{item.full_name}</p>
                                    </div>
                                    <div className="d-flex">
                                        <span>Email:</span>
                                        <p>{item.email}</p>
                                    </div>
                                    <div className="d-flex">
                                        <span>Số điện thoại: </span>
                                        <p>{item.phone}</p>
                                    </div>
                                    <div className="d-flex address_profile">
                                        <span>Địa chỉ: </span>
                                        <p>{item.address + " , " + item.ward_name + " , " + item.district_name + " , "+ item.city_name}</p>
                                    </div>
                                    <div className="grid">
                                        <div className="">
                                            <span className="title_note">Ghi chú: </span>
                                            <span className="note_child">{item.note.length > limitNote ? item.note.substring(0, limitNote) + '...' : item.note}</span>
                                            <span className="note_child_hidden" style={{display: 'none'}}>{item.note}</span>
                                        </div>

                                        <div className="grid">
                                            {
                                                item.note.length > limitNote ? <a className="btn_show">Xem thêm</a> : ''
                                            }

                                            <a className="btn_hide" style={{display: 'none'}}>Thu gọn</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ) : <div className="content_purchase">
                            <div className="changePass-wapper pl-0">
                                <div className="table_purchase prescription">
                                    <img src={imgNotPurchase} alt=""/>
                                    <h2>Bạn chưa có đơn thuốc nào được tải lên</h2>
                                    <Link to="/profile/prescription/create">
                                        <span>Đăng đơn thuốc</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    }
                </ul>
            </div>

            {dataPaginate.totalRecord <= customersPerPage ? '' :
                <Pagination
                    onChange={(value) => setCurrentPage(value)}
                    total={dataPaginate.totalRecord}
                    current={dataPaginate.currentPage}
                    defaultPageSize={customersPerPage}
                    showSizeChanger= {false}
                />
            }

        </div>
    )
}

export default Prescription
