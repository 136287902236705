import {
  GET_GROUPS,
} from "../constans/ActionType";

const actions = {};

actions.setGroups = (brands) => {
  return { type: GET_GROUPS, payload: brands };
};

export { actions };
