import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {Pagination} from "antd";
import OrderView from '../../common/ViewOrder';
import {CouponApiService} from "../../../ultils/EndPoint";
import {convertDateTime, formatCurrency, objectToQueryParams} from "../../../helpers/Function";
import CouponEmpty from "./CouponEmpty";
import { useHistory } from 'react-router';
function Purcharse() {
    const TYPE_NEW = 1
    const TYPE_USED = 2
    const [showOrderView, setShowOrdeView] = useState(false);
    const [listCoupon, setListCoupon]  = useState([])
    const [type, setType]  = useState(1)
    const [dataPaginate, setDataPaginate] = useState({
        "currentPage" : 1
    })
    const [customersPerPage] = useState(10)


    const setCurrentPage = (page) => {
        let data = {}
        data.page = page
        data.per_page = customersPerPage
        data.type = type
        let query = objectToQueryParams(data)
        getListCoupon(query)
    }

    const handleChangeTab = (e) => {
        setType(parseInt(e))
    }

    function getListCoupon(query){
        CouponApiService.getList(query).then(r => {
            if (r.status){
                setListCoupon(r.data.records)
                setDataPaginate({
                    "totalRecord" : r.data.total_record,
                    "totalPage"   : r.data.total_page,
                    "currentPage"   : r.data.page,
                    "perPage"   : r.data.limit,
                })
            }
        })
    }
    useEffect(() => {
        let query = '?type='+type
        getListCoupon(query)
    }, [type])

    const history = useHistory()
    return (
        <div className="content_purchase">
            <OrderView show={showOrderView} close={() => setShowOrdeView(false)} onHide={() => setShowOrdeView(false)} />
            <div className="changePass-wapper">
                <h1>Ưu đãi của tôi</h1>
                <Tabs onSelect={handleChangeTab} defaultActiveKey={type} id="uncontrolled-tab-example" className="tab_purchase d-flex">
                    <Tab eventKey="1" title="Mới">
                        <ul className="list_voucher">
                            {type === TYPE_NEW && listCoupon ?  listCoupon.map((coupon, key) =>
                                <li key={key}>
                                    <div className="col-9 pl-0 pr-0">
                                        <h5 className="name_voucher">{coupon.code}</h5>
                                        <span className="price_voucher">Giá trị: {coupon.unit == 0 ? formatCurrency(coupon.value) + " VNĐ" : coupon.value + '%'}</span>

                                        <div className="grid">
                                            <p>HSD: {convertDateTime(coupon.end_time, 'DD/MM/YYYY')}</p>
                                            <p>Giá trị đơn hàng tối thiểu: {formatCurrency(coupon.min_order_value)} VND</p>
                                            <p>Số lượng : {coupon.number_of_use}</p>
                                        </div>
                                    </div>
                                    <div className="col-3 pl-0 pr-0">
                                        <button type="button" onClick={() => history.push('/cart')} className="btn btn-apply">Sử dụng</button>
                                    </div>
                                </li>

                            ) : <CouponEmpty/> }

                        </ul>
                    </Tab>
                    <Tab eventKey="2" title="Hết hạn / Đã sử dụng">
                        <ul className="list_voucher">
                            {type === TYPE_USED && listCoupon ? listCoupon.map((coupon, key) =>
                                <li key={key}>
                                    <div className="col-9 pl-0 pr-0">
                                        <h5 className="name_voucher">{coupon.code}</h5>
                                        <span className="price_voucher">Giá trị: {coupon.unit == 0 ? formatCurrency(coupon.value) + "VND" : coupon.value + '%'}</span>
                                        <div className="grid">
                                            <p>HSD: {convertDateTime(coupon.end_time, 'DD/MM/YYYY')}</p>
                                            <p>Giá trị đơn hàng tối thiểu: {formatCurrency(coupon.min_order_value)} VND</p>
                                            <p>Số lượng : {coupon.number_of_use}</p>
                                        </div>
                                    </div>
                                    <div className="col-3 pl-0 pr-0">
                                        <button type="button" className="btn btn-cancel">{coupon.number_of_use == 0 ? 'Đã sử dụng' : coupon.status_text}</button>
                                    </div>
                                </li>

                            ) : <CouponEmpty/>}
                        </ul>

                    </Tab>
                </Tabs>
            </div>
            {dataPaginate.totalRecord <= customersPerPage ? '' :
                <Pagination
                    onChange={(value) => setCurrentPage(value)}
                    total={dataPaginate.totalRecord}
                    current={dataPaginate.currentPage}
                    showSizeChanger= {false}
                    defaultPageSize={customersPerPage}
                />
            }
        </div>
    )
}
export default Purcharse;
