// all action type for login - logout - register - forgotPassword
export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNIN_BY_SOCIAL_REQUEST = 'SIGNIN_BY_SOCIAL_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const GET_OTP = 'GET_OTP';
export const CHECK_TIME_OTP = 'CHECK_TIME_OTP';
export const NOTI = 'NOTI';
export const NOTI_SOCIAL = 'NOTI_SOCIAL';
export const AUTH_ME = 'AUTH_ME';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const LOGOUT = 'LOGOUT';

export const CHECKREGISTER_SUCCESS = 'CHECKREGISTER_SUCCESS';
export const USER_REGISTER = 'USER_REGISTER';

//category
export const GET_LIST_CATEGORY = 'GET_LIST_CATEGORY';

//brand
export const GET_BRAND = 'GET_BRAND';

//website

export const GET_WEBSITE = 'GET_WEBSITE';
export const GET_STORE = 'GET_STORE';
export const GET_FOOTER = 'GET_FOOTER';
export const GET_CHAT = 'GET_CHAT';
export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const OPEN_CHAT = 'OPEN_CHAT'

export const OPEN_FULL_MY_ORDER = 'OPEN_FULL_MY_ORDER'

export const GET_MODAL = 'GET_MODAL';
//brand
export const GET_GROUPS = 'GET_GROUPS';

// cart
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const GET_NUMBER_CART = 'GET_NUMBER_CART';
export const ADD_CART = 'ADD_CART' ;
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_CART = 'DELETE_CART';
export const GET_ALL_DATA_CART = 'GET_ALL_DATA_CART';

// favorite product
export const GET_FAVORITE_PRODUCTS = 'GET_FAVORITE_PRODUCTS';
export const GET_LIST_FAVORITE_PRODUCTS = 'GET_LIST_FAVORITE_PRODUCTS';
export const DELETE_FAVORITE = 'DELETE_FAVORITE';
export const ADD_FAVORITE = 'ADD_FAVORITE';
