import React from 'react';
import {isMobile} from "react-device-detect";

function BannerMainCategory({banner}) {
  if (banner) {
    return (
      <div className="banner_main">
        {!isMobile ?  <img style={{width:'100%'}} src={banner.image} alt="banner-main-category"/>
          :  <img style={{width:'100%'}} src={banner.image_mobile ? banner.image_mobile : banner.image} alt="banner-main-category"/>
        }

      </div>
    )
  }
  return ''
}

export default BannerMainCategory;
