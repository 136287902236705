import React from 'react';
import {Link} from 'react-router-dom';
import imgNotPurchase from '../../../assets/img/profile/_ 1.png';
import iconBuy from '../../../assets/img/cart/Group.png';
function PurchaseEmpty() {
    return (
        <div className="content_purchase">
            <div className="changePass-wapper pl-0">
                <div className="table_purchase">
                    <img src={imgNotPurchase} alt=""/>
                    <h2>Bạn chưa có đơn hàng nào</h2>
                    <Link to="/">
                        <img src={iconBuy} alt=""/>
                        <span>Quay lại mua sắm</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default PurchaseEmpty;
