import React from 'react';
import {Link} from "react-router-dom";
import arrow from "../../../assets/img/Arrow_right_long_light (1).svg";
import arrow2 from "../../../assets/img/Arrow_right_long_light (2).svg";
import {formatDateNew} from "../../../helpers/Function";

export default function New(props) {
    return (
        <ul>
          <Link to={`/tin-tuc/${props.new.slug}`}>
            <img src={props.new.image} alt={props.new.slug}/>
          </Link>
            <ul className="body-tag">
                <Link to={`/tin-tuc/${props.new.slug}`}>
                    <h5> {props.new.new_catalog_relation && props.new.new_catalog_relation.length ?  props.new.new_catalog_relation[0].name : ""}
                        <span>{formatDateNew(props.new.updated_at)}</span>
                    </h5>
                    <h3>{props.new.name}</h3>
                    <li>{props.new.description}</li>
                    <p>Xem thêm <img className="arrow-more-3" alt="" src={arrow}></img><img className="arrow-more-4" alt="" src={arrow2}></img>
                    </p>
                </Link>
            </ul>
        </ul>
    )
}