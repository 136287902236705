import React from 'react';
import { Link } from 'react-router-dom';
function TagInfomation(props) {
    return (
        <ul className="tag_infomation">
            <li className="d-flex">
                <span>Danh mục</span>
                <Link to={`/danh-muc/${props.cateSlug}`}>{props.category}</Link>
            </li>
            {props.capacity ?
            <li className="d-flex">
                <span>Quy cách</span>
                <p>{props.capacity}</p>
            </li> : '' }
          {props.madeIn ?
            <li className="d-flex">
                <span>Sản xuất</span>
                <p>{props.madeIn}</p>
            </li> : '' }
          {props.brand ?
            <li className="d-flex">
                <span>Thương hiệu</span>
                <Link to={`/thuong-hieu/${props.slugBrand}`}>{props.brand}</Link>
            </li> : '' }
        </ul>
    )
}

export default TagInfomation
