import axios from 'axios';
import ENDPOINT from './EndPoint';
import { actions } from '../actions/Notification';

export const signIn = (user) => {
    return axios.post(`${ENDPOINT.LOGIN}`, user)
      .then(function (response) {
          return response.data
      })
      .catch(error =>
        actions.addNotification(error.response.status)
    )
} 

export const socialLoginRequest = (data) => {
  return axios.post(`${ENDPOINT.SOCAIL_LOGIN}`, data)
    .then(function (response) {
      return response
    })
    .catch(error =>
      console.log(error)
  )
} 

export const signUp = (user) => {
    return axios.post(`${ENDPOINT.REGISTER}`, user)
      .then(function (response) {
        return response.data
      })
      .catch(error =>
        actions.addNotification(error.response.data)
    )
} 



