import React from 'react';
import ItemMegaMenu from "./ItemMegaMenu";

function MegaMenu({childs}) {
    return (
        <div className="mega-menu-child">
            <div className="grid grid-5">
                {childs.map(item => {
                  return item.status ? (<ItemMegaMenu key={item.id} data={item}/>) : ''
                })}
            </div>
        </div>
    )
}

export default MegaMenu;
