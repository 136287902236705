import React, {useEffect, useState} from 'react';
import Filter from '../components/common/filter/filter';
import Banner from '../components/common/banner-product/BannerProduct';
import BreadCrumb from '../components/common/breadcrumb/Breadcrumb';
import Product from '../components/common/product/Product';
import BannerFooter from "../components/common/banner-footer/BannerFooter";
import Footer from "../components/common/footer/Footer";
import {DealApiService, ProductApiService} from "../ultils/EndPoint";
import {useHistory, useParams} from "react-router-dom";
import {deleteValueEmptyFromObject, getJsonQueryParams, getQuerystring, objectToQueryParams} from "../helpers/Function";
import {DEFAULT_LIMIT_SEARCH} from "../helpers/constant";
import $ from "jquery";
import NotFound from "../components/common/not_found/NotFound";
import moment from 'moment';

function ProductList(props) {
    const {slug} = useParams();
    let allQuery = getQuerystring("")
    const [brands, setBrands] = useState([]);
    const [group, setGroup] = useState({});
    const [dataPaginate, setDataPaginate] = useState({});
    const [endTime, setEndTime] = useState("")
    const [notFound, setNotFound] = useState(false)
     function makeTimer(timeEnd) {
        var endTime = timeEnd;
            endTime = moment(endTime, "YYYY-MM-DD HH:mm:ss").toDate();
             var now = new Date().getTime();
             var distance = endTime - now;

             var days = Math.floor(distance / (1000 * 60 * 60 * 24));
             var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
             var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
             var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (days < "10") { days = "0" + days; }
            if (hours < "10") { hours = "0" + hours; }
            if (minutes < "10") { minutes = "0" + minutes; }
            if (seconds < "10") { seconds = "0" + seconds; }

            if (!Number.isNaN(days) && days) {
                $("#days").html(days + ' ngày&nbsp');
            }
            if (!Number.isNaN(hours) && !Number.isNaN(minutes) && !Number.isNaN(seconds)) {
                $("#hours").html(hours + ':');
                $("#minutes").html(minutes + ':');
                $("#seconds").html(seconds);
            }
        }
     if (props.deal && endTime) {
        setInterval(function() { makeTimer(endTime); }, 1000);
     }
     useEffect(async () => {
        ProductApiService.getBrands().then(res => {
            if (res.status) {
                setBrands(res.data)
            }
        })

        let dataParam = getJsonQueryParams()
        dataParam.per_page = DEFAULT_LIMIT_SEARCH
        deleteValueEmptyFromObject(dataParam)
        let query = objectToQueryParams(dataParam)

        let e = props.deal ?  await DealApiService.getBySlug(slug, query) : await ProductApiService.getDetailGroupProduct(slug, query)

        if (e.status) {
            if (e.data.banner) {
                e.data.cover = e.data.banner
            }
            setGroup(e.data)
            let products = e.data.products
            if (e.data.end_time) {
              setEndTime(e.data.end_time)
            }
            setDataPaginate({
                "totalRecord": products && products.total_record,
                "totalPage": products && products.total_page,
                "currentPage": products && products.page,
                "perPage": products && products.limit,
            })
        } else {
            setNotFound(true)
            // return history.push('/not-found')
        }


    }, [slug, allQuery])
    if (notFound) {
      return <NotFound />
    }
    return (
        <div>
            <div className="container">
                <div className="row">
                    <BreadCrumb from={'Trang chủ'} to={props.deal ? 'Deal hot trong ngày' : 'Nhóm sản phẩm'}/>
                    <br/>
                    <Banner check={true} cover={group.cover}/>

                    <div className="main product_group d-flex">
                        <div className="main_filter">
                            <Filter brands={brands}/>
                        </div>
                        <div className="main_product">
                            {
                                group.products ? <Product deal = {!!props.deal} products={group.products.records} dataPaginate={dataPaginate} page="group" name={group.name}/> : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
            <BannerFooter/>
        </div>
    )
}

export default ProductList;
