import React from 'react';
import {Link} from 'react-router-dom';
import imgNotPurchase from '../../../assets/img/sleeping-pills 1.png';
function PrescriptionEmpty() {
    return (
        <div className="content_purchase">
            <div className="changePass-wapper pl-0">
                <h1>Đơn thuốc tư vấn online</h1>
                <div className="table_purchase prescription">
                    <img src={imgNotPurchase} alt=""/>
                    <h2>Bạn chưa có đơn thuốc nào được tải lên</h2>
                    <Link to="#">
                        <span>Đăng đơn thuốc</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default PrescriptionEmpty;
