import React from "react";
import Modal from 'react-bootstrap/Modal';
import imgPopSuccess from '../../assets/img/payment/thumbs-up 1.png';

function PopupReviewSuccess(props) {
    return (
        <div>
            <Modal show={props.show} onHide={props.close} className="modal-login modal-success" >
                <Modal.Header closeButton  className="grid">
                    <img src={imgPopSuccess} alt=""/>
                    <Modal.Title>Đánh giá đơn hàng thành công</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mt-3">
                    <p>Đơn hàng của bạn đã được đánh giá thành công.</p>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PopupReviewSuccess;
