import React, {useEffect, useState} from 'react'
import imgNotPurchase from "../../../assets/img/sleeping-pills 1.png";
import {Link, useHistory} from "react-router-dom";
import $  from 'jquery';
import {CityApiService, DistrictApiService, PrescriptionApiService, WardApiService} from "../../../ultils/EndPoint";
import {onNumberOnlyChange, validateEmail, validateLimitCharacters, validatePhone} from "../../../helpers/Function";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import trim from "validator/es/lib/trim";

function Prescription() {
    const auth = useSelector(state => state.AuthMeRoot.auth)
    const history = useHistory()
    const [imageURL, setImageURL] = useState('');
    const [address, setAddress] = useState('');
    const [note, setNote] = useState('')
    const [cities, setListCities] = useState([])
    const [citySelected, setCitySelected] = useState(0)
    const [districts, setListDistrict] = useState([])
    const [districtSelected, setDistrictSelected] = useState(0)
    const [wards, setListWard] = useState([])
    const [wardSelected, setWardSelected] = useState(0)

    useEffect(() => {
        CityApiService.getListCity().then(r => {
            if (r.status){
                setListCities(r.data)
            }
        })
    }, [])


    useEffect(() => {
        setDistrictSelected(0)
        setWardSelected(0)

        if (citySelected > 0) {
            DistrictApiService.getListDistrictByCityId(citySelected).then(r => {
                if (r.status){
                    setListDistrict(r.data)
                }
            })
        }else{
            setListDistrict([])
            setListWard([])
        }
    }, [citySelected])

    useEffect(() => {
        setWardSelected(0)

        if (districtSelected > 0) {
            WardApiService.getListWardByDistrictId(districtSelected).then(r => {
                if (r.status){
                    setListWard(r.data)
                }
            })
        }else{
            setListWard([])
        }
    }, [districtSelected])

    const onFileChange = (e) => {
        setImageURL(e.target.files[0])

        var reader = new FileReader();

        reader.onload = function(e) {
            $('#imagePreview').attr('src', e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
            $('.image_icon').addClass('image_icon1');
            $('.icon_remove_image').addClass('icon_remove_image1');
        }
        if (e.target.files[0].size > 5e6) {
            alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            return false;
        }else {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const submitPrescription = () => {

        let name = $('#name_user_prescription').val()
        let phone = $('#phone').val()
        let email = $('#email').val()

        if (!imageURL){
            $('.error_image').html("Không được để trống")
        }else{
            $('.error_image').html("")
        }

        if (!name){
            $('.error_name').html("Không được để trống")
        }else{
            $('.error_name').html("")
        }

        if (!phone){
            $('.error_phone').html("Không được để trống")
        }
        if (phone && !validatePhone(phone)){
            $('.error_phone').html("Số điện thoại không hợp lệ")
        }
        if (phone && validatePhone(phone)){
            $('.error_phone').html("")
        }

        if (!email){
            $('.error_email').html("Không được để trống")
        }
        if (email && !validateEmail(email)){
            $('.error_email').html("Email không hợp lệ")
        }
        if (email && validateEmail(email)){
            $('.error_email').html("")
        }

        if (!address){
            $('.error_address').html("Không được để trống")
        }else{
            $('.error_address').html("")
        }

        if (!citySelected || citySelected == "0"){
            $('.error_city').html("Không được để trống")
        }else{
            $('.error_city').html("")
        }


        if (!districtSelected){
            $('.error_district').html("Không được để trống")
        }else{
            $('.error_district').html("")
        }

        if (!wardSelected){
            $('.error_ward').html("Không được để trống")
        }else{
            $('.error_ward').html("")
        }

        if (imageURL && name && phone && validatePhone(phone) && email && validateEmail(email) && address && citySelected && districtSelected && wardSelected){
            let city = cities.filter(city => {
                if (city.viet_nam_zone_id == citySelected) {
                    return city
                }
            })
            const formData = new FormData();
            formData.append("full_name", name)
            formData.append("phone", phone)
            formData.append("address", address)
            formData.append("email", email)
            formData.append("image", imageURL)
            if (city.length){
                formData.append("city_id", city[0].id)
            }
            formData.append("district_id", districtSelected)
            formData.append("ward_id", wardSelected)
            formData.append("note", note)

            PrescriptionApiService.store(formData).then(r => {
                if (r.status){
                    toast.success('Tạo đơn thuốc thành công')
                    setTimeout(() => {
                        history.push('/profile/prescription')
                    }, 1000);
                }
            })

        }

    }

    return (
        <div className="content_purchase">
            <div className="changePass-wapper">
                <h1>Đơn thuốc tư vấn online</h1>
                <div className="prescription_form">
                    <form action="">
                        <div className="form-group mb-39">
                            <label htmlFor="">Tải đơn thuốc lên</label>
                            <button htmlFor="imageUpload" type="button" className="btn btn-add">
                                <i className="icon_upload"></i>
                                <span>Chọn file</span>
                                <input className="input_uploadImage" onChange={onFileChange} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                            </button>

                            {imageURL ?
                                <div className="w-100">
                                    <div id="close_btn_wapper">
                                        <img className="image_icon" style={{'marginRight':'30px','width':'150px', 'height':'150px'}} alt="" id="imagePreview" src={imageURL}></img><i style={{'cursor':'pointer', 'fontSize': '0px'}} id="btn_close"  className="mdi mdi-delete icon_remove_image"></i>
                                    </div>
                                </div>
                                : ''}
                            <p>&nbsp;</p><span className="error_image" style={{fontSize: "12px", color:"red"}}></span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Họ và tên <span className="red">*</span></label>
                            <input defaultValue={auth.Name} onKeyPress={(e) => {validateLimitCharacters(e, '#name_user_prescription', 255)}} id="name_user_prescription" type="text" className="form-control"/>
                            <span className="error_name" style={{fontSize: "12px", color:"red"}}></span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Số điện thoại<span className="red">*</span></label>
                            <input defaultValue={auth.Phone} onKeyPress={onNumberOnlyChange} type="text" className="form-control" id="phone"/>
                            <span className="error_phone" style={{fontSize: "12px", color:"red"}}></span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Email<span className="red">*</span></label>
                            <input defaultValue={auth.Email} type="text" className="form-control" id="email"/>
                            <span className="error_email" style={{fontSize: "12px", color:"red"}}></span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Tỉnh / thành phố<span className="red">*</span></label>
                            <select value={citySelected} onChange={(e) => {setCitySelected(e.target.value)}} className="form-control">
                                <option value="0">Lựa chọn tỉnh/thành phố</option>
                                {cities.length && cities.map((city, index) =>
                                    <option key={index} value={city.viet_nam_zone_id}>{city.name}</option>
                                )}
                            </select>
                            <span className="error_city" style={{fontSize: "12px", color:"red"}}></span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Quận / Huyện <span className="red">*</span></label>
                            <select value={districtSelected} onChange={(e) => {setDistrictSelected(e.target.value)}} className="form-control">
                                <option value="0">Lựa chọn quận/huyện</option>
                                {districts.length && districts.map((district, index) =>
                                    <option key={index} value={district.id}>{district.name}</option>
                                )}
                            </select>
                            <span className="error_district" style={{fontSize: "12px", color:"red"}}></span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Phường / xã <span className="red">*</span></label>
                            <select value={wardSelected} onChange={(e) => {setWardSelected(e.target.value)}} className="form-control">
                                <option value="">Lựa chọn phường/xã</option>
                                {wards.length && wards.map((ward, index) =>
                                    <option key={index} value={ward.id}>{ward.name}</option>
                                )}
                            </select>
                            <span className="error_ward" style={{fontSize: "12px", color:"red"}}></span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Địa chỉ<span className="red">*</span></label>
                            <input onKeyPress={(e) => {validateLimitCharacters(e, '#address_prescription', 255)}} id="address_prescription" onChange={(e) => {setAddress(trim(e.target.value))}} type="text" className="form-control"/>
                            <span className="error_address" style={{fontSize: "12px", color:"red"}}></span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Ghi chú</label>
                            <textarea onChange={(e) => {setNote(e.target.value)}} name="" id="" cols="30" rows="3" className="form-control"></textarea>
                        </div>
                        <div className="button_storeUserAdress d-flex">
                            <Link style={{'textDecoration':'none'}} to="/profile/prescription"><div className="button_storeUserAdress_goHome">Quay lại</div></Link>
                            <a onClick={submitPrescription}  style={{'borderStyle':'none', 'backgroundColor' : '#096dd9', 'width' : "140px"}}  className="button_storeUserAdress_update">Đăng đơn thuốc</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Prescription
