import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import icon_login_gg from "../../assets/img/icons8-google 1.png";
import icon_login_fb from "../../assets/img/icons8-facebook 1.png";
import icon_register from "../../assets/img/Expand_right_double_light.png";
import OtpResetpassword from './OtpResetpassword';
import isEmpty from "validator/lib/isEmpty";
import axios from 'axios';
import ENDPOINT from '../../ultils/EndPoint';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { actions } from '../../actions/User';
import * as LoginSocialNetWerk from '../../components/common/social-login/Social_login';
import Login from './login';
import { websiteActions } from '../../actions';

function ForgotPwd (props) {
    // regex for test
    const dispatch = useDispatch();
    const isPhoneNumber = new RegExp("^[0-9]*$")
    const [show, setShow] = useState(false);
    const [showResetPwd, setshowResetPwd] = useState(false);
    const [showVerificationOTP, setshowVerificationOTP] = useState(false);
    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setmsg] = useState();
    const handleClose = () => setShow(props.close);
    const handleCloseMiss = () => setshowResetPwd(false);
    const [phonePassthrough, setPhonePassthrough] = useState('')
    const [disable, setDisable]= useState(false);
    const [id, setId] = useState('')

    const [phoneReset, setPhoneReset] = useState('');


    const handlePressPhoneNumber = () => {
        validationMsg.phoneReset = '';
        setmsg('')
        $('.form-phone').removeClass('red_blur')
    }

    const hanldForgotPass = (e) => {
        setPhoneReset(e.target.value)
    }

    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(isEmpty(phoneReset)) {
            msg.phoneReset = 'Vui lòng nhập số điện thoại'
            $('.form-phone').addClass('red_blur')
        }else if (!isPhoneNumber.test(phoneReset) ||  phoneReset.length !== 10){
            $('.form-phone').addClass('red_blur')
            msg.phoneReset = 'Số điện thoại không hợp lệ. Số điện thoại là 10 số, bắt đầu bằng số 0'
        }else if(phoneReset.charAt(0) !== '0') {
            $('.form-phone').addClass('red_blur')
            msg.phoneReset = 'Số điện thoại không hợp lệ. Số điện thoại là 10 số, bắt đầu bằng số 0'
        }

        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    const handleGetOTP = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 4000);
        
        let query = '';
        const data = {
            phone : phoneReset,
            type: 2,
        }
        Object.keys(data).map((key,index) => 
        query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )
        axios.post(`${ENDPOINT.REGISTERGETOTP}`+ query, data)
        .then(function (response) {
            if(response.data.data) {
                setPhonePassthrough(response.data.data.Phone)
                dispatch(actions.getOTPAgains(response.data.data.id))
                $('.form-phone').removeClass('red_blur')
                handelSetshowResetPwd()
                setshowVerificationOTP(true)
                setmsg('')
            }
        })
        .catch(error =>
            {if(error) {
                setmsg(`Số điện thoại chưa từng đăng kí tại ${process.env.REACT_APP_NAME}`)
                $('.form-phone').addClass('red_blur')
            }else {
                $('.form-phone').removeClass('red_blur')
            }
        }
        )
    }

    useEffect(() => {
        $('input[name="inputForNumber"]').keypress
        (
        function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else 
                {
                    if (event.keyCode < 48 || event.keyCode > 57 ) 
                    {
                        event.preventDefault();	
                    }	
                }
            }
        );
    })

    const handelSetshowResetPwd = () => {
        setshowResetPwd(true)
        setPhoneReset('')
        handleClose()
        setmsg('')
        validationMsg.phoneReset = ''
    }

    const closeModal = () => {
        handleClose()
        setmsg('')
        validationMsg.phoneReset = ''
    }

    // set show for modal
    const [showLogin, setShowLogin] = useState(false);

    return (
        <div>
            <OtpResetpassword phone={phonePassthrough} show={showVerificationOTP} close={() => setshowVerificationOTP(false)} onHide={() => setshowVerificationOTP(false)}/>
            {
                    showLogin === true 
                    ? <Login show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
                    : 
                    <Modal show={props.show} onHide={closeModal} className="modal-login" >
                        <Modal.Header closeButton  className="grid">
                            <Modal.Title>Quên mật khẩu</Modal.Title>
                            <p>Nhập số điện thoại đã đăng kí tài khoản.</p>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="form_pwd">
                                <div className="form-group">
                                    <input type="text" onChange={hanldForgotPass} name="inputForNumber" onKeyDown={handlePressPhoneNumber} className="form-control form-phone" placeholder="Nhập số điện thoại của bạn"/>
                                    <p style={{'fontSize': '12px', 'margin':'0px'}} className="text-danger">{validationMsg.phoneReset}</p> 
                                    <p style={{'fontSize': '12px'}} className="text-danger">{msg}</p> 
                                </div>
                                <div className="form-group">
                                    <Button onClick={handleGetOTP} disabled={disable} className="button_acceprt">Yêu cầu gửi mã OTP</Button>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group w-100">
                                <label htmlFor="">Bạn đã có tài khoản?</label>
                                <div className="d-flex w-100">
                                    <Button className="btn_login_other">
                                        <img src={icon_login_gg} alt=""/>
                                    </Button>
                                    <LoginSocialNetWerk.GoogleLoginAuth/>
                                    <Button className="btn_login_other">
                                        <img src={icon_login_fb} alt=""/>
                                    </Button>
                                    <LoginSocialNetWerk.FaceBookLoginAuth/>
                                    <Button onClick={() => {
                                        handleClose()
                                        // setShowLogin(true)
                                        dispatch(websiteActions.setModal(true))
                                    }} className="btn_register btn_login">
                                        Đăng nhập ngay
                                        <img src={icon_register} alt=""/>
                                    </Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
            }
        </div>
    )
}

export default ForgotPwd;
