import React, {useEffect, useState} from 'react';
import './footer.scss';
import logo from '../../../assets/img/logo group.svg';
import icon_phone from '../../../assets/img/Phone.svg';
import icon_mess from '../../../assets/img/Message.svg';
import icon_pin from '../../../assets/img/Pin_alt.svg';
import {Link, useHistory} from 'react-router-dom'
import {StoreApiService} from "../../../ultils/EndPoint";
import {useSelector} from "react-redux";
import Login from "../../../pages/auth/login";
import $ from 'jquery';
import faceBook from '../../../assets/img/Frame 2.svg';

function Footer() {

    const history = useHistory();
    const [stories, setStories] = useState([]);
    const websiteInfo = useSelector(state => state.getWebsite.website);
    const [showLogin, setShowLogin] = useState(false);
    const userAuthToken = localStorage.getItem('User-token');
    const footer = useSelector(state => state.getWebsite.footer)
    useEffect(() => {
        StoreApiService.getStories().then(res => {
            setStories(res.data)
        })
    }, [])
    function CheckAuth(link) {
        if (userAuthToken) {
            return history.push(link)
        }
         setShowLogin(true)
    }
    $(function () {
        $('.content_footer').hide();
        $('.title_menu_child').on('click',function () {
            $(this).toggleClass('active')
            $(this).next().toggleClass('show');
        })
    })
    if (!footer) {
        return null;
    }
    return (
        <div>
            <div className="footer pc">
                <div className="container   pl-0 pr-0">
                    <div className="d-flex justify-content-between">
                        <ul style={{'marginLeft' : '20px'}}>
                            <p>
                                <img alt="" src={websiteInfo.logo} className="footer-logo"></img>
                            </p>
                            <Link to="/static-page/gioi-thieu-citimed"><li>Giới thiệu {process.env.REACT_APP_NAME}</li></Link>
                            <Link to="/static-page/cong-dong-citicare"><li>Cộng đồng CitiCARE</li></Link>
                            <Link to="/static-page/dich-vu-cua-chung-toi"><li>Dịch vụ của chúng tôi</li></Link>
                            <Link to="/static-page/co-hoi-viec-lam"><li>Cơ hội việc làm</li></Link>
                            <Link to="/static-page/cau-hoi-thuong-gap"><li>Câu hỏi thường gặp (FAQ)</li></Link>
                        </ul>
                        <ul>
                            <h2 style={{'marginBottom': '20px' , 'paddingTop' : '20px'}}>HƯỚNG DẪN MUA HÀNG</h2>
                            <Link to="/static-page/huong-dan-mua-hang"><li>Hướng dẫn mua hàng</li></Link>
                            <Link to="/static-page/chinh-sach-doi-tra"><li>Chính sách đổi trả</li></Link>
                            <Link to="/static-page/phuong-thuc-thanh-toan"><li>Phương thức thanh toán</li></Link>
                            <Link to="/static-page/chinh-sach-bao-mat"><li>Chính sách bảo mật</li></Link>
                             <Link to="/static-page/dieu-khoan-dich-vu"><li>Điều khoản dịch vụ</li></Link>
                            <Link to="/static-page/lien-lac-duong-day-nong"><li>Liên lạc đường dây nóng</li></Link>
                            <Link to="/static-page/chinh-sach-tich-diem"><li>Chính sách tích điểm</li></Link>
                        </ul>
                        <ul >
                            <h2 style={{'marginBottom': '20px' , 'paddingTop' : '20px'}}>KIỂM TRA ĐƠN HÀNG</h2>
                            <li onClick={() => CheckAuth('/profile/detail')}>Tài khoản của bạn</li>
                            <li onClick={() => CheckAuth('/profile/purchase-order')}>Kiểm tra đơn hàng</li>
                            <li onClick={() => CheckAuth('/profile/prescription/create')}>Đăng đơn thuốc</li>
                            <li onClick={() => CheckAuth('/profile/prescription/create')}>Mua lại thuốc theo đơn (Refills)</li>
                        </ul>
                        <ul className="icon-ul">
                            <h2 style={{'marginBottom': '20px' , 'paddingTop' : '20px'}}>LIÊN HỆ</h2>
                            <li>
                                <img className="icon-list" alt="" src={icon_phone}></img><span style={{'marginLeft' : '10px'}}>Hotline: {websiteInfo.phone}</span>
                            </li>
                            <li>
                                <img className="icon-list" alt="" src={icon_mess}></img><span style={{'marginLeft' : '10px'}}>{websiteInfo.email}</span>
                            </li>
                            <li>
                                <img className="icon-list" alt="" src={icon_pin}></img><span style={{'marginLeft' : '10px'}}>{websiteInfo.address}</span>
                            </li>

                            <h2 style={{'marginBottom': '10px' , 'paddingTop' : '20px'}}>LIKE FACEBOOK {process.env.REACT_APP_NAME}</h2>
                            <li>
                                <Link to={{pathname: `${websiteInfo.facebook}`}} target="_blank"><img className="icon-list" alt="" src={faceBook}></img><span style={{'marginLeft' : '10px'}}></span></Link>
                            </li>
                        </ul>
                        <ul>
                            <h2 style={{'marginBottom': '20px' , 'paddingTop' : '20px'}}>ĐỊA CHỈ CỬA HÀNG</h2>
                            <ul className="adress-list">
                                {
                                    stories && stories.map((story, key) => {
                                        return (
                                            <ul key={key}>
                                                <h2>{story.name}</h2>
                                                <li>{story.address}</li>
                                                <li>{story.phone}</li>
                                            </ul>
                                        )
                                    })
                                }

                            </ul>
                        </ul>
                    </div>
                </div>
                <Login  show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
            </div>
            <div className="footer mobile">
                <div className="container   pl-0 pr-0">
                    <div className="d-flex justify-content-between">
                        <ul style={{'marginLeft' : '20px'}}>
                            <p>
                                <img alt="" src={logo} className="footer-logo"></img>
                            </p>
                            <Link to="/static-page/gioi-thieu-citimed"><li>Giới thiệu {process.env.REACT_APP_NAME}</li></Link>
                            <Link to="/static-page/cong-dong-citicare"><li>Cộng đồng CitiCARE</li></Link>
                            <Link to="/static-page/dich-vu-cua-chung-toi"><li>Dịch vụ của chúng tôi</li></Link>
                            <Link to="/static-page/co-hoi-viec-lam"><li>Cơ hội việc làm</li></Link>
                            <Link to="/static-page/cau-hoi-thuong-gap"><li>Câu hỏi thường gặp (FAQ)</li></Link>
                        </ul>
                        <ul>
                            <h2 style={{'marginBottom': '20px' , 'paddingTop' : '20px'}} className="title_menu_child">HƯỚNG DẪN MUA HÀNG</h2>
                            <div className="content_footer">
                                <Link to="/static-page/huong-dan-mua-hang"><li>Hướng dẫn mua hàng</li></Link>
                                <Link to="/static-page/chinh-sach-doi-tra"><li>Chính sách đổi trả</li></Link>
                                <Link to="/static-page/phuong-thuc-thanh-toan"><li>Phương thức thanh toán</li></Link>
                                <Link to="/static-page/chinh-sach-bao-mat"><li>Chính sách bảo mật</li></Link>
                                <Link to="/static-page/dieu-khoan-dich-vu"><li>Điều khoản dịch vụ</li></Link>
                                <Link to="/static-page/lien-lac-duong-day-nong"><li>Liên lạc đường dây nóng</li></Link>
                                <Link to="/static-page/chinh-sach-tich-diem"><li>Chính sách tích điểm</li></Link>
                            </div>
                        </ul>
                        <ul >
                            <h2 style={{'marginBottom': '20px' , 'paddingTop' : '20px'}} className="title_menu_child">KIỂM TRA ĐƠN HÀNG</h2>
                            <div className="content_footer">
                                <li onClick={() => CheckAuth('/profile/detail')}>Tài khoản của bạn</li>
                                <li onClick={() => CheckAuth('/profile/purchase-order')}>Kiểm tra đơn hàng</li>
                                <li onClick={() => CheckAuth('/profile/prescription/create')}>Đăng đơn thuốc</li>
                                <li onClick={() => CheckAuth('/profile/prescription/create')}>Mua lại thuốc theo đơn (Refills)</li>
                            </div>
                        </ul>
                        <ul className="icon-ul">
                            <h2 style={{'marginBottom': '20px' , 'paddingTop' : '20px'}} className="title_menu_child">LIÊN HỆ</h2>
                            <div className="content_footer">
                                <li>
                                    <img className="icon-list" alt="" src={icon_phone}></img><span style={{'marginLeft' : '10px'}}>Hotline: {websiteInfo.phone}</span>
                                </li>
                                <li>
                                    <img className="icon-list" alt="" src={icon_mess}></img><span style={{'marginLeft' : '10px'}}>{websiteInfo.email}</span>
                                </li>
                                <li>
                                    <img className="icon-list" alt="" src={icon_pin}></img><span style={{'marginLeft' : '10px'}}>{websiteInfo.address}</span>
                                </li>
                            </div>
                        </ul>
                        <ul>
                            <h2 style={{'marginBottom': '20px' , 'paddingTop' : '20px'}} className="title_menu_child">ĐỊA CHỈ CỬA HÀNG</h2>
                            <div className="content_footer">
                                <ul className="adress-list">
                                {
                                    stories && stories.map((story, key) => {
                                        return (
                                            <ul key={key}>
                                                <h2>{story.name}</h2>
                                                <li>{story.address}</li>
                                                <li>{story.phone}</li>
                                            </ul>
                                        )
                                    })
                                }

                            </ul>
                            </div>
                           
                        </ul>

                        <ul className="icon-ul">
                            <h2 style={{'marginBottom': '10px' }}>LIKE FACEBOOK {process.env.REACT_APP_NAME}</h2>
                            <li style={{listStyle:'none'}}>
                                <Link  to={{pathname: `${websiteInfo.facebook}`}} target="_blank"><img className="icon-list" alt="" src={faceBook}></img><span style={{'marginLeft' : '10px'}}></span></Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <Login  show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
            </div>
        </div>
    )
}

export default Footer
