import React from 'react';
import {Link} from "react-router-dom";

function Child({data}) {
  if (data && data.length) {
    return (
      <ul className="list_content_side_menu">
        {data.map(item => {
          return ( <li key={item.id}>
            <Link to={"/danh-muc/" + item.slug}>{item.name}</Link>
          </li>)
        })}
      </ul>
    );
  }
  return null;
}

export default Child;