import { 
    LOGOUT, 
    SIGNIN_REQUEST,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
} from "../constans/ActionType";

export const userReducer = (state = { inPromise: false }, action) => {
    const { type, payload } = action;
    switch (type) {
      case SIGNIN_REQUEST:
      case SIGNUP_REQUEST:
      return { ...state, inPromise: true };

      case SIGNUP_SUCCESS:
        const msg  = payload;
        return { ...state, inPromise: 1, msg };
  
      case LOGOUT:
        return { inPromise: false, ...payload };
  
      default:
        return state;
    }
};
  