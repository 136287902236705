import React, {useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import icon_login_gg from "../../assets/img/icons8-google 1.png";
import icon_login_fb from "../../assets/img/icons8-facebook 1.png";
import icon_register from "../../assets/img/Expand_right_double_light.png";
import Icon_pwd from '../../assets/img/Eye_light.png';
import Icon_show from '../../assets/img/Eye_light_active.png';
import isEmpty from "validator/lib/isEmpty";
import isEmail from 'validator/lib/isEmail';
import { actions } from '../../actions/User';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import {toast } from 'react-toastify';
import * as LoginSocialNetWerk from '../../components/common/social-login/Social_login';
import Login from './login';
import { websiteActions } from '../../actions';

function Register (props) {
    var check = /^[a-zA-Z0-9[:space:]ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+$/
    const [stateBtn, setStateBtn] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(props.close);
    const dispatch = useDispatch();
    // set const validate
    const [validationMsg, setValidationMsg] = useState({});
    var [isCheckPerson, setCheckPerson] = useState('') 
    const [isCheckedEmail, setCheckedEmail] = useState('')
    // set const input value
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirm] = useState('');
    const [person, setPerson] = useState('');
    const [checkRule, setCheckRule] = useState(false)

    //removeExtraSpace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // func onChange input value
    const handleName = (e) => {
        setName(removeExtraSpace(e.target.value))
    }

    const handleEmail = (e) => {
        setEmail(removeExtraSpace(e.target.value))
    }

    const handlePassword = (e) => {
        setPassword(removeExtraSpace(e.target.value))
    }

    const handlePasswordConfirm = (e) => {
        setPasswordConfirm(e.target.value)
    }

    const handlePerson = (e) => {
        setPerson(removeExtraSpace(e.target.value))
    }

    const handleCheckbox = (e) => {
        if(e.target.checked) {
            setCheckRule(true)
        }else {
            setCheckRule(false)
        }
    } 
 
    // handle password show/hide
    function initSettings() {
        $("#password-addon").off('click').on('click', function(){
            $(this).toggleClass('active');
            if($(this).prev('input').length > 0) {
                $(this).prev('input').attr('type') === "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
            }
        })
        $("#password-addoned").off('click').on('click', function(){
            $(this).toggleClass('active');
            if($(this).prev('input').length > 0) {
                $(this).prev('input').attr('type') === "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
            }
        })
    }
    useEffect(() => {
        return () => {
            initSettings()
        }
    })

    // validate before submit
    const validateAll = () => {
        const msg = {};
        
        if(isEmpty(name)) {
            msg.name = "Vui lòng nhập họ tên"
            $('.form-name').addClass('red_blur')
        }else if(name.length > 256) {
            msg.name = "Trường họ và tên chỉ chứa chữ cái và tối đa 256 kí tự.Vui lòng nhập lại."
            $('.form-name').addClass('red_blur')
        }

        if(isEmpty(email)) {
            msg.email = "Vui lòng nhập email"
            $('.form-email').addClass('red_blur')
        }else if(email.length > 256) {
            msg.email = "Trường Email chỉ tối đa 256 kí tự.Vui lòng nhập lại."
            $('.form-email').addClass('red_blur')
        }else if (!isEmail(email)) {
            msg.email = "Vui lòng nhập đúng định dạng email"
            $('.form-email').addClass('red_blur')
        }

        if(isEmpty(password)) {
            msg.password = "Vui lòng nhập mật khẩu"
            $('.form-password').addClass('red_blur')
        }else if(password.length < 6) {
            msg.password = "Mật khẩu phải có tối thiểu 6 kí tự"
            $('.form-password').addClass('red_blur')
        }

        if(isEmpty(password_confirmation)) {
            $('.form-password-confirm').addClass('red_blur')
            msg.passwordConfirm = "Vui lòng nhập lại mật khẩu"
        }else if( password !== password_confirmation ) {
            $('.form-password-confirm').addClass('red_blur')
            msg.passwordConfirm = "Hai mật khẩu không giống nhau "
        }

        if(checkRule === false) {
            msg.checkRule = `Vui lòng đồng ý với các điều khoản của ${process.env.REACT_APP_NAME}`
        }
        
        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }
    
    const userRegister = {
        phone: props.phone,
        password: password,
        password_confirmation: password_confirmation,
        aff_code: person,
        email: email,
        name: name,
    }

    // useSlecter
    const stateNoti = useSelector(state => state.userReducer)
    const stateData = useSelector(state => state.notificationReducer)

    // when ok --> submit
    const onSubmitRegister = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setStateBtn(true)
        setTimeout(() => {
            setStateBtn(false)
        }, 5000);
        setTimeout(() => {
            dispatch(actions.signUpRequest(userRegister))
            dispatch(websiteActions.setUserForLogin({
                phone : props.phone,
                password : password
            }));
        }, 1000);
    }

    useEffect(() => {
        if(stateNoti.inPromise === 1 && stateNoti.msg === undefined){
            $('.remove_email').remove()
            $('.form-email').removeClass('red_blur')
            
            $('.remove_person').remove()
            $('.form-person').removeClass('red_blur')
            // dispatch(actions.signInRequest(user));
            dispatch(websiteActions.setRegistrerSuccess(true));
            toast.success('Đăng kí tài khoản thành công', {
                toastId: 1
            })
            handleClose()   
        }
    }, [
        stateNoti.inPromise,
        stateNoti.msg, 
        dispatch,
        // handleClose
    ])

    useEffect(() => {
        if(stateNoti.inPromise === false) {

        }else{
            if(stateData.payload === undefined){
                
            }else{
                if(stateData.payload.status === false){
    
                    if(stateData.payload.errors !== undefined) {
                        if(stateData.payload.errors.Email === "Email đã được sử dụng ! "){
                            setCheckedEmail('Email này đã được đăng ký')
                            $('.form-email').addClass('red_blur')
                        }else {
                            setCheckedEmail('')
                            $('.form-email').removeClass('red_blur')
                        }
                    }else {
                        setCheckedEmail('')
                        $('.form-email').removeClass('red_blur')
                    }
    
                    if(stateData.payload.error !== undefined) {
                        if(stateData.payload.error === "Không tìm thấy mã người giới thiệu ! "){
                            setCheckPerson('Không tìm thấy mã người giới thiệu !') 
                            $('.form-person').addClass('red_blur')
                        }
                    }
                } 
            }
            
        }
    }, [stateNoti.inPromise, stateData.payload])

    
   
    useEffect(() => {
        $('input[name="inputForName"]').keypress
        (
        function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {
                    event.preventDefault();	
                }
                else 
                {
                    if (event.keyCode < 48 || event.keyCode > 57 ) 
                    {
                       
                    }else {
                        event.preventDefault();	
                    }	
                }
            }
        );
    })

    const handleClosePopup = () => {
        handleClose()
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    const handleRemoveNameMess = () => {
        $('.form-name').removeClass('red_blur')
        validationMsg.name = ''
    }
    const handleRemoveEmailMess = () => {
        $('.form-email').removeClass('red_blur')
        setCheckedEmail('')
        validationMsg.email = ''
    }
    const handleRemovePassMess = () => {
        $('.form-password').removeClass('red_blur')
        validationMsg.password = ''
    }
    const handleRemoveConfirmMess = () => {
        $('.form-password-confirm').removeClass('red_blur')
        validationMsg.passwordConfirm = ''
    }

    const handleRemovePersonMess = () => {
        $('.form-person').removeClass('red_blur')
        setCheckPerson('')
    }

    const handleRemoveCheckMess = () => {
        validationMsg.checkRule = ''
    }

    // set show for modal
    const [showLogin, setShowLogin] = useState(false);

    return (
        <div>
            {
                showLogin === true 
                ? <Login show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
                : 
                <div>
                    <Modal show={props.show} onHide={handleClosePopup} className="modal-login" >
                        <Login show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
                        <Modal.Header closeButton  className="grid">
                            <Modal.Title>Hoàn tất đăng ký</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="mt-4">
                            <Form className="form_pwd">
                                <div className="form-group">
                                    <input type="text" className="form-control form-name" onKeyDown={handleRemoveNameMess} name="inputForName" onChange={handleName} placeholder="Họ và tên"/>
                                    <span style={{'fontSize': '12px'}} className="text-danger">{validationMsg.name}</span> 
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control form-email" onKeyDown={handleRemoveEmailMess} onChange={handleEmail}  placeholder="Email"/>
                                    <span style={{'fontSize': '12px'}} className="text-danger">{validationMsg.email}</span> 
                                    <p style={{'fontSize': '12px'}} className="text-danger remove_email">{isCheckedEmail}</p> 
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" value={props.phone} disabled="disabled"/>
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control form-password" onKeyDown={handleRemovePassMess} onChange={handlePassword}  placeholder="Mật khẩu" aria-describedby="password-addon"/>
                                    <button type="button" id="password-addon" className="btn_pwd">
                                        <img src={Icon_pwd} alt="" className="hide_pwd"/>
                                        <img src={Icon_show} alt="" className="show_pwd"/>
                                    </button>
                                </div>
                                <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.password}</p> 
                                <div className="form-group">
                                    <input type="password" className="form-control form-password-confirm" onKeyDown={handleRemoveConfirmMess} onChange={handlePasswordConfirm}  placeholder="Nhập lại Mật khẩu"/>
                                    <button type="button" id="password-addoned" className="btn_pwd">
                                        <img src={Icon_pwd} alt="" className="hide_pwd"/>
                                        <img src={Icon_show} alt="" className="show_pwd"/>
                                    </button>
                                </div>
                                <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.passwordConfirm}</p> 
                                {/* <div className="form-group introduce">
                                    <input type="text" className="form-control form-person" onKeyDown={handleRemovePersonMess} maxLength="30" onChange={handlePerson}  placeholder="Người giới thiệu (nếu có)"/>
                                    <p style={{'fontSize': '12px'}} className="text-danger remove_person">{isCheckPerson}</p> 
                                </div> */}
                                <div className="form-group agree_terms">
                                    <label htmlFor="" className="d-flex customer_checkbox">
                                        <input type="checkbox" onChange={handleCheckbox}  checked={checkRule} className="form-control"/>
                                        <span className="checkmark"></span>
                                        <p className="mb-0">Đồng ý với các <a href="/static-page/dieu-khoan-dich-vu" target="_blank">Điều khoản</a> của {process.env.REACT_APP_NAME}</p>
                                    </label>
                                    <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.checkRule}</p> 
                                </div>
                                <div className="form-group mb-0">
                                    <Button onClick={onSubmitRegister} disabled={stateBtn}  className="button_acceprt">Đăng ký tài khoản</Button>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer className="login_other">
                            <div className="form-group w-100">
                                <label htmlFor="">Bạn đã có tài khoản?</label>
                                <div className="d-flex w-100">
                                    <Button className="btn_login_other">
                                        <img src={icon_login_gg} alt=""/>
                                    </Button>
                                    <LoginSocialNetWerk.GoogleLoginAuth/>
                                    <Button className="btn_login_other">
                                        <img src={icon_login_fb} alt=""/>
                                    </Button>
                                    <LoginSocialNetWerk.FaceBookLoginAuth/>
                                    <Button onClick={() => {
                                        handleClose()
                                        // setShowLogin(true)
                                        dispatch(websiteActions.setModal(true))
                                    }}  className="btn_register btn_login">
                                        Đăng nhập ngay
                                        <img src={icon_register} alt=""/>
                                    </Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </div>
    )
}

export default Register;
