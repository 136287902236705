import React from 'react'
import {useSelector} from "react-redux";

function CopyRight() {
    const footer = useSelector(state => state.getWebsite.footer)
    if (!footer) {
      return null
    }
    return (
        <div className="copy_right">
            <div className="container">
                <div className="row">
                    <span>Copyright © 2020 {process.env.REACT_APP_NAME}. All Rights Reserved</span>
                </div>
            </div>
        </div>
    )
}

export default CopyRight
