import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ENDPOINT from '../ultils/EndPoint';
import { useDispatch } from 'react-redux';
import { actions } from '../actions/User';

function Timer(props){
    const [flag, setFlag] = useState(false);
    const dispatch = useDispatch();

       useEffect(() => {
      if(flag === true) {
        dispatch(actions.checkTime(undefined))   
      }else {
        dispatch(actions.checkTime(null))   
      } 
    }, [dispatch, flag])


    const [ minutes, setMinutes ] = useState(1);
    const [seconds, setSeconds ] =  useState(30);


    useEffect(()=>{
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
      
                if (minutes === 0) {
            
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
            if(seconds === 0 && minutes === 0) {
              setFlag(true)
            }
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
    }, [minutes,seconds]);


      const handelCallOtp = () => {

      let query = '';
        const data = {
            phone : props.phone,
            type: props.type,
        }
        Object.keys(data).map((key,index) => 
        query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )
        axios.post(`${ENDPOINT.REGISTERGETOTP}`+ query, data)
        .then(function (response) {
          dispatch(actions.getNoti('checked'))
          if(response.data.status === true){
            dispatch(actions.getOTPAgains(response.data.data.id))
            setFlag(false)
            setMinutes(1)
            setSeconds(30)   

          }
        })
  }

    return (
        <div>
        {flag === false ? <span className="timerer">{minutes < 10 ?  `0${minutes}` : minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</span> :  <div style={{'cursor':'pointer'}}  onClick={handelCallOtp}>Yêu cầu gửi lại mã OTP</div> }
        </div>
    )

}

export default Timer;
