import React, {useEffect, useState} from 'react';
import eyeLogin from '../../../assets/img/icon-eye (1).svg'
import '../Profile.scss';
import $ from 'jquery';
import isEmpty from "validator/lib/isEmpty";
import axios from 'axios';
import ENDPOINT from '../../../ultils/EndPoint';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";

function ChangePassword() {
    const history = useHistory()
    const [msg, setMsg] = useState('')
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newAgainstPassword, setNewAgainstPassword] = useState('');
    const [validationMsg, setValidationMsg] = useState({});
    const [disable, setDisable]= useState(false);

    //removeExtraSpace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // validate before submit
    const validateAll = () => {
        const msg = {};
        
        if(isEmpty(oldPassword)) {
            msg.oldPassword = "Vui lòng nhập mật khẩu"
            $('.form-old-password').addClass('red_blur')
        }else if(oldPassword.length < 6 || oldPassword.length > 30){
            msg.oldPassword = "Mật khẩu phải nằm trong khoảng từ 6 đến 30 kí tự"
            $('.form-old-password').addClass('red_blur')
        }

        if(isEmpty(newPassword)) {
            msg.newPassword = "Vui lòng nhập mật khẩu mới"
            $('.form-password').addClass('red_blur')
        }else if(newPassword.length < 6 || newPassword.length > 30){
            $('.form-password').addClass('red_blur')
            msg.newPassword = "Mật khẩu phải nằm trong khoảng từ 6 đến 30 kí tự"
        }

        if(isEmpty(newAgainstPassword)) {
            msg.newAgainstPassword = "Vui lòng nhập lại mật khẩu mới"
            $('.form-password-confirm').addClass('red_blur')
        }else if(newAgainstPassword !== newPassword) {
            $('.form-password-confirm').addClass('red_blur')
            msg.newAgainstPassword = "Mật khẩu nhập lại không trùng khớp"
        }
        
        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // params for reset password
    const dataChangePassword = new URLSearchParams();
    dataChangePassword.append('password', oldPassword);
    dataChangePassword.append('new_password', newPassword);
    dataChangePassword.append('password_confirmation', newAgainstPassword);

    const updatePassword = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);
   
        return axios.post(`${ENDPOINT.CHANGE_PASSWORD_CLIENT}`, dataChangePassword,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-token') 
            }
           })
          .then(function (response) {
                if(response.status === 200) {
                    toast.success('Thay đổi mật khẩu thành công')

                    setTimeout(() => {
                        setOldPassword('')
                        setNewPassword('')
                        setNewAgainstPassword('')
                        $('#inputPass1').val('')
                        $('#inputPass2').val('')
                        $('#inputPass3').val('')
                        window.location.reload()
                    }, 1000);
                }
          })
          .catch(error => {
            setMsg('Mật khẩu sai. Xin vui lòng kiểm tra lại !')
          }
        )
    } 

    // handle password show/hide
    function initSettings() {
        $("#change_password-1").off('click').on('click', function(){
            $(this).toggleClass('active');
            if($(this).prev('input').length > 0) {
                $(this).prev('input').attr('type') === "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
            }
        })
        $("#change_password-2").off('click').on('click', function(){
            $(this).toggleClass('active');
            if($(this).prev('input').length > 0) {
                $(this).prev('input').attr('type') === "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
            }
        })
        $("#change_password-3").off('click').on('click', function(){
            $(this).toggleClass('active');
            if($(this).prev('input').length > 0) {
                $(this).prev('input').attr('type') === "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
            }
        })
    }
    useEffect(() => {
        initSettings()
    })

    const handleOldPassword = (e) => {
        setOldPassword(removeExtraSpace(e.target.value))
    }

    const handleNewPassword = (e) => {
        setNewPassword(removeExtraSpace(e.target.value))
    }

    const handleNewPasswordAgainst = (e) => {
        setNewAgainstPassword(removeExtraSpace(e.target.value))
    }

    const handleGoToHomePage = () => {
        history.push('/');
    }

    const handleRemoveMessOldPass = () =>{
        validationMsg.oldPassword = ''
        $('.form-old-password').removeClass('red_blur')
        setMsg('')
    }

    const handleRemoveMessNewPass = () =>{
        validationMsg.newPassword = ''
        $('.form-password').removeClass('red_blur')
    }

    const handleRemoveMessConfirm = () =>{
        $('.form-password-confirm').removeClass('red_blur')
        validationMsg.newAgainstPassword = ''
    }

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="changePass-wapper">
                <h1>Thay đổi mật khẩu</h1>
                <div className="changePass-body">
                    <div className="changePass-input-wapper">
                        <p>Mật khẩu cũ</p>
                        <div className="input-group auth-pass-inputgroup changePass-input">
                            <input type="password" onChange={handleOldPassword} onKeyDown={handleRemoveMessOldPass} id="inputPass1" className="form-control form-old-password"/>
                            <button className="btn btn-light" type="button" id="change_password-1"><i className="mdi mdi-eye-outline"><img alt="" src={eyeLogin}></img></i></button>
                        </div>
                    </div>
                    <p style={{'fontSize': '12px', 'marginLeft':'168px'}} className="text-danger">{validationMsg.oldPassword}</p> 
                    <p style={{'fontSize': '12px', 'marginLeft':'168px'}} className="text-danger">{msg}</p> 
                    <div className="changePass-input-wapper">
                        <p>Mật khẩu mới</p>
                        <div className="input-group auth-pass-inputgroup changePass-input">
                            <input type="password" onChange={handleNewPassword} onKeyDown={handleRemoveMessNewPass} id="inputPass2" className="form-control form-password"/>
                            <button className="btn btn-light" type="button" id="change_password-2"><i className="mdi mdi-eye-outline"><img alt="" src={eyeLogin}></img></i></button>
                        </div>
                    </div>
                    <p style={{'fontSize': '12px', 'marginLeft':'168px'}} className="text-danger">{validationMsg.newPassword}</p> 
                    <div className="changePass-input-wapper">
                        <p>Nhập lại mật khẩu</p>
                        <div className="input-group auth-pass-inputgroup changePass-input">
                            <input type="password" onChange={handleNewPasswordAgainst} onKeyDown={handleRemoveMessConfirm} id="inputPass3" className="form-control form-password-confirm"/>
                            <button className="btn btn-light" type="button" id="change_password-3"><i className="mdi mdi-eye-outline"><img alt="" src={eyeLogin}></img></i></button>
                        </div>
                    </div>
                    <p style={{'fontSize': '12px', 'marginLeft':'168px'}} className="text-danger">{validationMsg.newAgainstPassword}</p> 
                </div>
                <div className="btn-changePassword">
                    <button className="goto-home goto-home-btn" onClick={handleGoToHomePage}> Quay lại trang chủ</button>
                    <button className="update-password update-password-btn" disabled={disable} onClick={updatePassword}>Cập nhật thông tin</button>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword
