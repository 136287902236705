import {
  GET_LIST_CATEGORY,
} from "../constans/ActionType";

const init = {
  categories: [],
}

export const getCategoryProduct = (state = init, {type, payload}) => {
  switch (type) {
    case GET_LIST_CATEGORY:
      return {...state, categories : payload}
    default:
      return state;
  }
}