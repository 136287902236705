import React from 'react';
import img_vector from '../../../assets/img/Vector 9.png';
import {Link, useHistory} from 'react-router-dom';
import {websiteActions} from "../../../actions";
import {useDispatch} from "react-redux";

function BreadCrumb(props) {
    const history = useHistory()
    const dispatch = useDispatch()
    const handleMovetoHome = () => {
        dispatch(websiteActions.setFooter(true))
        history.push('/')
    }

    return (
        <ul className="breadcrumb w-100">
            <li onClick={handleMovetoHome}>
                <Link to='/'>{props.from}</Link>
                <span>
                    <img src={img_vector} alt=""/>
                </span>
            </li>
            <li>
            {
                props.toNameSlug === undefined && props.toSlug === undefined
                ? <Link disabled to='#' style={{color : '#000'}} >{props.to}</Link>
                : <Link to={`/${props.toNameSlug}/${props.toSlug}`}>{props.to}</Link>
                
            }
            </li>
            {
                props.detail 
                ?  
                <li>
                    <span>
                        <img src={img_vector} alt=""/>
                    </span>
                    <Link disabled={(props.toNameSlug === undefined && props.toSlug === undefined) || props.checkend === true ? true : false} to="#">{props.detail}</Link>
                </li>
                : ''
            }
           
        </ul>
    )
}

export default BreadCrumb;
