import React, {useEffect, useState} from 'react';
import BreadCrumb from "../components/common/breadcrumb/Breadcrumb";
import Filter from "../components/common/filter/filter";
import Product from "../components/common/product/Product";
import BannerFooter from "../components/common/banner-footer/BannerFooter";
import {ProductApiService} from "../ultils/EndPoint";
import {
    deleteValueEmptyFromObject,
    getJsonQueryParams,
    getJsonQueryParamsSearch,
    getQuerystring
} from "../helpers/Function";
import SearchNotFound from "../../src/pages/Search_notfound";
import {DEFAULT_LIMIT_SEARCH} from "../helpers/constant";
function Search() {
    let keyword = decodeURIComponent(getQuerystring("keyword"))
    let allQuery = getQuerystring("")
    const [listProductSearch, setListProductSearch] = useState({});
    const [brands, setBrands] = useState([]);
    const [isNotFound, setNotFound] = useState(false);
    const [dataPaginate, setDataPaginate] = useState({});
    useEffect(() => {
        let dataParam = getJsonQueryParamsSearch()
        dataParam.per_page = DEFAULT_LIMIT_SEARCH
        deleteValueEmptyFromObject(dataParam)
        ProductApiService.getListProduct(dataParam).then(res => {
                if (res.data !== {}){
                    setListProductSearch(res.data)
                    if (res.data.records == null){
                        setNotFound(true)
                    }else{
                        setDataPaginate({
                            "totalRecord" : res.data.total_record,
                            "totalPage"   : res.data.total_page,
                            "currentPage"   : res.data.page,
                            "perPage"   : res.data.limit,
                        })
                        setNotFound(false)
                    }
                }
        })

        ProductApiService.getBrands().then(res => {
            if (res.status) {
                setBrands(res.data)
            }
        })

    }, [allQuery])

    return (
        <div>
            <div className="container">
                <div className="row">
                    <BreadCrumb from={'Trang chủ'} to={'Tìm kiếm'} />
                    <div className="main product_group d-flex">
                        <div className="main_filter">
                            <Filter brands={brands} page="search"/>
                        </div>
                        <div className="main_product">
                            {
                                isNotFound ? <SearchNotFound keyword={keyword} /> : <Product products={listProductSearch.records} dataPaginate={dataPaginate} page="search"/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <BannerFooter/>
        </div>
    )
}
export default Search;
