import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import {websiteActions} from "../../../actions";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

function ItemMegaMenu({data}) {
  const [dataChild, setDataChild] = useState([]);
  const dispatch = useDispatch()
  useEffect(() => {
    if (data.Child && data.Child.length) {
      setDataChild(data.Child)
    }
  }, [data.Child])

  const closeHover =  (e) => {
    $(e.target).parents('.mega-menu-child').hide()
    dispatch(websiteActions.setFooter(true))
  }


    // useEffect(() => {
    //     $(document).on('click','.list_menu_mega a',function () {
    //         $(this).parents().find('.mega-menu-child').hide();
    //     })
    // })


  return (
    <div className="list_mega_menu">
      <Link to={"/danh-muc/" + data.slug} onClick={closeHover}><h5>{data.name}</h5></Link>
      <ul className="list_menu_mega">
        {dataChild
        .filter(item => { if (item.status) { return item}})
        .sort((a,b) => a.order_no - b.order_no)
        .map((item,index) => {
          if (index <= 4) {
            return (<li key={item.id}>
              <Link to={"/danh-muc/" + item.slug} onClick={closeHover}>{item.name}</Link>
            </li>)
          } else {
            return ''
          }
        })}
        {dataChild.length > 5 ? (<li>
          <Link to={"/danh-muc/" + data.slug} className="show_more" onClick={closeHover}>... nhiều hơn</Link>
        </li>) : ''}
      </ul>
    </div>
  )
}

export default ItemMegaMenu;
