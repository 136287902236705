import { 
    NOTI_SOCIAL
} from "../constans/ActionType";

export const userProfileUpdate = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
    case NOTI_SOCIAL:
        return { payload };
  
      default:
        return state;
    }
};
  