import React, {useEffect, useState} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slide.scss';
import Slider from "react-slick";

function SlideDetail(props) {

    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)

    var slider1 = props.slide
    var slider2 = props.slide

   useEffect(() => {
       setNav1(slider1)
       setNav2(slider2)
   }, [slider1, slider2])

    return (
        <div className="slide_detail">
            {props.slide && props.slide.length > 4 ?
                <div>
                    <div>
                        <Slider
                            asNavFor={nav2}
                            ref={slider => (slider1 = slider)}
                            className="slick_top"
                        >

                        {slider1 && slider1.map((image, index) => 
                            <div key={index}>
                                <img src={image.image} alt=""/>
                            </div>
                        )}

                        </Slider>
                    </div>
                    <div>
                        <Slider
                            asNavFor={nav1}
                            ref={slider => (slider2 = slider)}
                            slidesToShow={4}
                            swipeToSlide={true}
                            focusOnSelect={true}
                            className="slick_nav"
                        >
                            {slider2 && slider2.map((image, index) => 
                                <div key={index}>
                                    <img src={image.image} alt=""/>
                                </div>
                            )}

                        </Slider>
                    </div>
                </div>
            : 
                <div>
                    <div>
                        <Slider
                            asNavFor={nav2}
                            infinite={false}
                            ref={slider => (slider1 = slider)}
                            className="slick_top"
                        >

                        {slider1 && slider1.map((image, index) => 
                            <div key={index}>
                                <img src={image.image} alt=""/>
                            </div>
                        )}

                        </Slider>
                    </div>
                    <div>
                        <Slider
                            asNavFor={nav1}
                            ref={slider => (slider2 = slider)}
                            slidesToShow={4}
                            infinite={false}
                            swipeToSlide={false}
                            focusOnSelect={true}
                            className="slick_nav"
                        >
                            {slider2 && slider2.map((image, index) => 
                                <div key={index}>
                                    <img src={image.image} alt=""/>
                                </div>
                            )}

                        </Slider>
                    </div>
                </div>
                
            }

        </div>
        
    )
}

export default SlideDetail
