import { 
    AUTH_ME
 } from "../constans/ActionType";
 
 const init = {
     auth: [],
 }
 
 export const AuthMeRoot = (state = init, {type, payload}) => {
     switch (type) {
         case AUTH_ME:
             return {...state, auth : payload}
         default:
             return state;
     }
 }