import React from 'react';
import '../../../assets/scss/styles.scss';
import { actions } from '../../../actions/User';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import {websiteActions} from '../../../actions'

export const FaceBookLoginAuth = () => {
    const dispatch = useDispatch();
    const responseFacebook = (response) => {
        if(response){
            const userSocail = {
                access_token : response.accessToken,
                provider : response.graphDomain
            }
            dispatch(actions.loginSocialNetWerk(userSocail));
        }
    }
    const handleFaceBook = () => {
        responseFacebook();
        dispatch(websiteActions.setSocialLoginSuccess(true));
    }

    return (
        <FacebookLogin
            appId={`${process.env.REACT_APP_ID_FACEBOOK}`}
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            onClick={handleFaceBook}
            cssClass="my-facebook-button-class"
        />
    )
}

export const GoogleLoginAuth = () => {
    const dispatch = useDispatch();
    const responseGoogle = res => {
        dispatch(websiteActions.setSocialLoginSuccess(true))
        if(res){
            const userSocail = {
                access_token : res.accessToken,
                provider : res.Zb.idpId
            }
            dispatch(actions.loginSocialNetWerk(userSocail));
        }
    }

    return (
        <GoogleLogin
            clientId={`${process.env.REACT_APP_ID_GOOGLE}`}
            cookiePolicy={'single_host_origin'}
            className="google_login_btn"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
        />
    )
}