import React, {useEffect, useState} from "react";
import {ServiceApiService} from "../../../ultils/EndPoint";

function Index() {
  const [services, setService] = useState([]);
  useEffect(() => {
    ServiceApiService.get().then(res => {
      setService(res.data)
    })
  }, [])
  if (services && services.length == 4) {
    return (<div className="method-delivery">
      {services.map(service => {
        return <ul key={service.id} className="non-border-left pl-0">
          <li>
            <img className="mr-3" src={service.icon} alt="" style={{'width' : '36px', 'height': '36px'}}></img>
          </li>
          <li>
            <h3>{service.title_1}</h3>
            <p>{service.title_2}</p>
          </li>
        </ul>
      })}

    </div>)
  }
  return null;
}

export default Index
