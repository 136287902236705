import React, {useEffect, useState} from 'react';
import SliderShow from '../components/common/slider/SliderShow';
import SliderFooter from '../components/common/slider-footer/SliderFooter';
import './main.scss';
import BannerFooter from '../components/common/banner-footer/BannerFooter';
import ProductRow from '../components/home-page/product-list/ProductRow';
import Category from '../components/home-page/category/Category';
import Brand from '../components/home-page/brand/Brand';
import {PartnerApiService, DealApiService, ProductApiService, NewsHomePageService} from "../ultils/EndPoint";
import Service from '../components/home-page/service'
import BannerSale from '../components/home-page/banner'
import {useDispatch} from "react-redux";
import {actions} from "../actions/Groups";
import NewsHomePage from "../components/home-page/news";
import {chunkArray} from "../helpers/Function";

function Main() {
    const dispatch = useDispatch()
    const [partners, setPartners] = useState([]);
    const [deal, setDeals] = useState(null);
    const [groups, setGroups] = useState([]);
    const [news, setNews] = useState([])

    useEffect(async () => {
        await DealApiService.getDealHomepage().then(res => {
          if (res.data) {
            setDeals(res.data)
          }
        })
      await ProductApiService.getGroupProduct().then(res => {
          if (res.data) {
            dispatch(actions.setGroups(res.data))
            let groupData = res.data.filter(item => {
              if (item.products && item.products.length) {
                return item;
              }
            })
            setGroups(groupData)
          }
        })
      let e = await NewsHomePageService.getNews()
      if (e.status) {
        setNews(e.data)
      }
      await PartnerApiService.get().then(res => {
        const dataPartners = chunkArray(res.data, 8)
        setPartners(dataPartners)
      })
    }, [])

    return (
        <div>
            <SliderShow/>
            <div className="container">
                <div className="row">
                  <BannerSale />
                  <Service />
                </div>
            </div>
            
            {/*Hot deal mỗi ngày*/}
            {deal && deal.id ? <ProductRow name={deal.name} deal={1} end_time={deal.end_time} products={deal.products} slug={deal.slug}/> : null}
            {/*<ProductRow name={'Hot deal mỗi ngày'} deal={1} />*/}

            {/* Danh mục sản phẩm */}
            <Category name={'Danh mục sản phẩm'}/>

            {/* Sale shock ngày Nhà giáo Việt Nam 20/11*/}
            {groups.length && groups[0] ?  <ProductRow name={groups[0].name} banner={groups[0].cover} slug={groups[0].slug} products={groups[0].products.slice(0,10)} /> : null}

            {/* Danh sách các thương hiệu */}
            <Brand name= {'Danh sách các thương hiệu'}/>

            {/* Chăm sóc da - CITIMED Beauty*/}
            {groups.length && groups.length > 1 ?
              groups.slice(1).map(group => {
                return  <ProductRow key={group.id} name={group.name} banner={group.cover} slug={group.slug} products={group.products.slice(0, 10)} />
              })
              : null}

            <BannerFooter/>

            { news.length ? <NewsHomePage news={news}/> : null }
            
            <SliderFooter dataPartners={partners}/>
        </div>
    )
}

export default Main
