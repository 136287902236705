import React, {useEffect, useState} from 'react'

import BreadCrumb from "../breadcrumb/Breadcrumb";
import './news.scss';
import {NewsService} from "../../../ultils/EndPoint";
import New from "./New";
import { Pagination } from 'antd';
import {useHistory, useParams} from "react-router-dom";
import {appendQueryParamsAndRedirect} from "../../../helpers/Function";
import {DEFAULT_LIMIT_NEWS} from "../../../helpers/constant";
import $ from "jquery";


function News() {
    const {slug} = useParams();
    const [news , setNews] = useState([])
    const [catalogs, setCatalogs] = useState([])
    const [currentPage, setcurrentPage] = useState(1);
    const [total, setTotal] = useState();
    const customersPerPage =  DEFAULT_LIMIT_NEWS;

const history = useHistory()

    const setCurrentPage = async (page) => {
        setcurrentPage(page)
        appendQueryParamsAndRedirect('page', page, history)
        await getDataNews(slug, {page : page})
    }

    async function getDataNews(slug = 'all', params = {}) {
        params.per_page = customersPerPage
        let e = await NewsService.getNews(slug, params)
        if (e.status) {
            setNews(e.data.records)
            setTotal(e.data.total_record || 0)
        }
    }
    useEffect(async () => {
        await getDataNews(slug)
    }, [slug])

    useEffect(async () => {

        let e = await NewsService.getCatalogs()
        if (e.status) {
            setCatalogs(e.data)
        }
    }, [])

    async function selectCatalogNew(event) {
    await getDataNews(event.target.value)
        setcurrentPage(1)
        appendQueryParamsAndRedirect('page', 1, history)
        history.push(`/tin-tuc-danh-muc/${event.target.value}`)
    }
    return (
        <div className="news_list container pl-0 pr-0 ">
            <BreadCrumb from={'Trang chủ'} to={'Tin tức'} toNameSlug={undefined} toSlug={undefined} detail={undefined}/>
            <div className="product mt-12">
                <div className="header-productTitle d-flex align-items-center justify-content-between w-100">
                    <div className="title_header">
                        <h2 className="mb-0">Tin tức của {process.env.REACT_APP_NAME}</h2>
                        {total > 0 ?
                        <p>Hiển thị {customersPerPage * (currentPage - 1) + 1} - {customersPerPage * currentPage < total
                        ? customersPerPage * currentPage : total} trong số {total} tin tức</p>
                          : <p>Không có tin tức nào</p>}
                    </div>
                    <select className="form-control custom_select" onChange={selectCatalogNew}>
                        <option value="all">Tất cả tin tức</option>
                        { catalogs.map( value => (
                            <>
                            <option value={value.slug} selected={slug === value.slug}> {value.name} </option>
                                {value.child && value.child.length ? value.child.map(valueChild => (
                                     <option value={valueChild.slug} selected={slug === valueChild.slug}>&ensp;{valueChild.name}</option>
                                )) : ""}
                            </>
                        )) }
                    </select>
                </div>
            </div>
            <div className="hotNew-body grid grid-3 mt-24 grid-gap-30">
                { news && news.length ? news.map(value => (<New new={value} key={value.id} />)) : null }
            </div>
            {total <= customersPerPage ? '' :
                                  <Pagination
                                    defaultCurrent={currentPage}
                                    onChange={(value) => {window.scrollTo({top: 200, left: 0, behavior: 'smooth' }); setCurrentPage(value) }}
                                    total={total}
                                    current={currentPage}
                                    defaultPageSize={customersPerPage}
                                    showSizeChanger= {false}
                                  />
                                }
        </div>
    )
}
export default News;
