export const DEFAULT_LIMIT_NEWS = 9

export const DEFAULT_LIMIT_SEARCH = 12

export const SHIP_COD = 1

export const UNCONFIRMED = 0
export const CONFIRMED = 1
export const SUCCESS = 2
export const CANCEL_CLIENT = 3
export const CANCEL_ADMIN = 4

export const RANK =
    [
        {
            min : 0,
            max : 4999,
            rank_current : "Standard",
            image_rank_current : "",
            rank_next : "Gold",
            image_rank_next : "",
        },
        {
            min: 5000,
            max : 20000,
            rank_current : "Gold",
            image_rank_current : "",
            rank_next : "Platinum",
            image_rank_next : "",
        },
        {
            min: 20001,
            max : 50000,
            rank_current : "Platinum",
            image_rank_current : "",
            rank_next : "Diamond",
            image_rank_next : "",
        },
        {
            min: 50001,
            max : "",
            rank_current : "Diamond",
            image_rank_current : "",
            rank_next : "",
            image_rank_next : "",
        }
    ]


export const EventJoinChannel = 1
export const EventMessage     = 2