import React, {useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import icon_login_gg from "../../assets/img/icons8-google 1.png";
import icon_login_fb from "../../assets/img/icons8-facebook 1.png";
import icon_register from "../../assets/img/Expand_right_double_light.png";
import Icon_pwd from '../../assets/img/Eye_light.png';
import Icon_show from '../../assets/img/Eye_light_active.png';
import $ from 'jquery';
import isEmpty from "validator/lib/isEmpty";
import axios from 'axios';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ENDPOINT from '../../ultils/EndPoint';
import * as LoginSocialNetWerk from '../../components/common/social-login/Social_login';
import Login from './login';
import { useDispatch } from 'react-redux';
import { websiteActions } from '../../actions';

function ResetPwd (props) {
    const [show, setShow] = useState(false);
    const [validationMsg, setValidationMsg] = useState({});
    const handleClose = () => setShow(props.close);
    const [disable, setDisable]= useState(false);
    //removeExtraSpace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const handlePassword = (e) => {
        setPassword(removeExtraSpace(e.target.value))
    }
    const dispatch = useDispatch()

    const handlePasswordConfirm = (e) => {
        setPasswordConfirm(e.target.value)
    }

    // validate before submit
    const validateAll = () => {
        const msg = {};
            
        if(password.length === 0) {
            msg.password = "Vui lòng nhập mật khẩu"
            $('.form-password').addClass('red_blur')
        }else if(password.length < 6) {
            msg.password = "Mật khẩu phải có tối thiểu 6 kí tự"
            $('.form-password').addClass('red_blur')
        }

        if(isEmpty(passwordConfirm)) {
            msg.passwordConfirm = "Vui lòng nhập lại mật khẩu"
            $('.form-password-confirm').addClass('red_blur')
        }else if( passwordConfirm !== password ) {
            msg.passwordConfirm = "Hai mật khẩu không giống nhau "
            $('.form-password-confirm').addClass('red_blur')
        }

        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // params for reset password
    const dataResetPassword = new URLSearchParams();
    dataResetPassword.append('phone', props.phoneNumber);
    dataResetPassword.append('password', password);
    dataResetPassword.append('password_confirmation', passwordConfirm);
    dataResetPassword.append('otp_id', props.otp_id);
    dataResetPassword.append('otp', props.otp);

    // vetifyOTP
    const handleConfirResetPassword = () => {
        const isValidate = validateAll()
        if(!isValidate) return


        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 4000);

        axios.post(`${ENDPOINT.RESETPASSWORD}`, dataResetPassword)
        .then(function (response) {
            if(response.status === 200) {
                $('.form-password-confirm').removeClass('red_blur')
                $('.form-password').removeClass('red_blur')
                toast.success('Đặt lại mật khẩu thành công') 
            }
            setTimeout(() => {
                dispatch(websiteActions.setModal(true))
                handleCloseModal();
            }, 2000);
        })
        .catch(error => {}) 
    }

    function initSettings() {
        $("#password-addon2").off('click').on('click', function(){
            $(this).toggleClass('active');
            if($(this).prev('input').length > 0) {
                $(this).prev('input').attr('type') === "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
            }
        })
        $("#password-addoned3").off('click').on('click', function(){
            $(this).toggleClass('active');
            if($(this).prev('input').length > 0) {
                $(this).prev('input').attr('type') === "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
            }
        })
    }
    useEffect(() => {
        return () => {
            initSettings()
        }
    })

    const handleCloseModal = () => {
        setShow(props.close)
        setValidationMsg('')
        setPassword('')
        // window.location.reload()
    }

    const closeMove = () => {
        handleClose()
        setValidationMsg('')
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    }

    const handleRemovePassMess = () => {
        validationMsg.password = ''
        $('.form-password').removeClass('red_blur')
    }

    const handleRemovePassConfirmMess = () => {
        validationMsg.passwordConfirm = ''
        $('.form-password-confirm').removeClass('red_blur')
    }

    // set show for modal
    const [showLogin, setShowLogin] = useState(false);

    return (
        <div>
            {/*<ToastContainer*/}
            {/*    position="top-right"*/}
            {/*    autoClose={2000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*/>*/}
            {
                showLogin === true 
                ? <Login show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
                : 
                <div>
                   
                    <Modal show={props.show} onHide={closeMove} className="modal-login" >
                        <Modal.Header closeButton  className="grid">
                            <Modal.Title>Đặt lại mật khẩu</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="mt-4">
                            <Form className="form_pwd">
                                <div className="form-group">
                                    <input type="password" onChange={handlePassword} onKeyDown={handleRemovePassMess} className="form-control form-password" placeholder="Mật khẩu" aria-describedby="password-addon"/>
                                    <button type="button" id="password-addon2" className="btn_pwd">
                                        <img src={Icon_pwd} alt="" className="hide_pwd"/>
                                        <img src={Icon_show} alt="" className="show_pwd"/>
                                    </button>
                                </div>
                                <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.password}</p> 

                                <div className="form-group">
                                    <input type="password" onChange={handlePasswordConfirm} onKeyDown={handleRemovePassConfirmMess} className="form-control form-password-confirm" placeholder="Nhập lại Mật khẩu"/>
                                    <button type="button" id="password-addoned3" className="btn_pwd">
                                        <img src={Icon_pwd} alt="" className="hide_pwd"/>
                                        <img src={Icon_show} alt="" className="show_pwd"/>
                                    </button>
                                </div>
                                <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.passwordConfirm}</p> 

                                <div className="form-group">
                                    <Button onClick={handleConfirResetPassword} disabled={disable} className="button_acceprt">Lưu mật khẩu</Button>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group w-100">
                                <label htmlFor="">Bạn đã có tài khoản?</label>
                                <div className="d-flex w-100">
                                    <Button className="btn_login_other">
                                        <img src={icon_login_gg} alt=""/>
                                    </Button>
                                    <LoginSocialNetWerk.GoogleLoginAuth/>
                                    <Button className="btn_login_other">
                                        <img src={icon_login_fb} alt=""/>
                                    </Button>
                                    <LoginSocialNetWerk.FaceBookLoginAuth/>
                                    <Button onClick={() => {
                                        handleClose()
                                        // setShowLogin(true)
                                        dispatch(websiteActions.setModal(true))
                                    }} className="btn_register btn_login">
                                        Đăng nhập ngay
                                        <img src={icon_register} alt=""/>
                                    </Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </div>
    )
}

export default ResetPwd;
