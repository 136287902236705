import React, {useEffect, useState} from 'react';
import icon_standard from '../../../assets/img/badge (1) 1.svg';
import icon_standard1 from '../../../assets/img/badge (1) 2.svg';
import { Link } from 'react-router-dom';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {ApiService, PointApiService} from "../../../ultils/EndPoint";
import {
    convertDateTime,
    formatCurrency,
    objectToQueryParams
} from "../../../helpers/Function";
import {Pagination} from "antd";
import HistoryPointEmpty from '../profile-components/HistoryPointEmpty';
import {ProgressBar} from "react-bootstrap";
import {RANK} from "../../../helpers/constant";
function HistoryPoint() {

    const perPage = 10;
    const [type, setType] = useState(1)
    const [page, setPage] = useState(1)
    const [history, setHistory] = useState([])
    const [auth, setAuth] = useState({})
    const [dataPaginate, setDataPaginate] = useState({
        "currentPage" : 1
    })
    const [infoRank, setInfoRank] = useState({})

    function setDataQuery(page = ''){
        let data = {}
        if (page){
            data.page = page
        }
        data.per_page = perPage
        data.type = type
        return objectToQueryParams(data)
    }

    function getListHistory(query){
        PointApiService.history(query).then(r => {
            if (r.status){
                setHistory(r.data.records)
                setDataPaginate({
                    "totalRecord" : r.data.total_record,
                    "totalPage"   : r.data.total_page,
                    "currentPage"   : r.data.page,
                    "perPage"   : r.data.limit,
                })
            }
        })
        ApiService.get('client/me', {}, true).then(r => {
            if (r.status){
                setAuth(r.data)
            }
        })

    }

    function setCurrentPage(page){
        let query = setDataQuery(page)
        getListHistory(query)
    }
    const handleChangeTab = (e) =>{
        setType(parseInt(e))
    }

    const getInfoRank = () => {
        let pointAuth = auth.point_rank_level
        let rank = RANK.filter((value, key) => {
            if ((value.min <= pointAuth && value.max >= pointAuth) || (value.min <= pointAuth && key === RANK.length - 1)){
                return value
            }
        })
        setInfoRank(rank[0])
    }

    useEffect(() => {
        getInfoRank()
    }, [auth])

    useEffect(() => {
        let query = setDataQuery()
        getListHistory(query)
    }, [type, page])

    return (
        <div className="content_purchase ">
            <div className="changePass-wapper ">
                <h1>Lịch sử tích điểm</h1>
                <div className="step_history_point d-flex justify-content-between">
                    <div className="grid text-center">
                        <img src={icon_standard} alt=""/>
                        <span>{infoRank ? infoRank.rank_current : ''}</span>
                    </div>
                    <div className="bg_step">
                        <span>{auth.point_rank_level}{infoRank && infoRank.max ? "/" : ""}{infoRank ? infoRank.max : ''}</span>
                        <strong>
                            <ProgressBar max={infoRank ? infoRank.max : auth.point_rank_level} variant="success" now={auth.point_rank_level} />
                        </strong>
                    </div>
                    <div className="grid text-center">
                        {infoRank && infoRank.max ? <img src={icon_standard1} alt=""/> : ""}
                        <span>{infoRank ? infoRank.rank_next : ''}</span>
                    </div>
                </div>
                <div className="total_point d-flex justify-content-between">
                    <h2>Bạn đang có {formatCurrency(auth.Point)} điểm {process.env.REACT_APP_NAME}</h2>
                    <Link to="/profile/history-points/buy">Đổi điểm</Link>
                </div>
                <Tabs onSelect={handleChangeTab} defaultActiveKey="1" id="uncontrolled-tab-example" className="tab_purchase table_point d-flex">
                    <Tab eventKey="1" title="Điểm đã nhận">
                        <div className="table-responsive table_point pc">
                            <table className="table borderless">
                                {history.length ? <thead>
                                    <th>Thời gian</th>
                                    <th>Số điểm</th>
                                    <th>Nội dung</th>
                                    <th>Loại</th>
                                    </thead>
                                     : ''}
                                <tbody>
                                {type == 1 && history.length ? history.map((v, key) =>
                                    <tr>
                                        <td>{convertDateTime(v.created_at, "HH:mm - DD/MM/YYYY")}</td>
                                        <td>+{v.value}</td>
                                        <td>{v.content}</td>
                                        <td>{v.subject}</td>
                                    </tr>
                                ) : <HistoryPointEmpty />}
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive table_point mobile">
                            {type == 1 && history.length ? history.map((v, key) =>
                                <ul>
                                    <div className="d-flex">
                                        <span className="time">
                                            {convertDateTime(v.created_at, "HH:mm - DD/MM/YYYY")}
                                        </span>
                                        <span className="time">{v.subject}</span>
                                    </div>
                                    <p className="point">+{v.value}</p>
                                    <strong>{v.content}</strong>
                                </ul>
                            ) : <HistoryPointEmpty />}
                        </div>
                    </Tab>
                    <Tab eventKey="2" title="Điểm đã dùng">
                        <div className="table-responsive table_point pc">
                            <table className="table borderless">
                                {history.length ? <thead>
                                    <th>Thời gian</th>
                                    <th>Số điểm</th>
                                    <th>Nội dung</th>
                                    <th>Loại</th>
                                    </thead>
                                    : ''}
                                <tbody>
                                {type == 2 && history.length ? history.map((v, key) =>
                                    <tr>
                                        <td>{convertDateTime(v.created_at, "HH:mm - DD/MM/YYYY")}</td>
                                        <td>-{v.value}</td>
                                        <td>{v.content}</td>
                                        <td>{v.subject}</td>
                                    </tr>
                                ) : <HistoryPointEmpty />}
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive table_point mobile">
                            <div className="table-responsive table_point mobile">
                                {type == 2 && history.length ? history.map((v, key) =>
                                    <ul>
                                        <div className="d-flex">
                                        <span className="time">
                                            {convertDateTime(v.created_at, "HH:mm - DD/MM/YYYY")}
                                        </span>
                                            <span className="time">{v.subject}</span>
                                        </div>
                                        <p className="point minus">-{v.value}</p>
                                        <strong>{v.content}</strong>
                                    </ul>
                                ) : <HistoryPointEmpty />}
                            </div>
                        </div>
                    </Tab>
                </Tabs>

                {dataPaginate.totalRecord <= perPage ? '' :
                    <Pagination
                        onChange={(value) => setCurrentPage(value)}
                        total={dataPaginate.totalRecord}
                        current={dataPaginate.currentPage}
                        showSizeChanger= {false}
                        defaultPageSize={perPage}
                    />
                }
            </div>
        </div>
    )
}

export default HistoryPoint;
