import {
  GET_WEBSITE,
  GET_STORE, GET_FOOTER,
  GET_MODAL, GET_CHAT
  , CHECKREGISTER_SUCCESS
  , USER_REGISTER
  , OPEN_CHAT
  , OPEN_FULL_MY_ORDER
  , SOCIAL_LOGIN
} from "../constans/ActionType";

const actions = {};

actions.setWebsite = (website) => {
  return { type: GET_WEBSITE, payload: website };
};

actions.setStore = (stores) => {
  return { type: GET_STORE, payload: stores };
};

actions.setFooter = (website) => {
  return { type: GET_FOOTER, payload: website };
};

actions.setModal = (modal) => {
  return { type: GET_MODAL, payload: modal };
};

actions.setChat = (chat) => {
  return { type: GET_CHAT, payload: chat };
};

actions.setFullMyOrder = (myOrder) => {
  return { type: OPEN_FULL_MY_ORDER, payload: myOrder };
};

actions.setRegistrerSuccess = (checkedRegister) => {
  return { type: CHECKREGISTER_SUCCESS, payload: checkedRegister };
};

actions.setSocialLoginSuccess = (socialLogin) => {
  return { type: SOCIAL_LOGIN, payload: socialLogin };
};

actions.setOpenChat = (openChat) => {
  return {
    type: OPEN_CHAT,
    payload : openChat
  }
}

actions.setUserForLogin = (userInfo) => {
  return { type: USER_REGISTER, payload: userInfo };
};


export { actions };
