import React from 'react'
import BreadCrumb from "../components/common/breadcrumb/Breadcrumb";
import imgCart from '../assets/img/cart/shopping-cart 1.png';
import '../assets/scss/cart.scss';
import {Link} from 'react-router-dom';
import {websiteActions} from "../actions";
import {useDispatch} from "react-redux";

function CartEmpty() {
    const dispatch = useDispatch()
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="cart">
                        <BreadCrumb 
                            from={'Trang chủ'}
                            to={'Giỏ hàng'}                        
                            toNameSlug={undefined}
                            toSlug={undefined}
                            detail={undefined}
                        />
                        <div className="w-100 cart_empty grid justify-content-center">
                            <img src={imgCart} alt=""/>
                            <h5>Giỏ hàng của bạn hiện đang không có sản phẩm nào</h5>
                            <Link to="/" onClick={() => {dispatch(websiteActions.setFooter(true))}} className="button_buy_continue">Tiếp tục mua sắm</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartEmpty;
