import LazyLoad from "react-lazyload";
import React, {useEffect, useState} from "react";
import {BannerApiService} from "../../../ultils/EndPoint";
import {Link} from "react-router-dom";
import {isMobile} from 'react-device-detect';

function Index() {
  const [bannerMain, setBannerMain] = useState([]);
  //banner-main
  useEffect(() => {
    const TYPE_SALE_BANNER = 2;
    BannerApiService.getBannerByType(TYPE_SALE_BANNER).then(res => {
      let banners = res.data;
      if (banners && banners.length == 3) {
        setBannerMain(banners)
      }
    })
  }, [])

  if (bannerMain.length) {
    return (<div className="d-flex w-100 justify-content-between mb-4 banner">
      {bannerMain.map(banner => {
        return(
          <Link key={banner.id} to={{pathname: `${banner.link}`}} target="_blank">
            {!isMobile ?
            <LazyLoad key={banner.id} height={200}>
              <img alt="banner_sale" src={banner.image} style={{'width' : '360px', 'height': '299.15px'}}></img>
            </LazyLoad> :
              <LazyLoad key={banner.id} height={200}>
                <img alt="banner_sale" src={banner.image_mobile ? banner.image_mobile : banner.image} style={{'width' : '360px', 'height': '299.15px'}}></img>
              </LazyLoad>
            }
        </Link>)
      })}
    </div>);
  }
  return null;
}
export default Index
