import React from "react";
import './sliderFooter.scss';
import $ from 'jquery';
import 'slick-carousel/slick/slick';

function SliderFooter({dataPartners}) {
  if (dataPartners.length > 1) {
    $(function () {
      $('.slider-footer').not('.slick-initialized').slick({
        dots: true,
        infinite: true,
        dotsClass: 'slick-dot-customer1 slick-dots slick-thumb',
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        arrows: false,
      })
    })
  }
    $(function () {
        $('.slider-footer.mobile .image-branch').not('.slick-initialized').slick({
            dots: true,
            infinite: true,
            dotsClass: 'slick-dot-customer1 slick-dots slick-thumb',
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay:true,
            arrows: false,
        })
    })
  return (
    <div className="slider-footer-wapper">
      <div className="title_slide_footer">
          <h1>Đối tác của {process.env.REACT_APP_NAME}</h1>
          <p>{process.env.REACT_APP_NAME} là công ty đầu tiên tại Việt Nam phát triển dịch vụ đặt thuốc giao ngay kèm dược sĩ tư vấn trực tuyến. {process.env.REACT_APP_NAME} luôn đồng hành cùng các đối tác và khách hàng theo tiêu chuẩn phục vụ cao cấp.</p>
      </div>
      <div className="slider-footer pc">
        {dataPartners.map((item, index) => {
          return (<ul key={index} className="image-branch">
            {item.map(partner => {
              return <li key={partner.id}>
                <img alt={partner.name} src={partner.image}></img>
              </li>
            })}
          </ul>)
        })}
      </div>
      <div className="slider-footer mobile">
        {dataPartners.map((item, index) => {
          return (<ul key={index} className="image-branch">
            {item.map(partner => {
              return <li key={partner.id}>
                <img alt={partner.name} src={partner.image}></img>
              </li>
            })}
          </ul>)
        })}
      </div>
    </div>
  )
}

export default SliderFooter
