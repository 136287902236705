import React, {useEffect, useState} from 'react';
import './branch.scss';
import $ from 'jquery';
import arrowLeft from '../../../assets/img/Vector 9.svg'
import arrhoverLeft from '../../../assets/img/Vector 9 copy.svg'
import arrowRight from '../../../assets/img/Vector 9 (1).svg'
import arrhoverRight from '../../../assets/img/Vector 9 (1) copy.svg'
import {ProductApiService} from "../../../ultils/EndPoint";
import {Link} from 'react-router-dom';
import 'slick-carousel/slick/slick';
import {useDispatch} from "react-redux";
import {actions} from "../../../actions/Brand";

function Brand({name}) {
  const dispatch = useDispatch()
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    ProductApiService.getBrands().then(res => {
      setBrands(res.data)
      dispatch(actions.setBrands(res.data))
    })
  }, [])
  $(function () {
    if (brands && brands.length) {
      $('.branch-carousel').not('.slick-initialized').slick({
        dots: false,
        // autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        prevArrow: '.arrowLeft',
        nextArrow: '.arrowRight',
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              initialSlide: 2,
              infinite: false,
              adaptiveHeight: true
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              initialSlide: 2,
              infinite: false,
              adaptiveHeight: true
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
              infinite: false,
              adaptiveHeight: true
            }
          }

        ]
      })
    }
  })

  return (
    <div className="container">
      <div className="row">
        <div className="branch-wapper">
          <div className="branch-title">
            <h2 style={{color: '#C6910F', textTransform:'uppercase'}}>{name}</h2>
            <div className="button_slick">
              <p className="arrow arrowLeft "><img alt="" className="arrow-1" src={arrowRight}></img> <img alt=""
                                                                                                           className="arrow-2"
                                                                                                           src={arrhoverRight}></img>
              </p>
              <p className="arrow arrowRight ml-2"><img alt="" className="arrow-3" src={arrowLeft}></img> <img alt=""
                                                                                                               className="arrow-4"
                                                                                                               src={arrhoverLeft}></img>
              </p>
            </div>
          </div>

          <div className="branch-carousel">
            {
              brands && brands.map(brand => {
                return <Link key={brand.id} to={"/thuong-hieu/" + brand.slug}><img src={brand.image}
                                                                    alt={brand.name}/></Link>
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Brand
