import React, {useEffect, useState} from 'react';
import BreadCrumb from "../components/common/breadcrumb/Breadcrumb";
import axios from 'axios';
import ENDPOINT from "../ultils/EndPoint";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import BannerFooter from "../components/common/banner-footer/BannerFooter";
import $ from 'jquery'

function TermServices() {
    const {slug} = useParams();
    const [termservices, setTerm] = useState([])

    useEffect(() => {
        const getTerm = async () => {
            try {
                const  res = await axios.get(`${ENDPOINT.TERM_SERVICES}/${slug}`)
                setTerm(res.data.data)
            } catch(error) {
                return;
            }
        }
        getTerm();
    }, [slug])
    $(function () {
        var imgDetail = $('.ql-align-center').find('img');
        var imgContent = imgDetail.innerWidth();
        console.log(imgDetail)
        if( 600 < imgContent < 3000) {
            imgDetail.addClass('auto')
        }
    })
    return (
        <div>
            <div className="container">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{termservices.meta_title}</title>
                    <meta name="description" content={termservices.meta_desc}/>
                    <meta name="keywords" content={termservices.meta_key}/>
                    <link rel="canonical" href="http://mysite.com/example" />
                </Helmet>
                <div className="row">
                    <BreadCrumb from={"Trang chủ"} to={termservices.title} toLink={termservices.title}/>
                    <div className="content_detail_news term_services w-100">
                        <h1 className="title_news">{termservices.title}</h1>
                        <div dangerouslySetInnerHTML={{__html: termservices.content}}></div>
                    </div>
                </div>
            </div>
            <BannerFooter/>
        </div>
    )
}

export default TermServices;
