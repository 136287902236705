import moment from "moment";

import $ from 'jquery';
export const formatCurrency = (value) => {
  if (value == 0) {
    return value;
  }
  let floatValue = parseFloat(value).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return floatValue.substring(0, floatValue.length - 2);
};

export const getDiscount = (product) => {
  let price = product.price;
  let discount = product.deal && product.deal.id ? finalPrice(product) : product.discount;
  return Math.round((price - discount) / price * 100)
};

export const chunkArray = (inputArray, perChunk) => {
  return inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/perChunk)

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

export const getPercentDiscount = (price, afterDiscount) => {
  return (100 - ((afterDiscount * 100)/price)).toFixed()
  // price : giá hiện tại
  // afterDiscount : giá đã giảm
}

export const getImageMedia = (medias) => {
  let child = medias.find(item => item.collection_name === 'single');
  if (child) {
    return child.image
  }
  return null;
}

export const formatDateTime = (time) => {
  return moment(time).format( 'HH:mm DD-MM-YYYY')
}

export const convertDateTime = (time, format) => {

  return moment(time).format(format)
}

export const formatDateNew = (time) => {
  return moment(time).format('DD/MM/YYYY')
}

export function objectToQueryParams(queryParams) {
  return queryParams ?
      Object.entries(queryParams).reduce((acc, [key, val], index) => {
        const sign = index === 0 ? '?' : '&';
        acc += `${sign}${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
        return acc;
      }, '')
      : '';
}

export const getJsonQueryParams = () => window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});

export const getJsonQueryParamsSearch = () => window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = e.split('=')[1], r), {});

export const  deleteValueEmptyFromObject = (object) => {
  Object.keys(object).map((value, index) => {
        if(object[value] === "undefined" || object[value] === ""){
          delete object[value];
        }
      }
  )
}


export const getQuerystring = (key) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == key) {
      return pair[1];
    }
  }
  if (key === ""){
    return query
  }

  return ""
}

export const appendQueryParamsAndRedirect = (key, value, history) => {
  const paramsObject = getJsonQueryParams()
  paramsObject[key] = value

  deleteValueEmptyFromObject(paramsObject)

  history.push({ search: objectToQueryParams(paramsObject) })
}

export const deleteQueryParamsAndRedirect = (key, history) => {
  const paramsObject = getJsonQueryParams()
  delete paramsObject[key]
  history.push({ search: objectToQueryParams(paramsObject) })
}


export const onNumberOnlyChange = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  const isValid = new RegExp("[0-9]").test(keyValue);
  if (!isValid) {
    event.preventDefault();
    return;
  }
};

export const handlePasteOnlyNumber = e => {
  if (!/[0-9]+/.test(e.clipboardData.getData('text'))) {
    e.preventDefault();
  }
};

export const handlePasteLimit = (e, limit) => {
  if (e.clipboardData.getData('text').length > limit) {
    e.preventDefault();
  }
};

export const validateLimitCharacters = (e, input, limit) => {
    if ($(input).val().length > limit){
      e.preventDefault();
    }
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone) => {
  var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
  return vnf_regex.test(phone);
}

export function onlyNumber(event, value) {
  if ((event.keyCode < 48 || event.keyCode > 57) || (!value && event.keyCode === 48) || value.length > 12) {
    event.preventDefault();
  }
}
export const removeExtraSpace = (s) => s.trim().split(/ + /).join(' ');

export function getInfoStatusOrder (statusSelect) {
  let listOrderStatus = [
    {status : 0, status_name : "Chưa xác nhận", status_css : "unconfirmer"},
    {status : 1, status_name : "Đã xác nhận", status_css : "confirmer"},
    {status : 2, status_name : "Đã hoàn thành", status_css : "success"},
    {status : 3, status_name : "Hủy bởi khách hàng", status_css : "cancel_client"},
    {status : 4, status_name : "Hủy bởi admin", status_css : "cancel_admin"},

  ]
  let res = listOrderStatus.filter(status => {
    if (statusSelect == status.status){
      return status
    }
  })
  return res[0]
}

export function getInfoStatusDelivery(statusSelect){
  let listOrderStatus = [
    {status : 0, status_name : "Chờ lấy hàng", status_css : "undelivery"},
    {status : 1, status_name : "Đang giao hàng", status_css : "waitdelivery"},
    {status : 2, status_name : "Thành công", status_css : "delivered"},
    {status : 3, status_name : "Thất bại", status_css : "fail"},
  ]
  let res = listOrderStatus.filter(status => {
    if (statusSelect == status.status){
      return status
    }
  })
  return res[0]
}

export function getSumObject(obj){
  let sum = 0;
  for(let property in obj){
    sum += obj[property];
  }
  return sum;
}

export function round(value, step) {
  step || (step = 1.0);
  var inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}

export function sumProp(items, prop) {
  if (items == null || items == '') {
    return 0
  }
  return items.reduce(function (a, b) {
    return b[prop] == null ? a : a + b[prop]
  }, 0)
}

export function finalPrice(product) {
  let discountDeal = product.deal && product.deal.type == 1 ? (product.discount - product.deal.value) : ((product.deal && product.deal.type == 2) ? Math.round(product.discount * (1 - product.deal.value/100)) : product.discount)
  return discountDeal > 0 ? discountDeal : 0;
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});