import {convertDateTime, formatCurrency, getInfoStatusDelivery, getInfoStatusOrder} from "../../../helpers/Function";
import React, {useEffect, useState} from "react";
import PurchaseEmpty from "./PurchaseEmpty";
import {Link, useHistory} from "react-router-dom";
import {SHIP_COD, SUCCESS, UNCONFIRMED} from "../../../helpers/constant";
import OrderView from "../../common/ViewOrder";
import {OrderClientApiService} from "../../../ultils/EndPoint";
import {Button, Modal} from "react-bootstrap";
import ENDPOINT from "../../../ultils/EndPoint";
import axios from "axios";

function ListOrder({listOrder}){
    const history = useHistory()
    const [showOrderView, setShowOrdeView] = useState(false);
    const [codeSelect, setCodeSelect] = useState("");
    const [idSelect, setIdSelect] = useState("");
    const [productSelect, setProductSelect] = useState([]);
    const [idForDelete, setIdForDelete] = useState('')
    const [idRepayment, setIdRepayment] = useState('')
    const [notFound, setNotFound] = useState(false)

    useEffect(() => {
        setNotFound(false)
        if (!listOrder){
            setNotFound(true)
        }
    })

    const getProductsForReview = async (id) => {
        await OrderClientApiService.getDetail(id).then(r => {
            if (r.status) {
                if (r.data.products.length > 0) {
                    setProductSelect([])
                    setProductSelect(r.data.products)
                } else {
                    setProductSelect([])
                }
            }
        })

    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setIdForDelete(e.target.id)
    };

    const [showRepayment, setShowRepayment] = useState(false);
    const handleCloseRePayment = () => setShowRepayment(false);
    const handleShowRePayment = (e) => {
        setShowRepayment(true)
        setIdRepayment(e)
    };

    const handlerePayment = (e) => {
        setRepaymentMethod(e);
    }

    const userAuthToken = localStorage.getItem('User-token');

    // delete user adress 
    const handleDeleteUserAdress = (id) => {
        const config = {
            headers: { Authorization: `Bearer ${userAuthToken}` }
        };
        axios.put(`${ENDPOINT.ORDERS}/${id}`, config)
        .then(function (response) {
           window.location.reload()
        })
        .catch(error => {
            console.log(error);
        }
        )
    }

    const [repaymentMethod, setRepaymentMethod] = useState('');
 
    const getPaymentMethod = (e) => {
        setRepaymentMethod(e.target.value)
    }

    // re-payment 
    const handleRePayment = (id) => {
        const rePayment = {
            call_back_url : process.env.REACT_APP_DOMAIN_HOST + `profile/purchase-order`,
            payment_method_id : parseInt(repaymentMethod)
        }

        const config = {
            headers: { Authorization: `Bearer ${userAuthToken}` }
        };

        axios.post(`${ENDPOINT.ORDERS_PAYMENT}/${id}`,rePayment, config)
        .then(function (response) {
            window.open(
                response.data.data.payment_url,
                '_blank'
            );
        })
        .catch(error => {
            console.log(error);
        }
        )
    }

    if (notFound) {
        return <PurchaseEmpty name={'Bạn chưa có đơn hàng nào'}/>
    }

    return (
        <>
            {
                listOrder && listOrder.map((order, key) => {
                        return <li key={key}>
                            <div className="d-flex justify-content-between">
                                <h5 style={{cursor: "pointer"}} onClick={() => {history.push('/profile/purchase-order/' + order.id)}} className="name_purchase">Đơn hàng #{order.code}</h5>
                            </div>
                            <span className="time">{convertDateTime(order.created_at, 'DD/MM/YYYY HH:mm')}  </span>
                            <ul className="pl-0 infomation_purchase">
                                <li className="row">
                                    <p className="col-12 col-sm-4">Giá trị đơn hàng</p>
                                    <h3>{formatCurrency(order.real_price)}đ</h3>
                                </li>
                                <li className="row">
                                    <p className="col-12 col-sm-12 col-md-12 col-lg-4">Phương thức thanh toán</p>
                                    <strong className="method-payment">{order.payment_method.name}</strong> {order.payment_status ? <strong className="paid">Đã thanh toán</strong> : <strong className="not-pay">Chưa thanh toán</strong>}
                                </li>
                                <li className="row">
                                    <p className="col-12 col-sm-4">Trạng thái đơn hàng / vận chuyển</p>
                                    {
                                        <a style={{cursor:'default'}} className={getInfoStatusOrder(order.status).status_css}>{getInfoStatusOrder(order.status).status_name}</a>
                                    }
                                    {
                                        <a style={{cursor:'default'}} className={getInfoStatusDelivery(order.delivery_status).status_css}>{getInfoStatusDelivery(order.delivery_status).status_name}</a>
                                    }
                                </li>
                                <li className="row" style={{paddingLeft: "15px"}}>
                                    {
                                        order.status == SUCCESS && order.can_review_order ? <a className="view_order" onClick={() => {setShowOrdeView(true); setIdSelect(order.id); setCodeSelect(order.code); getProductsForReview(order.id)}}>Đánh giá sản phẩm đã mua</a> : ''
                                    }
                                    {
                                        order.status == UNCONFIRMED ? <Link to="#" onClick={handleShow} id={order.id} className="cancel_order">Hủy đơn hàng</Link> : ''
                                    }
                                    {
                                        order.status == UNCONFIRMED && !order.payment_status && order.payment_method_id != SHIP_COD ? <Link to="#" onClick={() => {handleShowRePayment(order.id); handlerePayment(order.payment_method_id)}} className="pay_repeat">Thanh toán lại</Link> : ''
                                    }
                                </li>
                            </ul>
                        </li>
                    }) 
            }
            {
                productSelect.length > 0 ? <OrderView orderId={idSelect} orderCode={codeSelect} products={productSelect} show={showOrderView} close={() => setShowOrdeView(false)} onHide={() => setShowOrdeView(false)} /> : ''
            }

            <Modal className="modal_deleteAdressUser" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body  closeButton>
                    <p>Bạn có chắc chắn muốn huỷ đơn hàng này khỏi danh sách ?</p>               
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Không
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDeleteUserAdress(idForDelete)
                        handleClose()
                    }}>
                        Huỷ đơn hàng
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="" show={showRepayment} onHide={handleCloseRePayment}>
                <Modal.Header closeButton>
                    <h4>Chọn phương thức thanh toán lại </h4>     
                </Modal.Header>
                <Modal.Body  closeButton>
                    <select name="" id="" onChange={getPaymentMethod} value={repaymentMethod} className="form-control custom_select">
                        <option value="2">Thanh toán qua ví điện tử VNPAY</option>
                        <option value="3">Thanh toán qua ví điện tử MOMO</option>
                    </select>         
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseRePayment}>
                        Không
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleCloseRePayment()
                        handleRePayment(idRepayment)
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ListOrder;
