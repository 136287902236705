import { 
   GET_OTP,
   NOTI,
   CHECK_TIME_OTP
} from "../constans/ActionType";

const init = {
    otpRequest: [],
    noti:[],
    checkTime: []
}

export const CallOtpRequest = (state = init, {type, payload}) => {
    switch (type) {
        case GET_OTP:
            return {...state, otpRequest : payload}
        case NOTI:
        return {...state, noti : payload}
        case CHECK_TIME_OTP:
            return {...state, checkTime : payload}
        default:
            return state;
    }
}