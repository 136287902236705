import React, {useEffect, useState} from 'react';
import icon_filter from '../../../assets/img/Filter_alt_fill.png';
import {useHistory} from 'react-router-dom';
import $ from 'jquery';
import {
    appendQueryParamsAndRedirect,
    deleteQueryParamsAndRedirect,
    getQuerystring, handlePasteOnlyNumber,
    onNumberOnlyChange
} from "../../../helpers/Function";

function Filter(props) {
    const history = useHistory()
    const [disableFilterPrice, setDisableFilterPrice] = useState(true);
    const setCurrentPage = (page) => {
        appendQueryParamsAndRedirect('page', page , history)
    }

    useEffect(() => {
        checkPriceFilter()
    })

    const handleFilterPrice = () => {
        let price_min = document.getElementById("price_min").value
        let price_max = document.getElementById("price_max").value
        appendQueryParamsAndRedirect('min', price_min , history)
        appendQueryParamsAndRedirect('max', price_max, history)
        setCurrentPage(1)
    }

    const handleFilterBrand = (e) => {
        let valores = [];
        let checkboxes = document.querySelectorAll('input[type="checkbox"]:checked')
        checkboxes.forEach(input => {
            let valor = input?.defaultValue || input?.value;
            valores.push(valor);
        })

        let brandIdsString = valores.toString()
        if (brandIdsString){
            appendQueryParamsAndRedirect('brand_id', '[' + brandIdsString + ']' , history)
            setCurrentPage(1)

        }else{
            deleteQueryParamsAndRedirect("brand_id",history)
            setCurrentPage(1)
        }
    }
    const checkPriceFilter = () => {
        let priceMin = parseInt($('#price_min').val())
        let priceMax = parseInt($('#price_max').val())
        if ((priceMin && !priceMax) || (!priceMin && priceMax) || (priceMin && priceMax && priceMax > priceMin)){
            setDisableFilterPrice(false)
        } else{
            setDisableFilterPrice(true)
        }
    }

    const checkValidateOnPasteFilterPrice = (e) => {
        handlePasteOnlyNumber(e)
        checkPriceFilter()
    }



    const handleDisableFilter = () => {
        document.getElementById("price_min").value = ""
        document.getElementById("price_max").value = ""

        if (props.page === "search"){
            history.push(history.location.pathname + "?keyword=" + getQuerystring("keyword"))
        }else{
            history.push(history.location.pathname)
        }
    }

    let arrayBrandSelect = []
    if (getQuerystring('brand_id')) {
        arrayBrandSelect = JSON.parse(decodeURIComponent(getQuerystring('brand_id')))
    }
    $(function () {
        $('.filter_sort .title_filter_sort').off('click').on('click',function () {
            $(this).toggleClass('active')
            $(this).next().toggleClass('active')
        })
    })
    return (
        <div className="filter_sort">
            <div className="title_filter_sort">
                <img src={icon_filter} alt=""/>
                <p>Lọc sản phẩm</p>
            </div>
            <form action="" className="list_filter">
                <h5>Lọc theo thương hiệu</h5>
                <ul>
                    {props.brands.map((brand, key) => {
                        return (
                            <li key={key}>
                                <label htmlFor="" className="customer_checkbox">
                                    {arrayBrandSelect.includes(brand.id) ?
                                        <input checked className="filter-brand form-control" onChange={handleFilterBrand} type="checkbox" value={brand.id}/> :
                                        <input className="filter-brand form-control" onChange={handleFilterBrand} type="checkbox" value={brand.id}/>
                                    }
                                    <span className="checkmark"></span>
                                    {brand.name}
                                </label>
                            </li>
                        )
                    })}
                </ul>
                <div className="filter_from_to">
                    <h5>Lọc theo khoảng giá</h5>
                    <div className="d-flex justify-content-between">
                        <input type="text" onPaste={checkValidateOnPasteFilterPrice} onKeyPress={onNumberOnlyChange} onKeyUp={checkPriceFilter} defaultValue={getQuerystring("min")} id="price_min" placeholder="Từ" className="input_fill_price"/>
                        <input type="text" onPaste={checkValidateOnPasteFilterPrice} onKeyPress={onNumberOnlyChange} onKeyUp={checkPriceFilter} defaultValue={getQuerystring("max")} id="price_max" placeholder="Đến" className="input_fill_price"/>
                    </div>
                </div>
                <div className="button_action_filter">
                    <button type="button" disabled={disableFilterPrice} className="btn btn-apply" onClick={handleFilterPrice}>Áp dụng</button>
                    <button type="button" className="btn btn-cancel" onClick={handleDisableFilter}>Bỏ lọc tất cả</button>
                </div>
            </form>
        </div>
    )
}

export default Filter;
