import React, {useEffect, useState} from 'react';
import './product.scss';
import {Pagination} from "antd";
import Index from '../../common/product-item/index'
import {useHistory} from "react-router-dom";
import {appendQueryParamsAndRedirect, getQuerystring} from "../../../helpers/Function";
import ProductView from "../product_view/Product";
import ProductSameCategory from "../product_same_category/ProductSameCategory";
import ENDPOINT, {ApiService} from "../../../ultils/EndPoint";
import {DEFAULT_LIMIT_SEARCH} from "../../../helpers/constant";
function Product(props) {
    let dataPaginate = props.dataPaginate

    const tokenUser = localStorage.getItem('User-token')
    const [seenProducts, setSeenProducts] = useState([]);


    useEffect(()=>{
        if (tokenUser){
            ApiService.get('client/users/product-seen/list', {}, tokenUser).then(r => {
                if (r.status && r.data != null){
                    setSeenProducts(r.data)
                }
            })
        }

    },[])
    const history = useHistory()
    let keyword = decodeURIComponent(getQuerystring("keyword"))
    const setCurrentPage = (page) => {
        appendQueryParamsAndRedirect('page', page , history)
    }

    const handleSelectedSort = (e) => {
        appendQueryParamsAndRedirect('order_by', e.target.value, history)
        setCurrentPage(1)
    }


    return (
        <>
            <div className="product" id="to_header">
                <div className="header-productTitle d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                        <div className="title_header">
                            { props.page == "search" ? <h2 className="mb-0">Kết quả tìm kiếm</h2> : <h2 className="mb-0">{props.name}</h2> }
                            {
                                props.page == "search" ? (keyword ?
                                    <p>Tìm thấy <span style={{'fontWeight':'bolder'}}>{dataPaginate.totalRecord}</span> kết quả của từ <span style={{'fontWeight':'bolder'}}>{keyword}</span></p>
                                    : <p>Tìm thấy "<span style={{'fontWeight':'bolder'}}>{dataPaginate.totalRecord}</span>" kết quả</p>) :  (dataPaginate.totalRecord != 0 ? <p>Hiển thị {dataPaginate.perPage * (dataPaginate.currentPage - 1) + 1} - {dataPaginate.perPage * dataPaginate.currentPage < dataPaginate.totalRecord ? dataPaginate.perPage * dataPaginate.currentPage : dataPaginate.totalRecord} trong số {dataPaginate.totalRecord} Sản
                                    phẩm</p> : <p>Không có sản phẩm nào</p>)
                            }
                        </div>
                        { props.deal ?
                            <div id="timer" className="align-self-center" style={{paddingTop : 10+ 'px', minWidth: 250 + 'px'}}>
                                <p className="mr-2">Kết thúc trong </p>
                                <p id="days"></p>
                                <p id="hours"></p>
                                <p id="minutes"></p>
                                <p id="seconds"></p>
                            </div> : ''
                            }
                    </div>
                    {dataPaginate.totalRecord != 0 ?
                    <select value={decodeURIComponent(getQuerystring("order_by"))} name="order_by" id="" className="custom_select form-control" onChange={handleSelectedSort}>
                        <option value="product_sell desc">Sản phẩm bán chạy</option>
                        <option value="discount asc">Giá tăng dần</option>
                        <option value="discount desc">Giá giảm dần</option>
                        <option value="name asc">Xếp thứ tự A-Z</option>
                        <option value="name desc">Xếp thứ tự Z-A</option>
                    </select>
                      : null }
                </div>
                <div className="parent">
                    <div className="grid grid-4 grid-gap-10">
                        {props.products && props.products.map((product, key) => {
                            return (
                                <Index product={product} key={key}/>
                            )
                        })}
                    </div>
                    {dataPaginate.totalRecord <= dataPaginate.perPage ? '' :
                        <Pagination
                            defaultCurrent={dataPaginate.currentPage}
                            onChange={(value) => {document.getElementById('to_header').scrollIntoView({behavior: 'smooth'}); setCurrentPage(value) }}
                            total={dataPaginate.totalRecord}
                            current={dataPaginate.currentPage}
                            defaultPageSize={DEFAULT_LIMIT_SEARCH}
                            showSizeChanger= {false}
                        />
                    }
                </div>
            </div>
            {tokenUser ? <ProductSameCategory name={"Sản phẩm đã xem"} dataSameCategory={seenProducts} /> : ''}
        </>
)
}
export default Product;

