import React from 'react';
import { useHistory } from 'react-router';

function BannerProduct(props) {
    const history = useHistory();

    function redirectPage() {
        if(props.deal) {
            return history.push("/deal/" + props.slug)
        }
        return history.push("/group/" + props.slug)
    }

    return (
        <div onClick={props.check === true ? null : redirectPage} style={{cursor: (props.check === true ? 'default' : 'pointer')}} className="banner_product w-100">
            {props.cover ? <img alt="img-group" src={props.cover}></img> : ''} 
        </div>
    )
}

export default BannerProduct;
