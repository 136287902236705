import React, {useEffect, useState} from 'react'
import BreadCrumb from "../components/common/breadcrumb/Breadcrumb";
import imgCartBuy from '../assets/img/cart/Group.png';
import '../assets/scss/cart.scss';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import $ from 'jquery';
import {
    formatCurrency,
    getQuerystring,
    onNumberOnlyChange,
    validateEmail,
    validateLimitCharacters,
    getImageMedia, handlePasteLimit, validatePhone, convertDateTime
} from "../helpers/Function";
import {
    AddressApiService,
    CartApiService,
    CityApiService, CouponApiService,
    DistrictApiService,
    OrderApiService,
    ShippingFee, WardApiService
} from "../ultils/EndPoint";
import {SHIP_COD} from "../helpers/constant";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {GetAllDataCart} from "../actions/Cart";
import {websiteActions} from "../actions";
import {Button} from "react-bootstrap";

function Payment() {
    const dispatch = useDispatch()

    const [showVoucher, setShowVoucher] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [productPayment, setProductPayment] = useState([]);
    const [priceProvisional, setPriceProvisional] = useState(0);
    const [discountProduct, setDiscountProduct] = useState(0);
    const [listAddress, setListAddress] = useState([]);
    const [shippingFee, setShippingFee] = useState(0);
    const [addressSelected, setAddressSelected] = useState('');
    const [note, setNote] = useState('');
    const [methodPayment, setMethodPayment] = useState(1);
    const [exportReceipt, setExportReceipt] = useState(false);
    const [nameCompany, setNameCompany] = useState('');
    const [emailCompany, setEmailCompany] = useState('');
    const [addressCompany, setAddressCompany] = useState('');
    const [taxCode, setTaxCode] = useState('');

    const [listCities, setListCities] = useState([])
    const [citySelected, setCitySelected] = useState(0)
    const [listDistrict, setListDistrict] = useState([])
    const [districtSelected, setDistrictSelected] = useState(0)
    const [listWard, setListWard] = useState([])
    const [wardSelected, setWardSelected] = useState(0)
    const [isAddressDefault, setIsAddressDefault] = useState(0)
    const [couponList, setCouponList] = useState([])
    const [couponListNotUse, setCouponListNotUse] = useState([])
    const [couponId, setCouponId] = useState(null)
    const [couponValue, setCouponValue] = useState(0)
    const [couponCode, setCouponCode] = useState('')
    const [isSubmitPayment, setIsSubmitPayment] = useState(true)
    const [showStatus, setShowStatus] = useState(false);
    const [message, setMessage] = useState(false);
    const handleCloseStatus = () => {
        setShowStatus(false)
    };
    const showAlert = (message) => {
        setMessage(message);
        setShowStatus(true);
    }

    const history = useHistory()

    const handleCloseNewAddress = () => {
        setShowAddress(false)
    };
    const applyCoupon = (item) => {
        if (item.unit == 1) {
            setCouponValue(Math.round(item.value / 100 * priceProvisional))
        } else {
            setCouponValue(item.value)
        }
        setCouponId(item.id)

        setCouponCode(item.code)
        setShowVoucher(false)
    };
    const removeCoupon = () => {
        setCouponId(null)
        setCouponValue(0)
        setCouponCode('')
        setShowVoucher(false)
    };
    const handleKeyword = (e) => {
        getCouponList(priceProvisional, e.target.value)
    }

    useEffect(() =>{
        // dispatch(websiteActions.setChat(false))
        // dispatch(websiteActions.setFooter(false))
        let productsIdSelect = getQuerystring("payment-products")
        CartApiService.getCart({product_ids : productsIdSelect}).then(r => {
            if (r.status){
                let products = r.data.products.filter(product =>
                    product.selected === true
                )
                let isBuy = true;
                for (let i = 0; i < products.length; i++) {
                    let product = products[i]
                    if (!product) {
                        isBuy = false;
                        break;
                    }
                    if (!product.is_available) {
                        isBuy = false;
                        break;
                    }
                }
                if (!isBuy) {
                    showAlert('Có sản phẩm không thể đặt hàng!')
                    history.push("/cart")
                    return;
                }
                setProductPayment(products)

                let total = 0;
                let discount = 0;
                products.map(product => {
                    total += product.origin_price
                    discount += product.coupon_value
                })

                setPriceProvisional(total)
                setDiscountProduct(discount)
                getCouponList(total)
            }else{
                history.push("/cart")
            }
        })


        AddressApiService.getList().then(r => {
            if (r.status){
                setListAddress(r.data)
                    if(r.data.length){
                        r.data.map(r => {
                            if (r.default === 1){
                                setAddressSelected(r.id)
                                getShippingFee(r)
                            }
                        })

                        let isNotSetDefault = r.data.every(item => {
                            return item.default === 0;
                        })
                        if (isNotSetDefault){
                            setAddressSelected(r.data[0].id)
                            getShippingFee(r.data[0])
                        }
                    }


            }
        })

        CityApiService.getListCity().then(r => {
            if (r.status){
                setListCities(r.data)
            }
        })
    }, [])

    function getShippingFee(data){
        ShippingFee.getShippingFee(data.city_id, data.district_id).then(r => {
            if (r.status) {
                setShippingFee(r.data)
            }
        })
    }

    function getCouponList(total, keyword = ''){
        CouponApiService.getByOrder({code: keyword.trim()}).then(res => {
            if (res.status && res.data && res.data.length) {
                let now = new Date()
                let dataUsed = res.data.filter(item => {
                    let startTime = new Date(item.start_time)
                    let endTime = new Date(item.end_time)
                    if (item.min_order_value <= total && (startTime <= now && endTime >= now) && item.number_of_use > 0) {
                        return item;
                    }
                })
                let dataNotUsed = res.data.filter(item => {
                    let startTime = new Date(item.start_time)
                    let endTime = new Date(item.end_time)
                    if (item.min_order_value > total || now < startTime || now > endTime) {
                        return item;
                    }
                })
                setCouponList(dataUsed)
                setCouponListNotUse(dataNotUsed)
            } else {
                setCouponList([])
                setCouponListNotUse([])
            }
        })
    }

    function changeMethodPayment(option) {
        setMethodPayment(option)
    }

    function changeNote(value){
        setNote(value)
    }

    const getTotalPriceFinal = () => {
        return priceProvisional + shippingFee - couponValue - discountProduct
    }

    function getTotalPointFinal(){
        let point = 0
        productPayment.map(product => {
            if (product.product_point > 0) {
                point += product.product_point * product.quantity
            }
        })
        return point
    }

    function checkAllGiftExchangePoint(){
        let isAll = true
        productPayment.forEach(product => {
            if (product.product_point <= 0) {
                isAll = false
                return
            }
        })
        return isAll
    }

    const changeAddressDelivery = (e) => {
        setAddressSelected(e.target.value)
        let address = listAddress.filter(address => {
            if (address.id == e.target.value) {
                return address
            }
        })
        if (address.length){
            getShippingFee(address[0])
        }
    }

    const getInfoAddressSelected = () => {
        return listAddress.filter(address => {
            if (address.id == addressSelected) {
                return address
            }
        })
    }

    const handlePayment = () => {
        setIsSubmitPayment(false)
        let dataOrder = {}

        if (exportReceipt){
            dataOrder.name_company = nameCompany.trim()
            dataOrder.email_company = emailCompany.trim()
            dataOrder.address_company = addressCompany.trim()
            dataOrder.tax_code = taxCode.trim()

            if (!nameCompany){
                $('.error_name_company').html("Vui lòng nhập tên công ty")
            }else{
                $('.error_name_company').html("")
            }

            if (!emailCompany){
                $('.error_email_company').html("Vui lòng nhập email công ty")
            }

            if (emailCompany && !validateEmail(emailCompany)){
                $('.error_email_company').html("Email công ty không hợp lệ")
            }

            if (emailCompany && validateEmail(emailCompany)){
                $('.error_email_company').html("")
            }

            if (!addressCompany){
                $('.error_address_company').html("Vui lòng nhập địa chỉ công ty")
            }else{
                $('.error_address_company').html("")
            }

            if (!taxCode){
                $('.error_tax_code').html("Vui lòng nhập mã số thuế")
            }else{
                $('.error_tax_code').html("")
            }

            if (!nameCompany || !emailCompany || !addressCompany || !taxCode || (emailCompany && !validateEmail(emailCompany))){
                return
            }
        }

        let dataRequestProductPayment = []
        productPayment.map(product => {
            dataRequestProductPayment.push({product_id : product.id})
        })

        dataOrder.note = note
        dataOrder.methodPayment = methodPayment
        dataOrder.products = dataRequestProductPayment
        let address = getInfoAddressSelected()
        if (address.length){
            dataOrder.address = address[0].address
            dataOrder.city_name = address[0].city_name
            dataOrder.district_name = address[0].district_name
            dataOrder.ward_name = address[0].ward_name
            dataOrder.phone = address[0].phone
            dataOrder.name = address[0].name
        }else{
            showAlert('Chưa chọn địa chỉ giao hàng')
            setIsSubmitPayment(true)
            return
        }
        dataOrder.shipping_fee = shippingFee
        dataOrder.call_back_url = process.env.REACT_APP_DOMAIN_HOST + "profile/purchase-order"
        dataOrder.payment_method_id = methodPayment
        dataOrder.is_export = exportReceipt
        dataOrder.coupon_id = couponId


        OrderApiService.create(dataOrder).then(async r => {
            if (r.status) {
                await CartApiService.getCart().then(function (response) {
                    if (response.data) {
                        dispatch(GetAllDataCart(response.data));
                    } else {
                        dispatch(GetAllDataCart([]));
                    }
                })

                localStorage.setItem('create_order_success', 'done');
                if (dataOrder.payment_method_id !== SHIP_COD) {
                    localStorage.setItem("isBackFromPaymentCart", 'true')
                    window.location = r.data.payment_url
                } else {
                    history.push("profile/purchase-order/" + r.data.order_id)
                }
            } else {
                history.push("/cart")
            }
        })

    }

    const changeCitySelected = (e) => {
        setCitySelected(e.target.value)
    }

    const changeDistrictSelected = (e) => {
        setDistrictSelected(e.target.value)
    }

    const changeWardSelected = (e) => {
        setWardSelected(e.target.value)
    }

    useEffect(() =>{
        WardApiService.getListWardByDistrictId(districtSelected).then(r => {
            if (r.status){
                setListWard(r.data)
                if (r.data.length){
                    setWardSelected(r.data[0].id)
                }
            }
        })
    }, [districtSelected])

    let allQuery = getQuerystring("")
    useEffect(() => {
        if (localStorage.getItem("isBackFromPaymentCart") == 'true'){
            localStorage.setItem("isBackFromPaymentCart", 'false')
            window.location.href = '/thanh-toan?' + allQuery
        }
    }, [allQuery])

    useEffect(() =>{
        if (citySelected > 0) {
            DistrictApiService.getListDistrictByCityId(citySelected).then(r => {
                if (r.status){
                    setListDistrict(r.data)
                    setDistrictSelected(r.data[0].id)
                    if (r.data.length){
                        WardApiService.getListWardByDistrictId(r.data[0].id).then(r => {
                            if (r.status){
                                setListWard(r.data)
                                setWardSelected(r.data[0].id)
                            }
                        })
                    }
                }
            })
        }else{
            setListDistrict([])
            setDistrictSelected(0)
            setListWard([])
            setWardSelected(0)
        }

    }, [citySelected])

    const handleBtnAddressDefault = () => {
        isAddressDefault == 0 ? setIsAddressDefault(1) : setIsAddressDefault(0)
    }

    const handleAddNewAddress = (e) => {
        let name = $('#name_add_address').val()
        let phone = $('#phone_add_address').val()
        let address = $('#address_add_address').val()

        if (!name){
            $('.error_name_add_address').html("Không được để trống")
        }else{
            $('.error_name_add_address').html("")
        }
        if (!phone){
            $('.error_phone_add_address').html("Không được để trống")
        }
        if (phone && !validatePhone(phone)){
            $('.error_phone_add_address').html("Số điện thoại không hợp lệ")
        }
        if (phone && validatePhone(phone)){
            $('.error_phone_add_address').html("")
        }

        if (!address){
            $('.error_address_add_address').html("Không được để trống")
        }else{
            $('.error_address_add_address').html("")
        }

        if (!citySelected || citySelected == "0"){
            $('.error_city_add_address').html("Không được để trống")
        }else{
            $('.error_city_add_address').html("")
        }

        if (!districtSelected){
            $('.error_district_add_address').html("Không được để trống")
        }else{
            $('.error_district_add_address').html("")
        }

        if (!wardSelected){
            $('.error_ward_add_address').html("Không được để trống")
        }else{
            $('.error_ward_add_address').html("")
        }

        if (name && phone && address && validatePhone(phone) && citySelected && districtSelected && wardSelected){
            let data = {}
            data.name = name
            data.phone = phone
            data.address = address

            let city = listCities.filter(city => {
                if (city.viet_nam_zone_id == citySelected) {
                    return city
                }
            })
            if (city.length){
                data.city_id = city[0].id
            }
            data.district_id = parseInt(districtSelected)
            data.ward_id = parseInt(wardSelected)
            if (isAddressDefault == 1) {
                data.default = 1
            }

            AddressApiService.store(data).then(r => {
                if (r.status){
                    setShowAddress(false)
                    AddressApiService.getList().then(r => {
                        if (r.status){
                            setListAddress(r.data)
                        }
                    })
                    setAddressSelected(r.data.id)
                    getShippingFee(r.data)
                    setCitySelected(0)
                    setDistrictSelected(0)
                    setWardSelected(0)
                    setIsAddressDefault(0)
                }
            })
        }
    }


    useEffect(() => {
        $('input[name="inputForName"]').keypress
        (
            function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {
                    event.preventDefault();
                }
                else
                {
                    if (event.keyCode < 48 || event.keyCode > 57 )
                    {

                    }else {
                        event.preventDefault();
                    }
                }
            }
        );
    })

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
          
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
     }

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="carts w-100">
                        <BreadCrumb from={'Trang chủ'} to={'Thanh toán'}/>
                        <div className="product mt-12">
                            <div className="header-productTitle d-flex align-items-center justify-content-between w-100">
                                <div className="title_header">
                                    <h2 className="mb-0">Thanh toán</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="address_customer">
                                        <div className="d-flex justify-content-between title_address">
                                            <h5>Địa chỉ giao hàng</h5>
                                            <a onClick={() => setShowAddress(true)} >Thêm địa chỉ</a>
                                            <Modal show={showAddress} onHide={handleCloseNewAddress} className="modal-login modal-address" >
                                                <Modal.Header closeButton  className="grid">
                                                    <Modal.Title>Thêm địa chỉ mới</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body className="mt-3">
                                                    <Form>
                                                         <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6">
                                                                    <label >Họ và tên <span className="red">*</span></label>
                                                                    <input onKeyPress={(e) => {validateLimitCharacters(e, '#name_add_address', 255)}} onPaste={(e) => {handlePasteLimit(e, 255)}} name="inputForName" id="name_add_address" type="text" className="form-control"/>
                                                                    <span className="error_name_add_address" style={{color : "red", fontSize: "12px"}}></span>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <label >Số điện thoại <span className="red">*</span></label>
                                                                    <input onKeyPress={onNumberOnlyChange} id="phone_add_address" type="text"  className="form-control"/>
                                                                    <span className="error_phone_add_address" style={{color : "red", fontSize: "12px"}}></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12">
                                                                    <label >Địa chỉ <span className="red">*</span></label>
                                                                    <input onKeyPress={(e) => {validateLimitCharacters(e, '#address_add_address', 255)}} id="address_add_address" type="text"  className="form-control"/>
                                                                    <span className="error_address_add_address" style={{color : "red", fontSize: "12px"}}></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6">
                                                                    <label >Tỉnh / thành phố <span className="red">*</span></label>
                                                                    <select value={citySelected} onChange={changeCitySelected} name="" id="" className="form-control custom_select">
                                                                        <option value="0">Lựa chọn tỉnh/thành phố</option>
                                                                        {listCities.length && listCities.map((city, index) =>
                                                                            <option key={index} value={city.viet_nam_zone_id}>{city.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <span className="error_city_add_address" style={{color : "red", fontSize: "12px"}}></span>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <label >Quận / huyện <span className="red">*</span></label>
                                                                    <select value={districtSelected} name="" id="" onChange={changeDistrictSelected} className="form-control custom_select">
                                                                        {listDistrict.length && listDistrict.map((district, index) =>
                                                                            <option key={index} value={district.id}>{district.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <span className="error_district_add_address" style={{color : "red", fontSize: "12px"}}></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6">
                                                                    <label >Phường / xã<span className="red">*</span></label>
                                                                    <select value={wardSelected} name="" id="" onChange={changeWardSelected} className="form-control custom_select">
                                                                        {listWard.length && listWard.map((ward, index) =>
                                                                            <option key={index} value={ward.id}>{ward.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <span className="error_ward_add_address" style={{color : "red", fontSize: "12px"}}></span>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <label  style={{ 'opacity': '0'}}>default</label>
                                                                    <div className="d-flex">
                                                                        <input onChange={handleBtnAddressDefault} type="checkbox" checked={isAddressDefault}/>
                                                                        <span >Đặt làm địa chỉ mặc định</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-0 mt-40">
                                                            <button onClick={handleAddNewAddress} type="button" className='btn btn-address'>Thêm địa chỉ</button>
                                                        </div>
                                                    </Form>
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                        <div className="form-group title_address mt-24">
                                            <select value={addressSelected} onChange={(e) => {
                                                changeAddressDelivery(e)
                                            }} name="address" id="" className="form-control">
                                                {listAddress.length && listAddress.map((address, index) => {
                                                    return (
                                                        <option key={index} value={address.id}>{address.name + ' - ' + address.phone + ' - ' + titleCase(address.address) + " , " + titleCase(address.ward_name) + " , " + titleCase(address.district_name) + " , " + titleCase(address.city_name)}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>

                                        <div className="form-group title_address mt-24">
                                            <h5>Ghi chú</h5>
                                            <textarea onChange={(e) => {
                                                changeNote(e.target.value)
                                            }} onKeyPress={(e) => {
                                                validateLimitCharacters(e, '#note_payment', 255)
                                            }} onPaste={(e) => {
                                                handlePasteLimit(e, 255)
                                            }} name="note" id="note_payment" cols="30" rows="2" className="form-control" placeholder="Nhập ghi chú ở đây"></textarea>
                                        </div>
                                        <div hidden={checkAllGiftExchangePoint()} className="form-group title_address mt-24">
                                            <h5>Phương thức thanh toán</h5>
                                            <div className="d-flex">
                                                <input checked={methodPayment === 1} onClick={() => {
                                                    changeMethodPayment(1)
                                                }} type="radio" name="payment"/>
                                                <label htmlFor="checkbox">Thanh toán khi nhận hàng</label>
                                            </div>
                                            <div className="d-flex">
                                                <input checked={methodPayment === 2} onClick={() => {
                                                    changeMethodPayment(2)
                                                }} type="radio" name="payment"/>
                                                <label htmlFor="checkbox">Thanh toán qua ví điện tử VNPAY</label>
                                            </div>
                                            <div className="d-flex">
                                                <input checked={methodPayment === 3} onClick={() => {
                                                    changeMethodPayment(3)
                                                }} type="radio" name="payment"/>
                                                <label htmlFor="checkbox">Thanh toán qua ví điện tử MOMO</label>
                                            </div>
                                        </div>

                                        <div className="form-group title_address mt-24">
                                            <h5>HÓa đơn mua hàng</h5>
                                            <div  className="d-flex">
                                                <input onChange={() => {
                                                    setExportReceipt(!exportReceipt)
                                                }} type="checkbox" name="option_buy" />
                                                <label htmlFor="checkbox">Yêu cầu xuất hóa đơn</label>
                                                <div className="grid note_payment">
                                                    <p className="mb-0">*Lưu ý: {process.env.REACT_APP_NAME} chỉ xuất hóa đơn điện tử</p>
                                                </div>
                                            </div>
                                        </div>
                                        {exportReceipt && (<div  className="form-group title_address pt-24">
                                            <h5 className="">Thông tin hóa đơn:</h5>
                                            <div className="form_bill">
                                                <div className="row">
                                                    <p  className="col-12 col-sm-4">Tên công ty <span className="red">*</span></p>
                                                    <div className="col-12 col-sm-8">
                                                        <input onChange={(e) => {setNameCompany(e.target.value)}} type="text" className="form-control " placeholder="Nhập tên công ty"/>
                                                        <span style={{color : 'red', fontSize: '12px'}} className="error_name_company"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <p  className="col-12 col-sm-4">Mã số thuế <span className="red">*</span></p>
                                                    <div className="col-12 col-sm-8">
                                                        <input onKeyPress={onNumberOnlyChange} onChange={(e) => {setTaxCode(e.target.value)}} type="text" className="form-control "  placeholder="Nhập mã số thuế"/>
                                                        <span style={{color : 'red', fontSize: '12px'}} className="error_tax_code"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <p  className="col-12 col-sm-4">Địa chỉ công ty<span className="red">*</span></p>
                                                    <div className="col-12 col-sm-8">
                                                        <input onKeyPress={(e) => {
                                                            validateLimitCharacters(e, '#address_company', 255)
                                                        }} onChange={(e) => {setAddressCompany(e.target.value)}} type="text" id="address_company" className="form-control "  placeholder="Nhập địa chỉ công ty"/>
                                                        <span style={{color : 'red', fontSize: '12px'}} className="error_address_company"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <p  className="col-12 col-sm-4">Email <span className="red">*</span></p>
                                                    <div className="col-12 col-sm-8">
                                                        <input onChange={(e) => {setEmailCompany(e.target.value)}} type="text" className="form-control "  placeholder="Nhập Email"/>
                                                        <span style={{color : 'red', fontSize: '12px'}} className="error_email_company"/>
                                                    </div>
                                                </div>
                                                <div className="note_bill">
                                                    <p>Lưu ý:</p>
                                                    <ul>
                                                        <li>
                                                            Lưu ý:
                                                            Hóa đơn cho sản phẩm của các nhà cung cấp khác {process.env.REACT_APP_NAME} Trading sẽ được xuất trong 14 ngày kể từ thời điểm quý khách nhận hàng.</li>
                                                        <li>
                                                            Với sản phẩm thuộc nhà cung cấp {process.env.REACT_APP_NAME} Trading, nếu quý khách không điền thông tin hóa đơn, {process.env.REACT_APP_NAME} sẽ xuất hóa đơn điện tử theo thông tin mua hàng.
                                                        </li>
                                                        <li>
                                                            Các mặt hàng sau đây trong đơn hàng của bạn không được hỗ trợ xuất hoá đơn
                                                        </li>
                                                        <li>
                                                            {process.env.REACT_APP_NAME} từ chối xử lý các yêu cầu phát sinh trong việc kê khai thuế đối với những hóa đơn từ 20 triệu đồng trở lên thanh toán bằng tiền mặt
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>)}

                                    </div>
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="address_customer pl-0 pr-0">
                                        <div className="d-flex justify-content-between title_address p-16">
                                            <h5>Thông tin đơn hàng</h5>
                                        </div>
                                        <ul className="list_product_cart pc">
                                            {productPayment.length && productPayment.map((product, key) => {
                                                return (
                                                    <li className="d-flex" key={key}>
                                                        <div className="image_zone">
                                                            <img src={getImageMedia(product.image)} alt={product.name} />
                                                        </div>
                                                        <div className="grid">
                                                            <h5>{product.name}</h5>
                                                            <div className="d-flex price">
                                                                {
                                                                    product.product_point ? <h2>{product.product_point} điểm</h2> : <><h2 style={{color: '#F53C45'}}>{formatCurrency(product.product_price)}đ</h2><h4 style={{color: '#F53C45'}}>{product.product_price !== product.product_real_price ? formatCurrency(product.product_real_price)+ 'đ' : ''}</h4></>
                                                                }
                                                            </div>
                                                            {product.coupon_value !== 0 ? <div className="vourcher">
                                                                <span>Giảm {formatCurrency(product.coupon_value)}đ</span><p>mã giảm giá {product.coupon_code}</p>
                                                            </div> : ''}
                                                        </div>
                                                        <span className="amount">{product.quantity}</span>
                                                        <p className="price">
                                                            { product.product_point ?  (product.product_point * product.quantity) + ' điểm' : formatCurrency(product.origin_price)+ 'đ' }
                                                        </p>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        <ul className="list_product_cart mobile">
                                            {productPayment.length && productPayment.map((product, key) => {
                                                return (
                                                    <li className="d-flex" key={key}>
                                                        <div className="image_zone">
                                                            <img src={getImageMedia(product.image)} alt={product.name} />
                                                        </div>
                                                        <div className="grid">
                                                            <h5>{product.name}</h5>
                                                            <div className="d-flex price">
                                                                {
                                                                    product.product_point ? <h2>{product.product_point} điểm</h2> : <><h2 style={{color: '#F53C45'}}>{formatCurrency(product.product_price)}đ</h2><h4 style={{color: '#F53C45'}}>{product.product_price !== product.product_real_price ? formatCurrency(product.product_real_price)+ 'đ' : ''}</h4></>
                                                                }
                                                                <span className="amount" style={{ 'marginTop' : ' -10px'}}>x {product.quantity}</span>
                                                            </div>
                                                            {product.coupon_value !== 0 ? <div className="vourcher">
                                                                <span>Giảm {formatCurrency(product.coupon_value)}đ</span><p>mã giảm giá {product.coupon_code}</p>
                                                            </div> : ''}

                                                        </div>

                                                        <p className="price">
                                                            { product.product_point ?  (product.product_point * product.quantity) + ' điểm' : formatCurrency(product.origin_price)+ 'đ' }
                                                        </p>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        <ul className="total_price_payment">
                                            <li>
                                                <label >Tạm tính</label>
                                                <p>{formatCurrency(priceProvisional)}đ</p>
                                            </li>
                                            <li>
                                                <label >Giảm giá sản phẩm</label>
                                                <p>{formatCurrency(discountProduct)}đ</p>
                                            </li>
                                            <li hidden={checkAllGiftExchangePoint()}>
                                                <label >Voucher đơn hàng</label>
                                                {couponId ? <p className="coupon_red">{formatCurrency(couponValue)}đ</p> : <Link to="#" onClick={() => setShowVoucher(true)} >Chọn mã</Link>}
                                            </li>
                                            {couponId ?
                                              <li>
                                                  <label></label>
                                                  <p>Áp dụng mã giảm giá { couponCode } <Link to="#" onClick={() => setShowVoucher(true)} >Thay đổi</Link></p>
                                              </li>: null}

                                            <li>
                                                <label>Phí vận chuyển</label>
                                                <p>{formatCurrency(shippingFee)}đ</p>
                                            </li>
                                            <li>
                                                <h2>Giá trị đơn hàng</h2>
                                                <h3>{formatCurrency(getTotalPriceFinal())}đ</h3>
                                            </li>
                                            <li>
                                                <h2></h2>
                                                <h3>{getTotalPointFinal() > 0 ? getTotalPointFinal() + ' điểm' : ''}</h3>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button_action_buy">
                <div className="container">
                    <div className="row">
                        <div className="d-flex w-100 justify-content-between">
                            <button onClick={() => {history.push("/cart")}} type='button' className="button_buy_continue">
                                <img src={imgCartBuy} alt=""/>
                                <span>Quay lại giỏ hàng</span>
                            </button>
                            {/*<button disabled={!isSubmitPayment} type="button" className="button_buy" onClick={() => handlePayment()} >{ methodPayment == 1 ? 'Đặt hàng' : 'Thanh toán'}</button>*/}
                            <button type="button" className="button_buy" onClick={() => handlePayment()} >{ methodPayment == 1 ? 'Đặt hàng' : 'Thanh toán'}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Modal show={showVoucher} onHide={() => setShowVoucher(false)} className="modal-login modal-voucher" >
                    <Modal.Header closeButton  className="grid">
                        <Modal.Title>Chọn mã giảm giá</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="mt-3">
                        <Form>
                            <div className="form-group d-flex">
                                <div className="grid">
                                    <input type="text" className="form-control" placeholder="Nhập mã giảm giá" onChange={handleKeyword}/>
                                    {/*<span className="validate_msg">Mã giảm giá không tồn tại</span>*/}
                                    {(couponList.length + couponListNotUse.length) == 0 ?
                                      <p className="not_voucher">Bạn chưa có mã giảm giá. Hãy quay lại sau nhé</p>
                                    : null}
                                </div>
                            </div>
                            <ul className="list_voucher">
                                { couponList.length ? couponList.map(item => {
                                    return <li key={item.id}>
                                        <div className="col-9 pl-0 pr-0">
                                            <h5 className="name_voucher">{item.code}</h5>
                                            <span className="price_voucher">Giá trị: { item.unit == 0 ? formatCurrency(item.value) + ' VND' : item.value + ' % (' + formatCurrency(Math.round(item.value * priceProvisional / 100)) + ' VND)' }</span>
                                            <div className="grid">
                                                <p>HSD: { convertDateTime(item.start_time, 'DD/MM/YYYY HH:mm') } - { convertDateTime(item.end_time, 'DD/MM/YYYY HH:mm') }</p>
                                                <p>Giá trị đơn hàng tối thiểu: { formatCurrency(item.min_order_value) } VND</p>
                                                <p>Số lượng : { item.number_of_use }</p>
                                            </div>
                                        </div>
                                        {!couponId || couponId !== item.id ?
                                          <div className="col-3 pl-0 pr-0">
                                              <button type="button" className="btn bt-apply"
                                                      onClick={() => applyCoupon(item)}>Áp dụng
                                              </button>
                                          </div> :
                                          <div className="col-3 pl-0 pr-0">
                                              <button type="button" className="btn bt-apply background-apply">Đã áp dụng
                                              </button>
                                          </div>
                                        }
                                    </li>
                                }) : null}

                            </ul>
                            { couponListNotUse.length ?
                            <ul className="list_voucher voucher_more">
                                <h1>Mã giảm giá khác</h1>
                                { couponListNotUse.length ? couponListNotUse.map(item => {
                                    return (
                                      <li key={item.id}>
                                          <div className="col-9 pl-0 pr-0">
                                              <h5 className="name_voucher">{item.code}</h5>
                                              <span className="price_voucher">Giá trị: { item.unit == 0 ? formatCurrency(item.value) + ' VND' : item.value + ' % (' + Math.round(item.value * priceProvisional / 100) + ' VND)' }</span>
                                              <div className="grid">
                                                  <p>HSD: { convertDateTime(item.start_time, 'DD/MM/YYYY HH:mm') } - { convertDateTime(item.end_time, 'DD/MM/YYYY HH:mm') }</p>
                                                  <p>Giá trị đơn hàng tối thiểu: { formatCurrency(item.min_order_value) } VND</p>
                                                  <p>Số lượng : { item.number_of_use }</p>
                                                  {new Date(item.start_time) > new Date() ?
                                                    <strong>*Mã giảm giá chưa bắt đầu</strong>
                                                    : (new Date(item.end_time) < new Date() ? <strong>*Mã giảm giá đã kết thúc</strong> : <strong>*Bạn không đủ điều kiện sử dụng mã này</strong>) }
                                              </div>
                                          </div>
                                      </li>
                                    )
                                }) : null }
                            </ul> : null }
                        </Form>
                    </Modal.Body>
                    {couponId ?
                    <Modal.Footer>
                        <button type="button" className="btn-remove-voucher" onClick={() => removeCoupon()}>Bỏ áp dụng mã giảm giá</button>
                    </Modal.Footer> : null }
                </Modal>
            </div>
            <Modal className="modal_deleteAdressUser" show={showStatus} onHide={handleCloseStatus}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseStatus}>
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Payment;
