import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import ReactStars from "react-rating-stars-component";
import {formatCurrency, getImageMedia} from "../../helpers/Function";
import $ from "jquery"
import {ReviewOrderClientApiService} from "../../ultils/EndPoint";
import PopupReviewSuccess from "./PopupReviewSuccess";
function ViewOrder(props) {
    const [show, setShow] = useState(false);
    const [showReviewSuccess, setShowReviewSuccess] = useState(false);
    const [listProduct] = useState(props.products);

    let arrayRatting = []
    const [arrayReview, setArrayReview] = useState([])

    const ratingChanged = () => {
        arrayRatting = []
        $('.ratting').each(function (){
            let ratting = $(this).find("p[role=status]").html()
            arrayRatting.push(ratting)
        })
    };

    const handleChangeContentReview = () => {
        let content = []
        $('.content_review').each(function (){
            content.push($(this).val())
        })
        setArrayReview(content)
    }

    const setDataReview = () => {
        let data = []
        arrayRatting.length && arrayRatting.map(function (ratting, key){
            let oneObject = {}
            oneObject.product_id = listProduct[key].id
            oneObject.star = parseInt(ratting)
            oneObject.description = arrayReview[key]
            data.push(oneObject)
            return ratting;
        })

        let dataSubmit = {}
        dataSubmit.products = data
        dataSubmit.order_code = props.orderCode
        return dataSubmit
    }

    const handleSubmitReview = () => {
        let isValidate = true;
        if (arrayReview.length === 0){
            isValidate = false
            $('.error_content_review').html("Không được để trống!")
        }else{
            arrayReview.map(function (value, index){
                if (!value){
                    $('.error_content_review_' + index).html("Không được để trống!")
                    isValidate = false
                    return value;
                }else{
                    $('.error_content_review_' + index).html("")
                    return value;
                }
            })
        }

        if (!isValidate){
            return
        }
        ratingChanged()
        let data = setDataReview()

        ReviewOrderClientApiService.review(data, props.orderId).then(async r => {
            if (r.status) {
                await setShowReviewSuccess(true)
                handleClose()
                window.location.reload();
            }else{
                alert(r.error)
            }
        })
    }


    const handleClose = () => setShow(props.close);
    return (
        <div>
            <PopupReviewSuccess show={showReviewSuccess} close={() => setShowReviewSuccess(false)}/>
            <Modal show={props.show} onHide={props.close} className="modal-login modal-view">
                <Modal.Header closeButton  className="grid">
                    <Modal.Title>Đánh giá sản phẩm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <ul className="list_product_order_view pl-0 mb-0">
                            {listProduct && listProduct.map((product, key) => {
                                return (
                                    <li key={key}>
                                        <div className="d-flex">
                                            <img src={getImageMedia(product.image)} alt={product.name}/>
                                            <div className="grid">
                                                <span>{product.name}</span>
                                                <div className="d-flex align-self-center">
                                                    <h2>{formatCurrency(product.product_price)}đ</h2><h4>{product.product_price !== product.product_real_price ? formatCurrency(product.product_real_price)+ 'đ' : ''}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ratings_popup">
                                            <h5>Đánh giá sản phẩm</h5>
                                            <ReactStars
                                                classNames="ratting"
                                                count={5}
                                                size={24}
                                                isHalf={false}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                activeColor="#ffd700"
                                                value={5}
                                            />
                                        </div>
                                        <div className="comment">
                                            <h5>Bình luận</h5>
                                            <textarea onChange={handleChangeContentReview} cols="30" rows="3" className="form-control content_review" placeholder="Nhập bình luận"></textarea>
                                            <span style={{color : "red", fontSize: "12px"}} className={"error_content_review error_content_review_" + key}/>
                                        </div>
                                    </li>
                                )
                            })}

                        </ul>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn-remove-voucher" onClick={handleSubmitReview}>Đánh giá</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ViewOrder;
