import React, {useEffect, useState} from 'react';
import './bannerFooter.scss';
import btnAppstore from '../../../assets/img/app-store 1.svg' 
import btnGoogke from '../../../assets/img/playstore 1.svg' 
import qrcode from '../../../assets/img/Rectangle 118.svg'
import hand from '../../../assets/img/hand.svg'
import { BannerApiService } from '../../../ultils/EndPoint';

function BannerFooter() {
    const [bannerApp, setBannerApp] = useState([])
    useEffect(() => {
        BannerApiService.getBannerByType(5).then(res => {
            setBannerApp(res.data)
        })
    }, [])

    return (
        <div style={{backgroundImage:`url(${ bannerApp !== null ? bannerApp[0] && bannerApp[0].image : ''})`, backgroundRepeat: 'round'}} className="banner-footer-common">
            <div className="container">
                <div className="row">
                    <div className="banner-footer-body">
                        <ul>
                            <h1>Tải app {process.env.REACT_APP_NAME}</h1>
                            <p>Săn sale miễn phí, shopping thả ga</p>
                            <div className="d-flex">
                                <div className="btn-social mr-3">
                                    <img style={{'width': '36px', 'height': '36px'}} alt="" src={btnGoogke}></img>
                                    <div className="ml-2">
                                        <p>Tải từ</p>
                                        <h1>Google Play</h1>
                                    </div>
                                </div>
                                <div className="btn-social mr-3">
                                    <img style={{'width': '36px', 'height': '36px'}} alt="" src={btnAppstore}></img>
                                    <div className="ml-2">
                                        <p>Tải từ</p>
                                        <h1>Apple Store</h1>
                                    </div>
                                </div>
                                <img className="qrCode" style={{'width': '82px', 'height': '90px', 'marginTop': '-7px'}} alt="" src={qrcode}></img>
                            </div>
                        </ul>
                        <ul className="banner_download">
                            <img style={{'width': '342px', 'height': '332px'}} className='ml-0 ' alt="" src={hand}></img>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerFooter
