import { takeEvery, call, put } from 'redux-saga/effects';
import { SIGNIN_REQUEST, SIGNUP_REQUEST, SIGNIN_BY_SOCIAL_REQUEST } from '../constans/ActionType';
import { signIn, signUp, socialLoginRequest } from '../ultils/Axios';
import {notificationActions, userActions} from '../actions';

function* signInRequest(action) {
  try {
    const user = yield call(signIn, action.payload);  
    const { data } = user;
    yield put(notificationActions.addNotification(user.payload));
    localStorage.setItem('User-token', data.token);
  } catch (error) {
    yield put(notificationActions.addNotification(error));
  }
}

function* loginBySocialAccount(action) {
  try {
    const user = yield call(socialLoginRequest, action.payload); 
      if(action.payload.provider === "facebook"){
        localStorage.setItem('User-token', user.data.data.token);
        setTimeout(() =>{
          window.location.reload()
        }, 500);
        } 
        if(action.payload.provider == "google"){
          localStorage.setItem('User-token', user.data.data.token);
          setTimeout(() =>{
          window.location.reload()
        }, 500);
      }
    } catch (error) {
      
  }
}

function* signUpRequest(action) {
  try {
    const user = yield call(signUp, action.payload); 
    yield put(notificationActions.addNotification(user.payload));
    yield put(userActions.signUpSuccess(user.payload));
  } catch (error) {
    // yield put(notificationActions.addNotification(error));
  }
}


function* userWatcher() {
  yield takeEvery(SIGNIN_REQUEST, signInRequest);
  yield takeEvery(SIGNUP_REQUEST, signUpRequest);
  yield takeEvery(SIGNIN_BY_SOCIAL_REQUEST, loginBySocialAccount);
}

export { userWatcher };

