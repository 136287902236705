import Main from "./pages/Main";
import ProductList from "./pages/Product_list";
import CategoryList from "./pages/Category_product";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory
} from "react-router-dom";
import Header from "./components/common/header/Header";
import React, {useEffect} from "react";
import Footer from "./components/common/footer/Footer";
import TradeMark from './pages/Trademark';
import Search from './pages/Search';
import SearchNotFound from './pages/Search_notfound';
import News from './pages/News_list';
import Detail from '../src/components/common/news/detail';
import TermServices from './pages/TermServices';
import ProductDetail from './components/common/product/Detail';
import NotFound from "./components/common/not_found/NotFound";
import Cart from './pages/Cart';
import Payment from './pages/Payment';
import ScrollToTop from "./components/common/ScrollToTop";
import iconChat from '../src/assets/img/Chat bubble.png';
import CopyRight from "./components/common/cory_right/CopyRight";
import Chat from './components/common/chat/Chat';
import $ from "jquery";
import {websiteActions} from "./actions";
import {useDispatch, useSelector} from "react-redux";
import ProfilePage from "./pages/profile/Profile";
import DetailInfo from "./components/profile/profile-components/DetailInfo";
import ChangePassword from "./components/profile/profile-components/ChangePassword";
import AdressDelivaryShow from "./components/profile/profile-components/AdressDelivaryShow";
import AdressDeliveryStore from "./components/profile/profile-components/AdressDeliveryStore";
import AdressDelivery from "./components/profile/profile-components/AdressDeliveryStore";
import FavoriteProduct from "./components/profile/profile-components/FavoriteProduct";
import PrescriptionEmpty from "./components/profile/profile-components/PrescriptionEmpty";
import Prescription from "./components/profile/profile-components/Prescription";
import PrescriptionList from "./components/profile/profile-components/PrescriptionList";
import Purchase from "./components/profile/profile-components/Purchase";
import PurchaseDetail from "./components/profile/profile-components/PurchaseDetail";
import Coupon from "./components/profile/profile-components/Coupon";
import CouponEmpty from "./components/profile/profile-components/CouponEmpty";
import HistoryPoint from "./components/profile/profile-components/HistoryPoint";
import BuyHistoryPoint from "./components/profile/profile-components/BuyHistoryPoint";
import HistoryPointEmpty from "./components/profile/profile-components/HistoryPointEmpty";

function App() {
    const token = localStorage.getItem('User-token');
    const chat = useSelector(state => state.getWebsite.footer)
    const dispatch = useDispatch()
    // const stateOpenMyFullorder = useSelector(state => state.getWebsite.openFullMyOrder)
    // useEffect(() => {
    //     if(stateOpenMyFullorder === true){
    //         dispatch(websiteActions.setFullMyOrder(false))
    //     }
    // }, [])
    
    const routes = [
        {
            path: "/profile/detail",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <DetailInfo/>
        },
        {
            path: "/profile/change-password",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <ChangePassword/>
        },
        {
            path: "/profile/address-delivery",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <AdressDelivaryShow/>
        },
        {
            path: "/profile/address-delivery/address",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <AdressDeliveryStore/>
        },
        {
            path: "/profile/address-delivery/address/:slug",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <AdressDelivery/>
        },
        {
            path: "/profile/favorite-product",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <FavoriteProduct/>
        },
        {
            path: "/profile/prescription/empty",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <PrescriptionEmpty />
        },
        {
            path: "/profile/prescription/create",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <Prescription />
        },
        {
            path: "/profile/prescription",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <PrescriptionList />
        },
        {
            path: "/profile/purchase-order",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <Purchase />
        },
        {
            path: "/profile/purchase-order/:id",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <PurchaseDetail />
        },
        {
            path: "/profile/coupons",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <Coupon />
        },
        {
            path: "/profile/coupons/empty",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <CouponEmpty />
        },
        {
            path: "/profile/history-points",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <HistoryPoint />
        },
        {
            path: "/profile/history-points/buy",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <BuyHistoryPoint />
        },
        {
            path: "/profile/history-points/empty",
            exact: true,
            sidebar: () => <div></div>,
            main: () => <HistoryPointEmpty />
        },
    ];

    useEffect( () => {
        $(window).on('popstate', function(event) {
            if (event.target.location.pathname !== '/cart') {
                dispatch(websiteActions.setFooter(true))
            } else {
                dispatch(websiteActions.setFooter(false))
            }
        });
    })

    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            document.getElementById("goToTop").style.display = "block";
        } else {
            document.getElementById("goToTop").style.display = "none";
        }
    }

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <div>
            <Router>
                <ScrollToTop />
                    <Header/>
                        <Switch>
                            <Route path='/' exact component={Main}/>
                            {routes.map((route, index) => (
                              <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={() =><ProfilePage authorized={token} component={<route.main />}/>}
                              />
                            ))}

                            <Route exact path='/group/:slug' component={ProductList}/>
                            
                            <Route exact path='/deal/:slug' component={() => <ProductList deal={1}/>}/>

                            <Route exact path='/san-pham/:slug' component={ProductDetail}/>

                            <Route exact path='/danh-muc/:slug' component={CategoryList}/>

                            <Route exact path='/thuong-hieu/:slug' component={TradeMark}/>

                            <Route exact path='/search' component={Search}/>

                            <Route exact path='/search/not-found' component={SearchNotFound}/>
                            
                            <Route exact path='/tin-tuc-danh-muc/:slug' component={News}/>

                            <Route exact path='/tin-tuc/:slug' component={Detail}/>

                            <Route exact path='/static-page/:slug' component={TermServices}/>

                            <Route exact path='/cart' component={Cart}/>

                            <Route exact path='/thanh-toan' component={Payment}/>

                            <Route path='/not-found' component={NotFound}></Route>
                            
                            <Route component={NotFound} />
                        </Switch>
                    <Footer/>
                <CopyRight/>
            </Router>

        
            <button onClick={topFunction} id="goToTop"><i className="fas fa-arrow-up" color="#FFFF"></i></button>
            {/*{ chat === true  ?*/}
                <div className="chat">
                    <button className="popupChat">
                        <img src={iconChat} alt=""/>
                    </button>
                    <Chat />
                </div>
            {/*: null*/}
        </div>
    );
}

export default App;
