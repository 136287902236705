import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/app.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './assets/img/Store';

ReactDOM.render(
  <div className="main">
    <Provider store={store}>
          <App />
    </Provider>
  </div>,
document.getElementById('root')
);

reportWebVitals();
