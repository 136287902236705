import React, { useEffect, useState} from 'react';
import './header.scss';
import logo from '../../../assets/img/logo group.png';
import user_icon from '../../../assets/img/User_cicrle.svg';
import user_love from '../../../assets/img/Favorite.svg';
import user_cart from '../../../assets/img/shopping 1 (1).svg';
import Login from '../../../pages/auth/login';
import logo_mobile from '../../../assets/img/Avatart.png';
import closeButton from '../../../assets/img/Close_round.png';
import iconChat from '../../../assets/img/Chat Bubbles.svg'
import icon_user from '../../../assets/img/User.svg';
import icon_order from '../../../assets/img/Paste.svg';
import icon_sale from '../../../assets/img/Gift.svg';
import icon_favorite from '../../../assets/img/menu/Favorite.svg';
import icon_logout from '../../../assets/img/Sign Out.svg';
import AccountPhone from '../../../pages/auth/account_phone';
import axios from 'axios';
import ENDPOINT, {WebsiteApiService, CartApiService, ProductFavoriteApiService} from '../../../ultils/EndPoint';
import arrow from '../../../assets/img/Path.svg';
import {useDispatch, useSelector} from 'react-redux';
import {userActions, websiteActions} from '../../../actions';
import {setListFavoriteProduct} from '../../../actions/FavoriteProduct';
import { GetAllDataCart, DeleteCart } from '../../../actions/Cart';
import { actions } from '../../../actions/User';
import {useHistory} from 'react-router-dom';
import { Link } from 'react-router-dom';
import imgButtonRemove from '../../../assets/img/payment/Vector.png';
import imgNotCart from '../../../assets/img/payment/shopping-cart (1) 1.png';
import Menu from '../menu';
import MenuMobile from '../menu/menu_mobile';
import {Helmet} from "react-helmet";
import {formatCurrency, getImageMedia} from "../../../helpers/Function";
import {getQuerystring} from "../../../helpers/Function";
import $, { trim } from 'jquery'
import {ToastContainer} from "react-toastify";

function Header() {
    const dispatch = useDispatch()
    const userAuthToken = localStorage.getItem('User-token');
    const history = useHistory();
    const [nameForcart, setNameForcart] = useState(false);
    const logOut = () => {
        localStorage.removeItem('User-token');
        dispatch(userActions.logOut())
        window.location.reload()
    };

    const [authMe, setAuthMe] = useState([]);

    // get data of cart
    const state = useSelector(state => state.todoProduct);


    const [dataCart, setDataCart] = useState([])
 
    useEffect(() => {
        if(state.cartData){
            setDataCart(state.cartData.products)
        }
    }, [state.cartData.products])

    const stateUserRegister = useSelector(state => state.getWebsite)

    useEffect(() => {
        if(stateUserRegister.registerSuccess === true){
            dispatch(actions.signInRequest(stateUserRegister.userRegister));

            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    }, [stateUserRegister.registerSuccess, stateUserRegister.userRegister, dispatch])
 

    let totalProductsInCart = 0
    dataCart && dataCart.map(number =>
        totalProductsInCart += number.quantity
    )

    useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${userAuthToken}` }
        };
          
        const signInAuth = () => {
        return axios.get(`${ENDPOINT.LOGINAUTH}`, config)
            .then(function (response) {
                setAuthMe(response.data.data);
                dispatch(actions.getAuthMe(response.data.data))
            })
            .catch(function(error) {
                if(error.response && error.response.status === 401){
                    localStorage.removeItem('User-token');
                    history.replace('/');
                }
            }
        );
        } 
        if(userAuthToken) {
            signInAuth()
        }

        WebsiteApiService.getWebsite().then(res => {
            dispatch(websiteActions.setWebsite(res.data))
        })

    }, [userAuthToken,dispatch, history])

    // set show for modal
    const [showLogin, setShowLogin] = useState(false);
    // const [showNotLogin, setShowNotLogin] = useState(false);
    const [showVerification, setshowVerification] = useState(false);
    const websiteInfo = useSelector(state => state.getWebsite.website);

    const state1 = useSelector(state => state.getWebsite.openModal)
    const stateSocialLogin = useSelector(state => state.getWebsite.socialLogin)

    useEffect(() => {
        if(state1 === true){
            setShowLogin(true)
        }

        if(stateSocialLogin === true){
            setShowLogin(false)
            setshowVerification(false)
        }
    }, [state1, stateSocialLogin])
   
    // handle cart
    const handleCartAdd =()=> {
        if(!userAuthToken){
            setNameForcart(true)
            setShowLogin(true)
        } else {
            dispatch(websiteActions.setFooter(false))
            history.push('/cart');

        }
    }

    let TotalCurrPriceCart = 0;

    dataCart && dataCart.map(check =>
        TotalCurrPriceCart+= check.quantity * check.product_price
    )
    // func delete item in cart 
    const reomoveItemInCart = (cart) => {
        const cartAfterDelete = dataCart && dataCart.filter((result) => result.id !== cart.id)
        setDataCart(cartAfterDelete);
 
        var data = [];
        cartAfterDelete && cartAfterDelete.map(cart => 
            {data.push({
                quantity: cart.quantity, 
                product_id: cart.id,
                selected: true,
            })
            }
        )
        CartApiService.postCart(data).then(res => {
            if(res.status === true){
                dispatch(GetAllDataCart(res.data));
            }
        })
  
    }

    useEffect(() => {
        if(userAuthToken){
            CartApiService.getCart().then(function (response) {
                if (response.data) {
                    dispatch(GetAllDataCart(response.data));
                } else {
                    dispatch(GetAllDataCart([]));
                }

            })
        }
    }, [dispatch, userAuthToken])


    // set token check 
    const tokenUser = localStorage.getItem('User-token')
    const stateListFavorite = useSelector(state => state.getFavoriteProduct.listDataGet)
    const stateListFavorite1 = useSelector(state => state.getFavoriteProduct.allFavor)
    const stateList = useSelector(state => state.getFavoriteProduct.favorite_products)

    useEffect(() => {
        if(tokenUser){
            ProductFavoriteApiService.getAllListFavorite().then(res => {
            dispatch(setListFavoriteProduct(res.data))
            })
        }
    }, [dispatch, stateList, stateListFavorite1])

    const handleFavorProducts = () => {
        if(!userAuthToken){
            setNameForcart(true)
            setShowLogin(true)
        }
    }
    const redirectSearch = (e) => {
        if (e.key === 'Enter') {
            history.push('/search?keyword=' +  encodeURIComponent(e.target.value))
        }
    }
    function getTotalPointFinal(){
        let point = 0
        dataCart && dataCart.map(product => {
            if (product.product_point > 0) {
                point += product.product_point * product.quantity
            }
        })
        return point
    }

    $(function () {
        $('.navbar-toggler').on('click',function () {
            $('body').addClass('body_shadow')
        })
        $('.btn-close-menu').on('click',function () {
            $('body').removeClass('body_shadow')
        })
        $('.caret').off('click').on("click", function(e){
            $(this).toggleClass('active')
            $(this).parent().next('ul').toggle();
            e.stopPropagation();
            e.preventDefault();
        });
    })

    return (
        <div>
            <Helmet>
                <link rel="icon" href={websiteInfo.icon} type="image/x-icon" sizes="16x16 32x32"/>
                <title>{websiteInfo.meta_title}</title>
                <meta name="description" content= {websiteInfo.meta_desc}/>
                <meta name="keywords" content= {websiteInfo.meta_key} />
            </Helmet>
            <AccountPhone show={showVerification} close={() => setshowVerification(false)} onHide={() => setshowVerification(false)} />
            {/* <NotLogin show={showNotLogin} close={() => setShowNotLogin(false)} onHide={() => setShowNotLogin(false)}/> */}
            <Login name={ nameForcart === true ? 'Bạn cần đăng nhập để thực hiện chức năng này' : ''}   show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
            <div className="header-top">
                <div className="container">
                    <div>
                        <div className="d-flex justify-content-between header-language w-100">
                            <p>{websiteInfo.title_header}</p>
                            <select className="dropdown-header-list">
                                <option>Tiếng Việt</option>
                                {/* <option>EngLish</option> */}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-0 pr-0">
                <div className="header pc">
                    <div>
                        <div className="container">
                            <div className="header-middle d-flex justify-content-between">
                                <img src={websiteInfo.logo} style={{cursor:'pointer'}} onClick={() => {history.replace('/'); dispatch(websiteActions.setFooter(true));}} alt="image_logo" className="image-logo"></img>

                                <input onKeyPress={(e) => redirectSearch(e)} className="input-search form-control" placeholder="Tìm kiếm ở đây" defaultValue={decodeURIComponent(getQuerystring('keyword'))}></input>
                                {userAuthToken ?
                                    (<div className="user-login">
                                    <img src={authMe.Avatar} alt="anh_avatar" className="logo-user-login-auth"></img><img className="arrow-avatar-auth" alt="anh" src={arrow}></img>
                                    <p className="name-auth">Xin chào, {authMe.Name}</p>
                                    <ul className="list-auth">
                                        <Link to='/profile/detail' style={{'textDecoration': 'none', 'color': 'rgba(0, 0, 0, 1)'}} onClick={() => {dispatch(websiteActions.setFooter(true))}}><li className="non-boder-top">Thông tin tài khoản</li></Link>
                                        <Link to="/profile/purchase-order" style={{'textDecoration': 'none', 'color': 'rgba(0, 0, 0, 1)'}} onClick={() => {dispatch(websiteActions.setFooter(true)) ; dispatch(websiteActions.setFullMyOrder(true))}}><li>Đơn hàng của tôi</li></Link>
                                        <Link to="/profile/coupons" style={{'textDecoration': 'none', 'color': 'rgba(0, 0, 0, 1)'}} onClick={() => {dispatch(websiteActions.setFooter(true))}}><li>Ưu đãi của tôi</li></Link>
                                        <li onClick={logOut} >Đăng xuất</li>
                                    </ul>
                                </div>)
                                : (<div className="user-login">
                                    <img src={user_icon} alt="anh_user_icon" className="logo-user-login"></img>
                                    <div  className="d-flex">
                                        <p className="link" onClick={() => setShowLogin(true)} >Đăng nhập</p>
                                        <strong> / </strong>
                                        <p className="link" onClick={() => setshowVerification(true)} >Đăng ký</p>
                                    </div>
                                </div>)
                                }
                                <Link to="/profile/favorite-product" onClick={() => {dispatch(websiteActions.setFooter(true))}}>
                                    <div className="user-love" onClick={handleFavorProducts}>
                                        <div className="acount-productFavor">
                                            <img src={user_love} alt="anh_icon" className="logo-user-love"></img>
                                            {
                                                userAuthToken
                                                ? stateListFavorite && stateListFavorite.length > 99 ? <p>99+</p> : <p>{stateListFavorite === null ? 0 : stateListFavorite && stateListFavorite.length}</p>
                                                : ''
                                            }
                                        </div>
                                        <p>Yêu thích</p>
                                    </div>
                                </Link>
                                <div className="user-cart">
                                    {userAuthToken ?
                                      <Link to="/cart" onClick={() => {dispatch(websiteActions.setFooter(false))}}>
                                          <div className="acount-productInCart">
                                              <img src={user_cart} alt="anh" className="logo-user-cart"></img>
                                              {totalProductsInCart > 99 ? <p>99+</p> :
                                                <p>{totalProductsInCart}</p>}
                                          </div>
                                      </Link>
                                      :
                                      <div className="acount-productInCart" onClick={handleCartAdd}>
                                          <img src={user_cart} alt="anh" className="logo-user-cart"></img>
                                      </div>
                                    }
                                    <p>Giỏ hàng</p>
                                    {
                                        totalProductsInCart === 0
                                        ?   <div className="cart_popup not_product">
                                                <img src={imgNotCart} alt="anh_cart" />
                                                <span>Giỏ hàng trống. Hãy tiếp tục mua sắm nhé bạn</span>
                                            </div>
                                        :
                                            <div className="cart_popup">
                                                <h5>Giỏ hàng: {totalProductsInCart} sản phẩm</h5>
                                                <ul className="list_cart_popup">
                                                    {dataCart && dataCart.map((cart, index) =>
                                                        <li key={index}>
                                                            <Link to={"/san-pham/" + cart.slug} onClick={() => {dispatch(websiteActions.setFooter(true))}}>
                                                                <img src={getImageMedia(cart.image)} alt={cart.name} />
                                                            </Link>
                                                            <div className="grid">
                                                                <Link to={"/san-pham/" + cart.slug} onClick={() => {dispatch(websiteActions.setFooter(true))}}>
                                                                    <p className="name_product">{cart.name}</p>
                                                                </Link>
                                                                {cart.product_point ?
                                                                    <div className="price">
                                                                        <h2 style={{'color':'#F53C45'}}>{formatCurrency(cart.product_point)} điểm</h2>
                                                                    </div> :
                                                                    cart.product_price === cart.product_real_price
                                                                    ?
                                                                    <div className="price">
                                                                        <h2 style={{'color':'#F53C45'}}>{formatCurrency(cart.product_price)} đ</h2>
                                                                    </div>
                                                                    :
                                                                    <div className="d-flex price">
                                                                        <h2 style={{'color':'#F53C45'}}>{formatCurrency(cart.product_price)} đ</h2>
                                                                        <h4 style={{'color':'#F53C45'}}>{formatCurrency(cart.product_real_price)} đ</h4>
                                                                    </div>
                                                                }

                                                            </div>
                                                            <div className="amount_product">
                                                                <span>x{cart.quantity}</span>
                                                            </div>
                                                            <div className="price_product">
                                                                    {cart.product_point === 0 ?
                                                                        <span>{formatCurrency(cart.origin_price)}đ</span>
                                                                        : <span>{formatCurrency(cart.point)} điểm</span> }
                                                            </div>

                                                            <button type="button" onClick={() => { reomoveItemInCart(cart)
                                                              dispatch(DeleteCart(index))}} className="btn btn_remove_product p-0">
                                                                <img src={imgButtonRemove} alt="anh_remove"/>
                                                            </button>
                                                        </li>
                                                    )}
                                                </ul>
                                                <ul className="list_cart_popup">
                                                    <li className="view_cart">
                                                        <span style={{display:"block"}}>Tạm tính: {Number(TotalCurrPriceCart).toLocaleString('en-US')}đ</span>
                                                        {
                                                            getTotalPointFinal() === 0
                                                            ? ''
                                                            : <span> Điểm : {getTotalPointFinal() + ' điểm'}</span>
                                                        }
                                                        {userAuthToken ? <Link to="/cart" onClick={() => {dispatch(websiteActions.setFooter(false))}}>Xem giỏ hàng</Link> : <Link to="/" onClick={() => {dispatch(websiteActions.setFooter(true))}}>Xem giỏ hàng</Link>}
                                                    </li>
                                                </ul>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <Menu />
                    </div>
                </div>
                <div className="header mobile">
                    <nav style={{zIndex:'1000'}} className="navbar navbar_mobile navbar-expand-lg navbar-light">
                        <div className="d-flex justify-content-between w-100">
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="header-middle">
                                <img src={websiteInfo.logo} onClick={() => {history.replace('/'); dispatch(websiteActions.setFooter(true));}} alt="image_logo" className="image-logo"></img>
                            </div>
                            <div className="user-cart">
                                {userAuthToken ?
                                  <Link to="/cart" onClick={() => {dispatch(websiteActions.setFooter(false))}}>
                                      <div className="acount-productInCart">
                                          <img src={user_cart} alt="anh" className="logo-user-cart"></img>
                                          {totalProductsInCart > 99 ? <p>99+</p> :
                                            <p>{totalProductsInCart}</p>}
                                      </div>
                                  </Link>
                                  :
                                  <div className="acount-productInCart" onClick={handleCartAdd}>
                                      <img src={user_cart} alt="anh" className="logo-user-cart"></img>
                                  </div>
                                }
                            </div>
                        </div>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <button type="button" className="btn-close-menu" data-toggle="collapse" id="closeMenu"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <img src={closeButton} alt="icon_close"/>
                            </button>
                            {userAuthToken ?
                                <div className="d-flex login_mobile">
                                    <img src={authMe.Avatar} alt="avatar_user" className='logo_mobile'/>
                                    <div className="grid">
                                        <h5>Xin chào, {authMe.Name}</h5>
                                        <p>Hôm nay bạn thế nào?</p>
                                    </div>
                                </div>
                              : <div className="d-flex login_mobile">
                                      <img src={logo_mobile} alt="logo_mobile" className='logo_mobile'/>
                                      <div className="grid">
                                          <h5>{process.env.REACT_APP_NAME} chào bạn,</h5>
                                          <div className="d-flex">
                                              <button type="button" className="login"  onClick={() => setShowLogin(true)}>Đăng nhập</button>
                                              <button type="button" className="signup" onClick={() => setshowVerification(true)}>Đăng ký</button>
                                          </div>
                                      </div>
                                  </div>
                            }
                            <ul className="navbar-nav mr-auto menu_child">
                                <MenuMobile></MenuMobile>
                                <li className="nav-item">
                                    <Link to="#" className="forum" onClick={() => { $('#closeMenu').click() }}>
                                        <img src={iconChat} alt=""/>
                                        Diễn đàn <strong>CitiCare</strong>
                                    </Link>
                                </li>
                                {userAuthToken ?
                                <li className="nav-item menu_login">
                                    <Link to="/profile/detail" className="forum" onClick={() => { $('#closeMenu').click() }} >
                                        <img src={icon_user} alt=""/>
                                        <span>Thông tin tài khoản</span>
                                    </Link>
                                </li> : null }
                                {userAuthToken ?
                                <li className="nav-item menu_login">
                                    <Link to="/profile/purchase-order" className="forum" onClick={() => { $('#closeMenu').click() }} >
                                        <img src={icon_order} alt=""/>
                                        <span>Thông tin đơn hàng</span>
                                    </Link>
                                </li> : null }
                                {userAuthToken ?
                                <li className="nav-item menu_login">
                                    <Link to="/profile/coupons" className="forum" onClick={() => { $('#closeMenu').click() }} >
                                        <img src={icon_sale} alt=""/>
                                        <span>Ưu đãi của tôi</span>
                                    </Link>
                                </li> : null }
                                {userAuthToken ?
                                <li className="nav-item menu_login">
                                    <Link to="/profile/favorite-product" className="forum" onClick={() => { $('#closeMenu').click() }} >
                                        <img src={icon_favorite} alt=""/>
                                        <span>Danh sách yêu thích</span>
                                    </Link>
                                </li> : null }
                                {userAuthToken ?
                                <li className="nav-item menu_login">
                                    <Link to="#" onClick={logOut} className="forum" >
                                        <img src={icon_logout} alt=""/>
                                        <span>Đăng xuất tài khoản</span>
                                    </Link>
                                </li>
                                  : null }
                            </ul>
                            {userAuthToken ?
                              <Link to="/profile/prescription/create" className="upload_prescription" onClick={() => { $('#closeMenu').click() }}>Đăng đơn thuốc</Link>
                              : null}
                        </div>
                    </nav>
                    <div className="input_search_header">
                        <input onKeyPress={(e) => redirectSearch(e)} className="input-search form-control" placeholder="Tìm kiếm ở đây" defaultValue={decodeURIComponent(getQuerystring('keyword'))}></input>
                    </div>
                </div>
            </div>
            <ToastContainer
                        position="top-right"
                        autoClose={2000}
            />
        </div>
    )
}

export default Header

