import {
  GET_BRAND,
} from "../constans/ActionType";

const actions = {};

actions.setBrands = (brands) => {
  return { type: GET_BRAND, payload: brands };
};

export { actions };
