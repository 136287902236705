import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import imgNotPurchase from '../../../assets/img/rating 1.svg';
import iconBuy from '../../../assets/img/cart/Group.png';
import { useDispatch, useSelector } from 'react-redux';
import ProductFavorite from '../../../components/common/product-item/ProductFavorite';
import {Pagination} from 'antd';
import "antd/dist/antd.css";
import ENDPOINT from '../../../ultils/EndPoint';
import axios from 'axios';
import {setFavoriteProduct} from '../../../actions/FavoriteProduct';

function FavoritProduct() {
    // set const for paginate
    const history = useHistory()
    const [numberPage,setNumberPage] = useState()
    const [currentPage, setcurrentPage] = useState(1);
    const [customersPerPage] = useState(8)

    // dispatch data
    const dispatch = useDispatch();

    // set constans client token 
    const user_token = localStorage.getItem('User-token') 

    // get list of favorist products
    const stateLoveProducts = useSelector(state => state.getFavoriteProduct.favorite_products);
    const stateListFavorite = useSelector(state => state.getFavoriteProduct.listDataGet)

    useEffect(() => {
        if(stateLoveProducts.length === 0){
            setcurrentPage(1)
        }
    }, [stateLoveProducts.length])

    // set data paginate
    useEffect(() => {
        let query = '';
        const data = {
            per_page : customersPerPage,
            page: currentPage,
        }
        Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )

        let unmounted = false;
        axios.get(`${ENDPOINT.FAVORITE_PRODUCT}/list` + query, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then((res) => {
            if (!unmounted) {
                dispatch(setFavoriteProduct(res.data.data.records || []))
                setNumberPage(res.data.data.total_record)
            }
        })
        .catch(error => {
            if(error.response && error.response.status === 401){
                localStorage.removeItem('User-Admin');
                history.push('/')
            }
        }) 
        return () => { unmounted = true };
    }, [
        stateListFavorite && stateListFavorite.length,
        customersPerPage, 
        currentPage, 
        dispatch,
    ]);
        
    return (
        stateListFavorite && stateListFavorite.length > 0
            ? 
                <div className="changePass-wapper store_newUserAdress">
                    <h1>Sản phẩm yêu thích</h1>
                    <div className="grid grid-4 grid-gap-10">
                        {stateLoveProducts && stateLoveProducts.map((product, key) => {
                        return (
                            <ProductFavorite product={product} key={key}/>
                        )
                    })}
                    </div>
                    {
                        stateListFavorite.length < 9
                        ? ''
                        :
                            <Pagination 
                                onChange={(value) => setcurrentPage(value)}
                                total={numberPage}
                                current={currentPage}
                                defaultPageSize='8'
                                showSizeChanger= {false}
                            /> 
                    }
                </div>          
            : 
                <div className="content_purchase">
                    <div className="changePass-wapper">
                        <h1>Sản phẩm yêu thích</h1>
                        <div className="table_purchase">
                            <img src={imgNotPurchase} alt=""/>
                            <h2>Bạn chưa có sản phẩm yêu thích nào ?</h2>
                            <div onClick={() => history.push('/')} className="love_list_product">
                                <img src={iconBuy} alt=""/>
                                <span>Quay lại mua sắm</span>
                            </div>
                        </div>
                    </div>
                </div>
        
    )
}

export default FavoritProduct;
