import React, {useEffect, useState} from 'react';
import '../Profile.scss';
import $ from 'jquery';
import axios from 'axios';
import ENDPOINT from '../../../ultils/EndPoint';
import {
    Link, useHistory
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router';

function AdressDeliveryStore() {
    // get slug
    const {slug}= useParams();
    console.log(slug)
    const history = useHistory()
    // set constans city, wards, districts
    const [city, setCity] = useState([]);
    const [city_id, setCity_id] = useState('');
    const [city_id_first, setCity_id_first] = useState('')
    const [district, setDistrict] = useState([]);
    const [district_id, setDistrict_id] = useState('');
    const [wards, setWards] = useState([]);
    const [ward_id, setWard_id] = useState('');

    // set const info 
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [adress, setAdress] = useState('');
    const [check, setCheck] = useState('');
    const [validationMsg, setValidationMsg] = useState({});
    const [disable, setDisable]= useState(false);
    const [checkBox, setCheckBox] = useState(false)

    // set msg if user not set default adress
    const [msg, setMsg] = useState('')

    //removeExtraSpace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // check only letter
    var regexCheck = /^[a-zA-Z\s]*$/

    // check only nummber
    var regexCheckNumber = /^[0-9]*$/

    // handle every thing change
    const handleCity = (e) => {
        setCity_id(e.target.value)
    }

    $("#exampleFormControlSelect1").change(function() {
        var id = $(this).children(":selected").attr("id");
        setCity_id_first(id)
    });

    const handleDistrict = (e) => {
        setDistrict_id(e.target.value)
    }

    const handleWard = (e) => {
        setWard_id(e.target.value)
    }

    const handleName = (e) => {
        setName(e.target.value)
    }

    const handlePhone = (e) => {
        setPhoneNumber(removeExtraSpace(e.target.value))
    }
  

    const handleAdress = (e) => {
        setAdress(e.target.value)
    }

    const handleCheck = (e) => {
        if(e.target.checked){
            setCheck('1')
            setCheckBox(true)
            setMsg('')
        }else {
            setCheck('0')
            setCheckBox(false)
        }
    }

    // handle for edit adress
    const [dataAll, setDateAll] = useState([]);
    let citi_zone = city.find(item => item.id === city_id_first);

    useEffect(() => {
        if(citi_zone){
            setCity_id(citi_zone.viet_nam_zone_id)
            setDistrict_id(dataAll.district_id)
            setWard_id(dataAll.ward_id)    
        }
    }, [citi_zone, dataAll])

    // call api for checked invalid
    useEffect(() => {
        if(slug){
            let unmounted = false;
            axios.get(`${ENDPOINT.ADRESS_USER}/${slug}`,  {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-token') 
                }
            })
            .then(function (response) {
                if (!unmounted) {
                    if(response.status === 200) {
                        setCheck(response.data.data.default);
                        setName(response.data.data.name)
                        setPhoneNumber(response.data.data.phone)
                        setAdress(response.data.data.address)
                        setCity_id_first(response.data.data.city_id)
                        setDateAll(response.data.data)
                    }
                }
            })
            return () => { unmounted = true };
        }
    }, [slug])

    // remove all value
    $('#exampleFormControlSelect1').change( function() {
        $('#exampleFormControlSelect2').val('')
        $('#exampleFormControlSelect3').val('')
    });

    // call api city
    useEffect(()=>{
        axios.get(`${ENDPOINT.CITIES_LIST}`,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-token') 
            }
        })
        .then(function (response) {
            if(response.status === 200) {
                setCity(response.data.data);
            }
        })
    },[]);

    // if have api city_id ---> districts
    useEffect(() => {
        if(city_id){
            let query = '';
            const data = {
                city_id : city_id,
                
            }
            Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
            )

            axios.get(`${ENDPOINT.DISTRICTS_LIST}` + query, data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-token') 
                }
            })
            .then(function (response) {
                    if(response.status === 200) {
                        setDistrict(response.data.data)    
                    }
            })
            .catch(error => {
                console.log(error)
            })
        }
        if(city_id === '') {
            setDistrict('')
            setDistrict_id('')
        } 
    }, [city_id])

    // if have district_id ---> wards
    useEffect(() => {
        if(district_id) {
            let query = '';
            const data = {
                district_id : district_id
            }
            Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
            )

            axios.get(`${ENDPOINT.WARDS_LIST}` + query, data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-token') 
                }
            })
            .then(function (response) {
                    if(response.status === 200) {
                        setWards(response.data.data)
                    }
            })
            .catch(error => {
                console.log(error)
            })
        }

        if(district_id === ''){
            setWards('')
            setWard_id('')
        }

    }, [district_id])

    // validate before submit
    const validateAll = () => {
        const msg = {};
        
        if(name === '') {
            msg.name = 'Vui lòng nhập thông tin Họ và tên'
            $('.form-name').addClass('red_blur')
        }
        // if(!regexCheck.test(name)){
        //     msg.name = 'Trường họ và tên chỉ nhập chữ cái. Vui lòng nhập lại.'
        //     $('.form-name').addClass('red_blur')
        // }
        else if(name.length > 255){
            msg.name = 'Trường họ và tên chỉ tối đa 255 kí tự. Vui lòng nhập lại.'
            $('.form-name').addClass('red_blur')
        } 

    
        if(phoneNumber === '') {
            msg.phoneNumber = 'Vui lòng nhập thông tin số điện thoại'
            $('.form-phone').addClass('red_blur')
        }else if(phoneNumber.length > 10 || phoneNumber.length < 10 || phoneNumber.charAt(0) !== '0' || phoneNumber === '0000000000'){
            msg.phoneNumber = 'Số điện thoại không hợp lệ. Số điện thoại là 10 số, bắt đầu bằng số 0'
            $('.form-phone').addClass('red_blur')
        }

        if(city_id === '') {
            msg.cities = 'Vui lòng chọn tỉnh, thành phố'
            $('.form-city').addClass('red_blur')
        }

        if(district_id === '') {
            msg.districts = 'Vui lòng chọn quận, huyện'
            $('.form-districts').addClass('red_blur')
        }

        if(ward_id === '') {
            msg.wards = 'Vui lòng chọn xã phường'
            $('.form-wards').addClass('red_blur')
        }

        if(wards === '' || district === '' || district_id === ''){
            msg.wards = 'Vui lòng chọn xã phường'
            $('.form-wards').addClass('red_blur')
        }

        if(wards === ''){
            msg.wards = 'Vui lòng chọn xã phường'
            $('.form-wards').addClass('red_blur')
        }

        if(adress === '') {
            msg.adress = 'Vui lòng nhập địa chỉ'
            $('.form-adress').addClass('red_blur')
        }else if(regexCheckNumber.test(adress)) {
            msg.adress = 'Địa chỉ không chính xác. Vui lòng nhập lại'
            $('.form-adress').addClass('red_blur')
        }else if(adress.length > 100) {
            msg.adress = 'Trường địa chỉ tối đa 100 kí tự.Vui lòng nhập lại.'
            $('.form-adress').addClass('red_blur')
        }
        
        setValidationMsg(msg) 
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // remove mess
    const handleRemoveMessName = () => {
        $('.form-name').removeClass('red_blur')
        validationMsg.name = ''
    }

    const handleRemoveMessPhone = () => {
        $('.form-phone').removeClass('red_blur')
        validationMsg.phoneNumber = ''
    }

    $('#exampleFormControlSelect1').change( function() {
        $(this).find(":selected").each(function () {
            validationMsg.cities = ''
            $('.form-city').removeClass('red_blur')
        });
    });

    $('#exampleFormControlSelect2').change( function() {
        $(this).find(":selected").each(function () {
            validationMsg.districts = ''
            $('.form-districts').removeClass('red_blur')
        });
    });

    $('#exampleFormControlSelect3').change( function() {
        $(this).find(":selected").each(function () {
            $('.form-wards').removeClass('red_blur')
            validationMsg.wards = ''
        });
    });

    const handleRemoveMessAdress = () => {
        $('.form-adress').removeClass('red_blur')
        validationMsg.adress = ''
    }

    // params for store adress
    const storeAdress = new URLSearchParams();
    storeAdress.append('name', name);
    storeAdress.append('address', adress);
    storeAdress.append('phone', phoneNumber);
    storeAdress.append('default', check);
    storeAdress.append('city_id', city_id_first );
    storeAdress.append('district_id', district_id);
    storeAdress.append('ward_id',  ward_id);

    // submit when all okey
    const handleStoreAdress = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 3000);

        return axios.post( slug ? `${ENDPOINT.ADRESS_USER}/${slug}` : `${ENDPOINT.ADRESS_USER}`, storeAdress,  {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('User-token') 
            }
           })
          .then(function (response) {
            if(response.status === 200) {
                if(slug){
                    toast.success('Chỉnh sửa địa chỉ thành công')
                }else {
                    toast.success('Thêm địa chỉ thành công')
                }
                setTimeout(() => {
                    history.push('/profile/address-delivery')
                }, 2000);
            }
          })
          .catch(error => {
              if(slug){
                setMsg('Bạn phải có 1 địa chỉ mặc định')
              }
          }
        )

    }

    // only type nummber
    useEffect(() => {
        $('input[name="inputForNumber"]').keydown
        (
        function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else 
                { 
                    if (event.keyCode < 48 || event.keyCode > 57 ) 
                    {
                        event.preventDefault();	
                    }	
                }
            }
        );
    })

   
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="changePass-wapper store_newUserAdress">
                <h1>{slug ? 'Chỉnh sửa địa chỉ giao hàng' : 'Địa chỉ giao hàng mới'} </h1>
                <div className="store_newUserAdress-wapper">
                {/* Name of user */}
                    <div className="form-group">
                        <label style={{'marginBottom':'4px'}} htmlFor="exampleInputEmail1">Họ và tên <span style={{'color':'red'}}>*</span></label>
                        <input type="text" onKeyDown={handleRemoveMessName} value={slug ? name : null} onChange={handleName} className="form-control form-name" />
                        <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.name}</p> 
                    </div>
                {/* Phone nummber of user */}
                    <div className="form-group">
                        <label style={{'marginBottom':'4px'}} htmlFor="exampleInputEmail1">Số điện thoại <span style={{'color':'red'}}>*</span></label>
                        <input name="inputForNumber" onKeyDown={handleRemoveMessPhone} value={slug ? phoneNumber : null} type="text" onChange={handlePhone} className="form-control form-phone" />
                        <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.phoneNumber}</p> 
                    </div>
                {/* City of user */}
                    <div className="form-group">
                        <label style={{'marginBottom':'4px'}} htmlFor="exampleFormControlSelect1">Tỉnh / Thành phố <span style={{'color':'red'}}>*</span></label>
                        <select className="form-control custom_select form-city" id="exampleFormControlSelect1" onChange={handleCity}>
                            <option id="city_value_default" value=""></option>
                            {city.map((city,index) =>
                                <option key={index} value={city.viet_nam_zone_id} selected={slug &&  city_id === city.viet_nam_zone_id } id={city.id}>{city.name}</option>
                            )}
                        </select>
                        <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.cities}</p> 
                    </div>
                {/* District of user */}
                    <div className="form-group">
                        <label style={{'marginBottom':'4px'}} htmlFor="exampleFormControlSelect2">Quận / Huyện <span style={{'color':'red'}}>*</span></label>
                        <select className="form-control custom_select form-districts" onChange={handleDistrict} id="exampleFormControlSelect2">
                            <option value=""></option>
                            {district  ? district.map((district,index) =>
                                <option key={index} selected={slug &&  district_id === district.id } value={district.id}>{district.name}</option>
                            ) : ''}
                        </select>
                        <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.districts}</p> 
                    </div>
                {/* Ward of user */}
                    <div className="form-group">
                        <label style={{'marginBottom':'4px'}} htmlFor="exampleFormControlSelect3">Phường / Xã <span style={{'color':'red'}}>*</span></label>
                        <select className="form-control custom_select form-wards" onChange={handleWard} id="exampleFormControlSelect3">
                            <option value=""></option>
                                {wards  ? wards.map((wards,index) =>
                                    <option key={index} selected={slug &&  ward_id === wards.id } value={wards.id}>{wards.name}</option>
                                ) : ''}
                        </select>
                        <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.wards}</p> 
                    </div>
                {/* Adress of user */}
                    <div className="form-group">
                        <label style={{'marginBottom':'4px'}} htmlFor="exampleInputEmail1">Địa chỉ <span style={{'color':'red'}}>*</span></label>
                        <input type="text"  onKeyDown={handleRemoveMessAdress} value={slug ? adress : null} onChange={handleAdress} className="form-control form-adress" />
                        <p style={{'fontSize': '12px'}} className="text-danger">{validationMsg.adress}</p> 
                    </div>
                {/* Check default of user */}
                <div className="form-check d-flex align-items-center">
                        <input className="form-check-input checkbox-round"  onChange={handleCheck} type="checkbox" checked={slug && check === 1 ? !checkBox : checkBox} id="defaultCheck1" />
                        <label className="form-check-label ml-3" style={{'cursor':'pointer'}} htmlFor="defaultCheck1">
                            Đặt làm địa chỉ mặc định
                        </label>
                    </div>
                </div>
                <p style={{'fontSize': '12px'}} className="text-danger">{msg}</p> 

                <div className="button_storeUserAdress">
                    <Link style={{'textDecoration':'none'}} to="/profile/address-delivery"><div className="button_storeUserAdress_goHome">Quay lại</div></Link> 
                    <button onClick={handleStoreAdress} style={{'borderStyle':'none'}} disabled={disable} className="button_storeUserAdress_update">Lưu địa chỉ</button>
                </div>
            </div>
        </div>
    )
}

export default AdressDeliveryStore