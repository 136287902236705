import React, {useEffect} from 'react'
import avatar from '../../../assets/img/logo_admin_citimed.png';
import img_ratings from '../../../assets/img/brands/Stars.png';
import {formatDateNew} from "../../../helpers/Function";
import ReactStars from "react-rating-stars-component";
import $ from "jquery"

function Rating(props) {
    $(function () {
        $('.btn-show-reply').click(function (){
            $(this).parent().next().css("display", "block")
            $(this).css("display", "none")
        });
        $('.btn-hide-reply').click(function (){
            $(this).parents('.list_reply_review').hide()
            $(this).parents('.list_reply_review').prev().find('button.btn-show-reply').css("display", "block")
        });
    })

    let user = props.review.user
    let review = props.review
    return (
        review && user && review.visible ? <div className="content_comemt_ratings">
            <div className="title_content_ratings d-flex">
                <img src={user.avatar} alt={user.name} className="avatar"/>
                <h5>{user.name}</h5>
                <span>{formatDateNew(review.created_at, 'DD/MM/YYYY')}</span>
            </div>
            <div className="comment_ratings">
                <ReactStars
                    count={5}
                    size={18}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                    value={review.star}
                    edit={false}
                />
                <p>{review.description}</p>
                {
                    review.reply.length ? <button type="button" className="btn-show btn-show-reply">Hiển thị bình luận ({review.reply.length})</button> : ''
                }

            </div>

            <div className="list_reply_review" style={{display: "none"}}>
                {review.reply.length ? review.reply.map((item, key) =>
                    <div key={key} className="content_comemt_ratings content_ratings admin_rep_comment">
                        <div className="title_content_ratings d-flex">
                            <img src={avatar} width="100%" alt="" className="avatar"/>
                            <h5>{item.admin_name}</h5>
                            <span>{formatDateNew(item.created_at, 'DD/MM/YYYY')}</span>
                        </div>
                        <div className="comment_ratings">
                            <p>{item.description}</p>
                        </div>
                    </div>
                ) : ''}
                <a style={{fontSize : "12px"}} className="btn-show btn-hide-reply">Ẩn bình luận</a>
            </div>
        </div> : ''
    )
}

export default Rating
