import React from 'react';
import News from '../components/common/news/News';
import BannerFooter from "../components/common/banner-footer/BannerFooter";
function NewsList() {
    return (
        <div>
            <News />
            <BannerFooter/>
        </div>
    )
}

export default NewsList;
