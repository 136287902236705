import React, {useEffect, useState} from 'react';
import DatePicker from "antd/lib/date-picker";
import 'antd/dist/antd.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {useHistory} from 'react-router-dom';
import OrderView from '../../common/ViewOrder';
import {OrderClientApiService} from "../../../ultils/EndPoint";
import {
    appendQueryParamsAndRedirect, convertDateTime, deleteQueryParamsAndRedirect, deleteValueEmptyFromObject,
    getJsonQueryParams,
    getQuerystring, objectToQueryParams
} from "../../../helpers/Function";
import {Pagination} from "antd";
import {
    CANCEL_ADMIN,
    CANCEL_CLIENT,
    CONFIRMED,
    DEFAULT_LIMIT_SEARCH,
    SUCCESS,
    UNCONFIRMED
} from "../../../helpers/constant";
import ListOrder from "./ListOrder";
import moment from "moment";
function Purcharse() {

    const [showOrderView, setShowOrdeView] = useState(false);
    const [listOrder, setListOrder] = useState([]);
    const [dataPaginate, setDataPaginate] = useState({});
    const history = useHistory()

    function getListOrder(){
        let dataParam = getJsonQueryParams()
        deleteValueEmptyFromObject(dataParam)
        let query = objectToQueryParams(dataParam)
        OrderClientApiService.getList(query).then(r => {
            if (r.status){
                setListOrder(r.data.records)
                setDataPaginate({
                    "totalRecord" : r.data.total_record,
                    "totalPage"   : r.data.total_page,
                    "currentPage"   : r.data.page,
                    "perPage"   : r.data.limit,
                })
            }
        })
    }

    useEffect(() => {
        getListOrder()
    }, [])

    const setCurrentPage = (page) => {
        appendQueryParamsAndRedirect('page', page , history)
        getListOrder()
    }

    const handleChangeKeyword = (e) =>{
        appendQueryParamsAndRedirect('keyword', e.target.value, history)
        getListOrder()
    }

    const handleChangeStatus = (e) =>{
        appendQueryParamsAndRedirect('status', e, history)
        setCurrentPage(1)
    }

    const changePaymentMethodSelect = (e) => {
        appendQueryParamsAndRedirect('payment_method_id', e.target.value, history)
        getListOrder()
    }
    const changePaymentStatusSelect = (e) => {
        appendQueryParamsAndRedirect('payment_status', e.target.value, history)
        getListOrder()
    }

    const onStartDateChange = (e) => {
        if (e != null){
            let time = convertDateTime(e, "YYYY-MM-DD")
            appendQueryParamsAndRedirect('from_date', time, history)
        }else{
            deleteQueryParamsAndRedirect('from_date', history)
        }

        getListOrder()
    }

    const onEndDateChange = (e) => {
        if (e != null){
            let time = convertDateTime(e, "YYYY-MM-DD")
            appendQueryParamsAndRedirect('to_date', time, history)
        }else{
            deleteQueryParamsAndRedirect('to_date', history)
        }

        getListOrder()
    }
    
    return (
        <div className="content_purchase">
            <OrderView show={showOrderView} close={() => setShowOrdeView(false)} onHide={() => setShowOrdeView(false)} />
            <div className="changePass-wapper">
                <h1>Đơn hàng của tôi</h1>
                <div className="filter d-flex">
                    <div className="input_search">
                        <input defaultValue={getQuerystring("keyword")} type="text" className="form-control" placeholder="Tìm kiếm ở đây" onChange={handleChangeKeyword}/>
                    </div>
                    <select onChange={changePaymentMethodSelect} name="" id="" className="form-control custom_select" defaultValue={getQuerystring("payment_method_id")}>
                        <option value="">Phương thức thanh toán</option>
                        <option value="1">Thanh toán khi nhận hàng</option>
                        <option value="2">Thanh toán qua ví điện tử VNPAY</option>
                        <option value="3">Thanh toán qua ví điện tử MOMO</option>
                        <option value="4">Thanh toán qua VISA</option>
                    </select>
                    <select onChange={changePaymentStatusSelect} name="" id="" className="form-control custom_select" defaultValue={getQuerystring("payment_status")}>
                        <option value="">Trạng thái thanh toán</option>
                        <option value="1">Đã thanh toán</option>
                        <option value="0">Chưa thanh toán</option>
                    </select>
                    <div className="time">
                        <div className="start_date">
                            <DatePicker defaultValue={getQuerystring("from_date") ? moment(getQuerystring("from_date"), 'YYYY-MM-DD') : ''} placeholder="Từ ngày" onChange={onStartDateChange}/>
                        </div>
                        <div className="start_date">
                            <DatePicker defaultValue={getQuerystring("to_date") ? moment(getQuerystring("to_date"), 'YYYY-MM-DD') : ''} placeholder="Đến ngày" onChange={onEndDateChange} />
                        </div>
                    </div>

                </div>
                <Tabs onSelect={handleChangeStatus} defaultActiveKey={getQuerystring("status") ? getQuerystring("status") : 'all'} id="uncontrolled-tab-example" className="tab_purchase d-flex">
                    <Tab eventKey="all" title="Tất cả" on>
                        <ul className="list_purchase pl-0">
                            {
                              getQuerystring("status") == "" || getQuerystring("status") === 'all' ?  <ListOrder listOrder={listOrder}/> : ''
                            }
                        </ul>
                    </Tab>
                    <Tab eventKey={UNCONFIRMED} title="Chưa xác nhận">
                        <ul className="list_purchase pl-0">
                            {
                                getQuerystring("status") == UNCONFIRMED ?  <ListOrder listOrder={listOrder}/> : ''
                            }
                        </ul>
                    </Tab>
                    <Tab eventKey={CONFIRMED} title="Đã xác nhận">
                        <ul className="list_purchase pl-0">
                            {
                                getQuerystring("status") == CONFIRMED ?  <ListOrder listOrder={listOrder}/> : ''
                            }
                        </ul>
                    </Tab>
                    <Tab eventKey={SUCCESS} title="Đã hoàn thành">
                        <ul className="list_purchase pl-0">
                            {
                                getQuerystring("status") == SUCCESS ?  <ListOrder listOrder={listOrder}/> : ''
                            }
                        </ul>
                    </Tab>
                    <Tab eventKey={CANCEL_CLIENT} title="Huỷ bởi khách hàng">
                        <ul className="list_purchase pl-0">
                            {
                                getQuerystring("status") == CANCEL_CLIENT ?  <ListOrder listOrder={listOrder}/> : ''
                            }
                        </ul>
                    </Tab>
                    <Tab eventKey={CANCEL_ADMIN} title="Huỷ bởi admin">
                        <ul className="list_purchase pl-0">
                            {
                                getQuerystring("status") == CANCEL_ADMIN ?  <ListOrder listOrder={listOrder}/> : ''
                            }
                        </ul>
                    </Tab>
                </Tabs>
            </div>
            {dataPaginate.totalRecord <= dataPaginate.perPage ? '' :
                <Pagination
                    defaultCurrent={dataPaginate.currentPage}
                    onChange={(value) => {window.scrollTo({top: 800, left: 0, behavior: 'smooth' }); setCurrentPage(value) }}
                    total={dataPaginate.totalRecord}
                    current={dataPaginate.currentPage}
                    defaultPageSize={DEFAULT_LIMIT_SEARCH}
                    showSizeChanger= {false}
                />
            }
        </div>
    )
}
export default Purcharse;
