import React from 'react';
import {useHistory} from 'react-router-dom';
import imgNotPurchase from '../../../assets/img/profile/gift-card 1.png';
import iconBuy from '../../../assets/img/cart/Group.png';
function CouponEmpty() {
    const history = useHistory()
    return (
        <div className="content_purchase">
            <div className="changePass-wapper">
                <div className="table_purchase">
                    <img src={imgNotPurchase} alt=""/>

                    <h2>Bạn chưa có ưu đãi nào!</h2>

                    <a onClick={() => history.push('/')}>
                        <img src={iconBuy} alt=""/>
                        <span>Quay lại mua sắm</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default CouponEmpty;
