import {
  GET_GROUPS,
} from "../constans/ActionType";

const init = {
  groups: [],
}

export const getGroups = (state = init, {type, payload}) => {
  switch (type) {
    case GET_GROUPS:
      return {...state, groups : payload}
    default:
      return state;
  }
}