import {
    GET_NUMBER_CART,
    ADD_CART, 
    DECREASE_QUANTITY, 
    INCREASE_QUANTITY, 
    DELETE_CART,
    GET_ALL_DATA_CART
} from "../constans/ActionType";

const initProduct = {
    numberCart:0,
    Carts: [],
    cartData:[]
}

export const todoProduct = (state = initProduct,action) =>{
    switch(action.type){

            case GET_ALL_DATA_CART: 
            return{
                ...state,
                cartData:action.payload
            }
            case GET_NUMBER_CART:
                return{
                    ...state
                }
 
            case ADD_CART:
                if(state.numberCart === 0){
                    let cart = {
                        product_id: action.payload.id,
                        quantity:1,
                        selected:true
                    }
                    state.Carts.push(cart);
                   
                }
                else{
                    let check = false;
                    state.Carts.map((item,key) => {
                        if(item.product_id === action.payload.id){
                            state.Carts[key].quantity++;
                            check=true;
                        }
                    });
                    if(!check){
                        let _cart = {
                            product_id: action.payload.id,
                            quantity:1,
                            selected:true
                        }
                        state.Carts.push(_cart);
                    }
                }
                return{
                    ...state,
                    numberCart:state.numberCart+1
                }

            case INCREASE_QUANTITY:
            state.numberCart++
            state.Carts[action.payload].quantity++;

            return{
                ...state
            }

            case DECREASE_QUANTITY:
            let quantity = state.Carts[action.payload].quantity;

            if(quantity>1){
                state.numberCart--;
                state.Carts[action.payload].quantity--;
            }
            return{
                ...state
            }

            case DELETE_CART:
            return{
                ...state,
                Carts:state.Carts.filter(item=>{
                    return item.product_id !== state.Carts[action.payload].product_id
                })
            }
        default:
            return state;
    }
}
