import React from 'react';
import ImageNotFound from '../../../assets/img/Group 90.svg'
import {Link} from "react-router-dom";

function NotFound() {
    return (
        <div className="mt-5 mb-5 d-flex justify-content-center">
          <Link to="/">
            <img alt="not-found" src={ImageNotFound} style={{ width : '100%'}}></img>
          </Link>
        </div>
    )
}

export default NotFound
