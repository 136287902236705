import React from "react";
import Modal from 'react-bootstrap/Modal';
import '../../assets/scss/popup.scss';
import citimedCare from '../../assets/img/image7.png';
import citimedLogo from '../../assets/img/image8.png';
import {Link} from "react-router-dom";

function PopupSuccess(props) {
    return (
        <div>
            <Modal show={props.show} onHide={props.close} className="modal-login modal-success-payment" >
                <Modal.Header closeButton  className="grid">
                    <Modal.Title className="popup-header">Đặt hàng thành công</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Cảm ơn Quý khách đã tin tưởng và mua hàng tại {process.env.REACT_APP_NAME}!</p>
                    <span>Trong vòng 10 phút, chúng tôi sẽ gọi điện thoại hoặc gửi tin nhắn xác nhận giao hàng cho quý khách.</span>
                    <div className="image-success d-flex">
                        <Link to="/"><img src={citimedLogo} className="logo_left" alt=""/></Link>
                        <Link to="/tin-tuc-danh-muc/all"><img src={citimedCare} alt=""/></Link>
                    </div>
                    <span>Tham gia diễn đàn CitiCARE để tìm hiểu những thông tin y tế hữu ích, kết nối cộng đồng bệnh nhân, tư vấn bác sĩ và chia sẻ kinh nghiệm điều trị của bạn ngay hôm nay.</span>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PopupSuccess;
