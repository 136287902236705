import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import {Link, useParams} from "react-router-dom";
import OrderView from '../../common/ViewOrder';
import PopupSuccess from "../../common/PopupSuccess";
import {OrderClientApiService} from "../../../ultils/EndPoint";
import {
    formatCurrency,
    getImageMedia,
    getInfoStatusDelivery,
    getInfoStatusOrder,
    sumProp
} from "../../../helpers/Function";
import {SUCCESS, UNCONFIRMED} from "../../../helpers/constant";
import {Button, Modal} from "react-bootstrap";
import NotFound from "../../../components/common/not_found/NotFound";
import ENDPOINT from "../../../ultils/EndPoint";
import axios from "axios";
import {useHistory} from 'react-router-dom'

function Purcharse() {

    const {id} = useParams()
    const [showOrderView, setShowOrdeView] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [order, setOrder] = useState({})
    const [show, setShow] = useState(false)
    const [notFound, setNotFound] = useState(false)
    
    function getDetailOrder(id){
        OrderClientApiService.getDetail(id).then(r => {
            if (r.status){
                setOrder(r.data)
                setShow(true)
            } else {
                setNotFound(true)
            }
        })
    }

    useEffect(async () => {
        if (localStorage.getItem("create_order_success") === "done"){
            await setShowSuccess(true)
        }
        await getDetailOrder(id)
    }, [id])

    function getTotalPointFinal(){
        let point = 0
        order.products && order.products.map(product => {
            if (product.product_point > 0) {
                point += product.product_point * product.quantity
            }
        })
        return point
    }

    const userAuthToken = localStorage.getItem('User-token');
    const history = useHistory();
    // delete user adress 
    const handleDeleteUserAdress = (id) => {
        const config = {
            headers: { Authorization: `Bearer ${userAuthToken}` }
        };
        axios.put(`${ENDPOINT.ORDERS}/${id}`, config)
        .then(function (response) {
            history.push('/profile/purchase-order')
        })
        .catch(error => {
            console.log(error);
        }
        )
    }

    const [idForDelete, setIdForDelete] = useState('')
    const [showDetail, setShowDetail] = useState(false);
    const handleClose = () => {setShowDetail(false)};
    const handleShow = (e) => {
        setShowDetail(true)
        setIdForDelete(e.target.id)
    };
    if (notFound) {
        return <NotFound/>
    }

    return (
        show 
        ?  
        <div>
            <div className="content_purchase">
                <OrderView orderId={id} orderCode={order.code} products={order.products} show={showOrderView} close={() => setShowOrdeView(false)} onHide={() => setShowOrdeView(false)} />
                <div className="changePass-wapper">
                    <h1>Đơn hàng #{order.code}</h1>
                    <div className="status_order">
                        <span>{order.payment_method.name}</span>
                        <div className="d-flex">
                            {order.payment_status ? <a style={{cursor:'default'}} className="paid">Đã thanh toán</a> : <a style={{cursor:'default'}} className="not-pay">Chưa thanh toán</a>}
                            <a style={{cursor:'default', color:'#FFF'}} className={getInfoStatusOrder(order.status).status_css}>{getInfoStatusOrder(order.status).status_name}</a>
                            <a style={{cursor:'default', color:'#FFF'}} className={getInfoStatusDelivery(order.delivery_status).status_css}>{getInfoStatusDelivery(order.delivery_status).status_name}</a>
                        </div>
                    </div>
                    <div className="list_order_detail table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sản phẩm</th>
                                    <th>Số lượng</th>
                                    <th>Tạm tính</th>
                                </tr>
                            </thead>
                            <tbody>
                            {order.products && order.products.length && order.products.map(product => {
                                return (
                                    <tr key={product.id}>
                                        <th>
                                            <div className="d-flex">
                                                <Link to={"/san-pham/" + product.slug}>
                                                <img src={getImageMedia(product.image)} alt={product.name}/>
                                                </Link>
                                                <div className="grid">
                                                    <Link to={"/san-pham/" + product.slug}>
                                                    <span className="name_product">{product.name}</span>
                                                    </Link>
                                                    <div className="d-flex price">
                                                        {
                                                            product.product_point ? <h2>{product.product_point} điểm</h2> : <><h2>{formatCurrency(product.product_price)}đ</h2><h4>{product.product_price !== product.product_real_price ? formatCurrency(product.product_real_price)+ 'đ' : ''}</h4></>
                                                        }
                                                    </div>
                                                    {product.coupon_value !== 0 ? <div className="vourcher">
                                                        <span>Giảm {formatCurrency(product.coupon_value)}đ</span><p>mã giảm giá {product.coupon_code}</p>
                                                    </div> : ''}
                                                </div>
                                            </div>
                                        </th>
                                        <td>
                                            {product.quantity}
                                        </td>
                                        <td>
                                            { product.product_point ?  (product.product_point * product.quantity) + ' điểm' : formatCurrency(product.origin_price)+ 'đ' }
                                        </td>
                                    </tr>
                                )
                                }
                            )}

                            </tbody>
                        </table>
                    </div>
                    <div className="cart-items cart-detail-items">
                        <div className="title">
                            <p>Thông tin sản phẩm</p>
                        </div>
                        {order.products && order.products.length && order.products.map(product => {
                            return (
                                <div className="cart-item" key={product.id}>
                                    <div className="cart-zone-info">
                                        <div className="image_product_zone">
                                            <Link to={"/san-pham/" + product.slug}>
                                                <img src={getImageMedia(product.image)} alt={product.name} />
                                            </Link>
                                        </div>
                                        <div className="cart_info_zone">
                                            <Link className="hover_to_detailItem" to={`/san-pham/${product.slug}`}><p className="name_product">{product.name}</p></Link>
                                            {product.product_point ?  <div className="price"><p>{product.quantity} x {formatCurrency(product.product_point)} điểm</p></div> :
                                            (product.product_price === product.product_real_price

                                                ?
                                                <div className="price">
                                                    <p>{product.quantity} x {formatCurrency(product.product_price)}đ</p>
                                                </div>
                                                :
                                                <div className="d-flex price">
                                                    <p>{product.quantity} x {formatCurrency(product.product_price)}đ</p>
                                                    <span>{formatCurrency(product.product_real_price)}đ</span>
                                                </div>
                                            )}
                                            {product.product_point == 0 ?
                                            <div className="voucher">
                                                {product.coupon_code ?
                                                    <p><span>Giảm {formatCurrency(product.coupon_value)}đ</span>mã giảm giá {product.coupon_code}</p> : null }
                                            </div> : null }
                                            {product.product_point == 0 ?
                                            <span className="total-price">{formatCurrency(product.origin_price)}đ</span>
                                            : <span className="total-price">{formatCurrency(product.point)} điểm</span> }
                                        </div>
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                    <div className="infomation_order">
                        <h5>Thông tin đơn hàng</h5>
                        <div className="row">
                            <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                <span>Tạm tính</span>
                            </div>
                            <span className="pl-3">{formatCurrency(sumProp(order.products, 'origin_price'))}đ</span>
                        </div>
                        <div className="row">
                            <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                <span>Giảm giá sản phẩm</span>
                            </div>
                            <span className="pl-3">{formatCurrency(sumProp(order.products, 'coupon_value'))}đ</span>
                        </div>
                        { order.coupon_unit == 0 ?
                            <div className="row">
                                <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                    <span>Voucher đơn hàng</span>
                                </div>
                                <span className="pl-3">{formatCurrency(order.coupon_price)}đ <strong>{order.coupon_code ? '(' + order.coupon_code +')' : ''}</strong></span>
                            </div> :
                            <div className="row">
                                <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                    <span>Voucher đơn hàng</span>
                                </div>
                                <span className="pl-3 coupon_flexResponsive">{formatCurrency(Math.round(order.coupon_price * sumProp(order.products, 'origin_price') / 100))}đ <strong>({order.coupon_code})</strong></span>
                            </div>
                        }
                            <div className="row">
                            <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                <span>Phí vận chuyển</span>
                            </div>
                            <span className="pl-3">{formatCurrency(order.shipping_fee)}đ</span>
                        </div>
                        <div className="row">
                            <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                <strong>Giá trị đơn hàng</strong>
                            </div>
                            <div className="grid pl-3">
                                <span className="red">{formatCurrency(order.real_price)}đ </span>
                                {
                                    getTotalPointFinal() > 0 ?  <span className="red">và {getTotalPointFinal() } điểm </span> : ''
                                }
                            </div>

                        </div>
                        {/* {
                            order.status == SUCCESS ? <Link to="#" className="view_order" onClick={() => setShowOrdeView(true)}>Đánh giá sản phẩm đã mua</Link> : ''
                        } */}

                        {
                            order.status == UNCONFIRMED ? <Link to="#" onClick={handleShow} id={order.id} className="cancel_order">Hủy đơn hàng</Link> : ''
                        }
                    </div>
                    <div className="infomation_order address">
                        <h5>Địa chỉ người nhận</h5>
                        <div className="row">
                            <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                <span>Họ và tên:</span>
                            </div>
                            <span>{order.name}</span>
                        </div>
                        <div className="row">
                            <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                <span> Địa chỉ: </span>
                            </div>
                            <span>{order.address}</span>
                        </div>
                        <div className="row">
                            <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                <span>Tỉnh/TP: </span>
                            </div>
                            <span>{order.city_name}</span>
                        </div>
                        <div className="row">
                            <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                <span>Quận/Huyện</span>
                            </div>
                            <span>{order.district_name}</span>
                        </div>
                        <div className="row">
                            <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                <span>Phường</span>
                            </div>
                            <span>{order.ward_name}</span>
                        </div>
                        <div className="row">
                            <div className="col-7 col-sm-5 col-md-5 col-lg-3">
                                <span>Số điện thoại</span>
                            </div>
                            <span>{order.phone}</span>
                        </div>
                    </div>
                    <div className="infomation_order address">
                        <h5>Ghi chú</h5>
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <span>{order.note ? order.note : 'Không có ghi chú'}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <PopupSuccess show={showSuccess} close={() => {setShowSuccess(false); localStorage.setItem("create_order_success", "off")}} onHide={() => setShowSuccess(false)}/>
            </div>
            <Modal className="modal_deleteAdressUser" show={showDetail} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body  closeButton>
                    <p>Bạn có chắc chắn muốn huỷ đơn hàng này khỏi danh sách ?</p>               
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Không
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDeleteUserAdress(idForDelete)
                        handleClose()
                    }}>
                        Huỷ đơn hàng
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
        : ''

    )

}
export default Purcharse;
