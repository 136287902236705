import {
  GET_WEBSITE,
  GET_STORE, GET_FOOTER,
  GET_MODAL, GET_CHAT, CHECKREGISTER_SUCCESS, USER_REGISTER, OPEN_CHAT, OPEN_FULL_MY_ORDER, SOCIAL_LOGIN
} from "../constans/ActionType";

const init = {
  website: {},
  stores: [],
  footer: true,
  openModal: false,
  chat: true,
  openChat : false,
  registerSuccess: false,
  openFullMyOrder: false,
  socialLogin: false,
  userRegister: [],
}

export const getWebsite = (state = init, {type, payload}) => {
  switch (type) {
    case GET_WEBSITE:
      return {...state, website : payload}
    case GET_STORE:
      return {...state, stores : payload}
    case GET_FOOTER:
      return {...state, footer : payload}
    case GET_MODAL:
    return {...state, openModal : payload}
    case GET_CHAT:
      return {...state, chat : payload}
    case OPEN_CHAT:
      return {...state, openChat : payload}
    case OPEN_FULL_MY_ORDER:
      return {...state, openFullMyOrder : payload}
    case SOCIAL_LOGIN:
      return {...state, socialLogin : payload}
    case CHECKREGISTER_SUCCESS:
      return {...state, registerSuccess : payload}
    case USER_REGISTER:
      return {...state, userRegister : payload}
    default:
      return state;
  }
}