import {
  GET_BRAND,
} from "../constans/ActionType";

const init = {
  brands: [],
}

export const getBrands = (state = init, {type, payload}) => {
  switch (type) {
    case GET_BRAND:
      return {...state, brands : payload}
    default:
      return state;
  }
}