import React from 'react';
import { Link } from 'react-router-dom';
import icon_buy from '../assets/img/icon_buy.png';
import icon_search from '../assets/img/search 1.png';
import '../assets/scss/search.scss';

function SearchNotFound(props) {
    return (
        <div>
            <div className="container pl-0 pr-0">
                    {/*<BreadCrumb from={'Trang chủ'} to={'Tìm kiếm'} />*/}
                    <div className="content_search mt-24">
                        <div className="title_search">
                            <h4>Kết quả tìm kiếm</h4>
                            {/*<p>Tìm kiếm <strong>0 kết quả</strong> của "{props.keyword}"</p>*/}
                            <p>Tìm kiếm <strong>0 kết quả</strong></p>
                        </div>
                        <div className="bg_search">
                            <img src={icon_search} alt=""/>
                            <h2>Không có kết quả nào cho từ khóa mà bạn tìm</h2>
                            <Link to="/">
                                <img src={icon_buy} alt=""/>
                                <span>Quay lại mua sắm</span>
                            </Link>
                        </div>
                    </div>
            </div>
            {/*<BannerFooter/>*/}
        </div>
    )
}
export default SearchNotFound;
