import React, {useEffect, useState} from 'react';
import '../../home-page/brand/branch.scss';
import './product-group.scss';
import $ from 'jquery';
import arrowLeft from '../../../assets/img/Vector 9.svg'
import arrhoverLeft from '../../../assets/img/Vector 9 copy.svg'
import arrowRight from '../../../assets/img/Vector 9 (1).svg'
import arrhoverRight from '../../../assets/img/Vector 9 (1) copy.svg'
import {ProductApiService} from "../../../ultils/EndPoint";
import ProductGroup from "./ProductGroup";
import {Link} from "react-router-dom";

function Index() {
    const [groups, setGroup] = useState({});
    const [products, setProducts] = useState([]);

    useEffect(() => {
        ProductApiService.getGroupFeatureProduct().then(res => {
            if (res.data) {
                if (res.data.products) {
                    setGroup(res.data)
                    setProducts(res.data.products)
                }
            }
        })
    }, [])

    $(function () {
        if (products.length) {
            $('.branch-carouseled').not('.slick-initialized').slick({
                dots: false,
                infinite: true,
                speed: 500,
                autoplay:true,
                slidesToShow: products.length >= 4 ? 4 : products.length,
                slidesToScroll:  products.length >= 4 ? 4 : products.length,
                prevArrow: '.arrowLefted',
                nextArrow: '.arrowRighted',
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }

                ]
            })
        }
    })

    if (products.length) {
        return (
          <div className="container">
              <div className="row">
                  <div className="branch-wapper category_product mobile_top_0">
                      <div className="branch-title">
                          <Link to={'/group/'+groups.slug}><h2>{groups.name}</h2></Link>
                          <div className="d-flex">
                              <p className="arrow arrowLefted "><img alt="" className="arrow-1" src={arrowRight}></img> <img alt="" className="arrow-2" src={arrhoverRight}></img></p>
                              <p className="arrow arrowRighted ml-2"><img alt="" className="arrow-3" src={arrowLeft}></img> <img alt="" className="arrow-4" src={arrhoverLeft}></img></p>
                          </div>
                      </div>
                      <div className="branch-carouseled">
                          {products.map((product, index)=> {
                              return <ProductGroup key={index} product={product} />
                          })}
                      </div>
                  </div>
              </div>
          </div>
        )
    }
    return '';
}

export default Index
