import React from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import BreadCrumb from '../common/breadcrumb/Breadcrumb';
import './Profile.scss'
import {
  NavLink
} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import $ from "jquery";
import {isMobile} from 'react-device-detect';
import {websiteActions} from "../../actions";

function Sidebar({authorized, componentToPassDown}) {
  const data = useSelector(state => state.AuthMeRoot.auth)
  const dispatch = useDispatch()
  const history = useHistory();
  const url = '/profile' + '/' + history.location.pathname.split('/')[2];
  if(!authorized) {
    return <Redirect to="/"/>
  }

  const hanldeLogout = () => {
    const userToken =  localStorage.removeItem('User-token')
    if(userToken !== 1) {
      window.location.href = '/';
    }
  }

  const changeLink = (e) => {
    history.push(e.target.value)
  }

  if (isMobile) {
    dispatch(websiteActions.setChat(false))
  }

  $(function () {
    // $('.popupChat').hide();
  })
  return (
    <div>
      <div className="container container-mb">
        <div className="row">
          <BreadCrumb from={'Trang chủ'} to={'Trang cá nhân'} toLink={'/'}/>
          <div className="main main-profile">
            <div className="profile_detail_category">
              <div className="profile_detail_categoryHead">
                <div className="iamge-detailUser">
                  <img src={data.Avatar} alt=""/>
                </div>
                <div className="name-code">
                  <h2>{data.Name}</h2>
                  <h3>{data.Code}</h3>
                </div>

              </div>
              <div className="profile_detail_categoryBottom">
                <ul>
                  <li><NavLink style={{'textDecoration': 'none', 'color': 'rgba(0, 0, 0, 1)'}} activeClassName="selected"  to="/profile/detail">Thông tin tài khoản</NavLink></li>
                  { data.socials && data.socials.length !== 0 ? '' : <li><NavLink style={{'textDecoration': 'none', 'color': 'rgba(0, 0, 0, 1)'}} activeClassName="selected"  to="/profile/change-password">Thay đổi mật khẩu</NavLink></li>} 
                  <li><NavLink style={{'textDecoration': 'none', 'color': 'rgba(0, 0, 0, 1)'}} activeClassName="selected"  to="/profile/address-delivery">Địa chỉ giao hàng</NavLink></li>
                  <li><NavLink style={{'textDecoration': 'none', 'color': 'rgba(0, 0, 0, 1)'}} activeClassName="selected"  to="/profile/favorite-product">Sản phẩm yêu thích</NavLink></li>
                  <li><NavLink style={{'textDecoration': 'none', 'color': 'rgba(0, 0, 0, 1)'}} activeClassName="selected"  to="/profile/prescription">Đơn thuốc của tôi</NavLink></li>
                  <li><NavLink style={{'textDecoration': 'none', 'color': 'rgba(0, 0, 0, 1)'}} activeClassName="selected"  to="/profile/purchase-order">Đơn hàng của tôi</NavLink></li>
                  <li><NavLink style={{'textDecoration': 'none', 'color': 'rgba(0, 0, 0, 1)'}} activeClassName="selected"  to="/profile/coupons">Ưu đãi của tôi</NavLink></li>
                  <li><NavLink style={{'textDecoration': 'none', 'color': 'rgba(0, 0, 0, 1)'}} activeClassName="selected"  to="/profile/history-points">Lịch sử tích điểm</NavLink></li>
                  <li onClick={hanldeLogout}>Đăng xuất</li>
                </ul>
              </div>
            </div>
            <div className="mb-sidebar">
              <div className="form-group selected-menu">
                <select className="form-control custom_select" onChange={changeLink} value={url}>
                  <option value="/profile/detail" >Thông tin tài khoản</option>
                  <option value="/profile/change-password">Thay đổi mật khẩu</option>
                  <option value="/profile/address-delivery">Địa chỉ giao hàng</option>
                  <option value="/profile/favorite-product">Sản phẩm yêu thích</option>
                  <option value="/profile/purchase-order">Đơn hàng của tôi</option>
                  <option value="/profile/prescription">Đơn thuốc của tôi</option>
                  <option value="/profile/coupons">Ưu đãi của tôi</option>
                  <option value="/profile/history-points">Lịch sử tích điểm</option>
                </select>
              </div>
            </div>
            <div className="profile_detail_profile">
              <>
                {componentToPassDown}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar