import { combineReducers } from 'redux';
import { userReducer } from './User';
import {notificationReducer} from './Notification';
import { CallOtpRequest } from './CallOtpRequest';
import { AuthMeRoot } from './Authme';
import { socialLogin } from './SocialLogin';
import { userProfileUpdate } from './UpdateProfileSocial';
import { getCategoryProduct } from './Category';
import { getBrands } from './Brand';
import { getWebsite } from './Website';
import { getGroups } from "./Groups";
import {todoProduct} from './Cart';
import { getFavoriteProduct } from './FavoriteProduct';

const rootReducer = combineReducers({
    AuthMeRoot,
    CallOtpRequest,
    userReducer,
    notificationReducer,
    socialLogin,
    userProfileUpdate,
    getCategoryProduct,
    getBrands,
    getWebsite,
    getGroups,
    todoProduct,
    getFavoriteProduct,
});

export default rootReducer;